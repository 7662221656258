import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { AppRoutes } from "../../Constants";
import { Transl } from "../../Helpers";

const BreadcrumbComponent = ({ children }) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Icon icon="bx:home" /> &nbsp;
                <Link to={AppRoutes.HOME}>
                    <span>{Transl("home")}</span>
                </Link>
            </Breadcrumb.Item>
            {children}
        </Breadcrumb>
    );
};

export default BreadcrumbComponent;
