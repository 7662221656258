import { message } from "antd";
import i18next from "i18next";
import { NextStepAction } from ".";
import { AppRoutes, LOCAL_LANGUAGE } from "../Constants";
import { formErrorHandler } from "../Helpers";
import AuthApi from "../Inceptors/AuthApi";

export const AuthRequest = () => {
    return {
        type: "USER_AUTH_REQUEST",
    };
};

export const AuthenticateAccount = (values, setState) => (dispatch) => {
    dispatch(AuthRequest());
    AuthApi.post("api/v1/login", {
        email: values?.email,
        password: values?.password,
    })
        .then((response) => {
            const { data } = response;
            if (data.status === "success") {
                i18next.changeLanguage(LOCAL_LANGUAGE);
                localStorage.setItem(
                    "user",
                    data?.response?.data?.token?.access_token
                );
                localStorage.setItem(
                    "refresh",
                    data?.response?.data?.token?.refresh_token
                );
                window.location.href = window.location.origin + AppRoutes.HOME;
            }
        })
        .catch((err) => {
            dispatch({
                type: "USER_AUTH_FAILED",
                error: err?.response?.data?.error?.message,
                status: err?.response?.status,
            });
            setState((prevState) => ({
                ...prevState,
                alertStatus: err?.response?.status,
            }));
        });
};

export const ValidateEmail = (values, form) => (dispatch) => {
    AuthApi.get("/api/v1/check/" + values.email)
        .then(() => {
            dispatch({
                type: "INSERT_REGISTER_PAGE",
                email: values.email,
                password: values.password,
            });
            dispatch(NextStepAction());
        })
        .catch((err) => {
            formErrorHandler(form, err.response, "email");
        });
};

export const RegisterAccount = (values) => (dispatch) => {
    AuthApi.post("/api/v1/register", {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        contact_number: values.contact,
        password: values.password,
        password_confirmation: values.password,
        addresses: {
            address_1: values.address1 != null ? values.address1 : null,
            address_2: values.address2 != null ? values.address2 : null,
            city: values.city != null ? values.city : null,
            country_id: values.country != null ? values.country : null,
            state_id: values.state != null ? values.state : null,
        },
    }).then((response) => {
        dispatch(NextStepAction());
        dispatch({
            type: "RETURN_MESSAGE",
            message: response?.data?.response?.message,
            category: "notification",
            status: response?.status,
        });
    });
};

export const ConfirmEmail = (values, history) => (dispatch) => {
    AuthApi.post("api/v1/update/email/confirm/" + values.email).catch((err) => {
        dispatch({
            type: "RETURN_MESSAGE",
            message: err?.response?.data?.error?.message,
            category: "notification",
            status: err?.response?.status,
        });
        history.push(AppRoutes.LOGIN);
    });
};

export const ResendEmailConfirmation = (values) => (dispatch) => {
    AuthApi.post("api/v1/update/resend/email/confirm/" + values.email)
        .then((response) => {
            dispatch({
                type: "RETURN_MESSAGE",
                message: response?.data?.response?.message,
                category: "notification",
                status: response?.status,
            });
        })
        .catch((err) => {
            dispatch({
                type: "RETURN_MESSAGE",
                message: err?.response?.data?.error?.message,
                category: "notification",
                status: err?.response?.status,
            });
        });
};

export const ForgetPassword = (values) => (dispatch) => {
    AuthApi.post("api/v1/password/create", {
        email: values?.email,
    })
        .then((response) => {
            if (response?.status === 200) {
                dispatch({
                    type: "RETURN_MESSAGE",
                    message: response?.data?.response?.message,
                    category: "notification",
                    status: response?.status,
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: "RETURN_MESSAGE",
                message: err?.response?.data?.error?.message,
                category: "notification",
                status: err?.response?.status,
            });
        });
};

export const ValidateResetPasswordToken = (values, history) => (dispatch) => {
    AuthApi.get(
        "api/v1/password/validate/" + values?.email + "/" + values?.token
    ).catch((err) => {
        history.push(AppRoutes.FORGETPASSWORD);
        dispatch({
            type: "RETURN_MESSAGE",
            message: err?.response?.data?.error?.message,
            category: "notification",
            status: err?.response?.status,
        });
    });
};

export const ResetPassword = (values, history) => (dispatch) => {
    AuthApi.post("api/v1/password/reset", {
        email: values?.email,
        password: values?.password,
        password_confirmation: values?.password,
        token: values?.token,
    }).then((response) => {
        if (response.status === 200) {
            history.push(AppRoutes.LOGIN);
            dispatch({
                type: "RETURN_MESSAGE",
                message: response?.data?.response?.message,
                category: "notification",
                status: response?.status,
            });
        }
    });
};

export const GetUserProfile = () => (dispatch) => {
    dispatch(AuthRequest());
    AuthApi.get("api/v1/user/me").then((response) => {
        dispatch({
            type: "USER_PROFILE",
            profile: response.data.response.data,
        });
    });
};

export const UpdateProfile = (values, history) => (dispatch) => {
    AuthApi.put("api/v1/user/update", {
        first_name: values.first_name,
        last_name: values.last_name,
        contact_number: values.contact,
        addresses: {
            address_1: values.address1 != null ? values.address1 : null,
            address_2: values.address2 != null ? values.address2 : null,
            city: values.city != null ? values.city : null,
            country_id: values.country != null ? values.country : null,
            state_id: values.state != null ? values.state : null,
        },
    })
        .then((response) => {
            message.success("Profile updated");
        })
        .catch((err) => {
            message.error(err?.response?.data?.error?.message);
        });
};

export const ChangePassword = (values) => (dispatch) => {
    AuthApi.put("api/v1/user/password/update", {
        current_password: values?.current_password,
        new_password: values?.new_password,
        new_password_confirmation: values?.confirm_password,
    })
        .then((response) => {
            message.success(response?.data?.response?.message);
        })
        .catch((err) => {
            message.error(err?.response?.data?.error?.message);
        });
};

export const SignOut = () => () => {
    AuthApi.delete("api/v1/user/logout").then((response) => {
        const { data } = response;
        if (data.status === "success") {
            localStorage.removeItem("user");
            localStorage.removeItem("refresh");
            window.sessionStorage.removeItem("store_id");
            window.location.href = window.location.origin + AppRoutes.LOGIN;
        }
    });
};
