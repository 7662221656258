import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateProfile } from "../../../../Actions";
import {
  AddressFormComponent,
  PersonalInfoFormComponent,
} from "../../../../Components/Form";

const ProfileMaintenanceComponent = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({auth}) => {
    return {
        auth
    };
  });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
        email: auth?.account?.user?.email,
        first_name: auth?.account?.user?.first_name,
        last_name: auth?.account?.user?.last_name,
        contact: auth?.account?.user?.contact_number,
        address1: auth?.account?.address?.address_1,
        address2: auth?.account?.address?.address_2,
        city: auth?.account?.address?.city,
        CountryLocation: auth?.account?.address?.country_id,
        StateLocation: auth?.account?.address?.state_id,
    });
  },[auth]);

  const handleSubmitProfile = (value) => {
    dispatch(
      UpdateProfile({
        first_name: value.first_name,
        last_name: value.last_name,
        contact: value.contact,
        address1: value.address1,
        address2: value.address2,
        city: value.city,
        country: value.CountryLocation,
        state: value.StateLocation,
      })
    );
  };

  return (
    <Form
      onFinish={handleSubmitProfile}
      form={form}
    >
      <Row>
        <Col lg={3}>
          <h3>E-mail:&nbsp;</h3>
        </Col>

        <Col lg={9}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your e-mail!",
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>

      <PersonalInfoFormComponent />
      <AddressFormComponent />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Update Profile
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfileMaintenanceComponent;
