import { Collapse, Input, Button, Card } from "antd";
import React from "react";
import DynamicConfiguration from "../../../Routes/Builder/Component/DynamicConfiguration/index";

const GenericFormEditor = ({
  selectedBlock,
  setChange,
  settings,
  mainGroup,
  ...props
}) => {
  return (
    <React.Fragment>
      <DynamicConfiguration
        page={selectedBlock?.page}
        blockId={selectedBlock?.blockId}
        editorType={settings?.editorType}
        defaultValue={settings?.value}
        settingMode={mainGroup}
        internalKey={settings?.internalKey}
        setChange={setChange}
        settings={settings}
        modifyKey="value"
      />
    </React.Fragment>
  );
};

export default GenericFormEditor;
