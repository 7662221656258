import React, { useEffect, useReducer } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  AppRoutes,
  LOCAL_LANGUAGE,
  LOCAL_USER_TOKEN,
  NAV_LOGO,
} from "./../../Constants";
import { useSelector, useDispatch } from "react-redux";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { GetUserProfile } from "../../Actions";
import { Icon } from "@iconify/react";
import accountIcon from "@iconify/icons-codicon/account";
import appMenu from "@iconify/icons-fe/app-menu";
import settingsFilled from "@iconify/icons-ci/settings-filled";
import logoutIcon from "@iconify/icons-icon-park/logout";
import package2 from "@iconify/icons-lucide/package-2";
import { Transl, reducerHelper } from "../../Helpers";
import LanguageModal from "../Modal/LanguageModal";
import LogOutModal from "../Modal/LogOutModal";
import OffCanvasNav from "../OffCanvas/OffCanvasNav";
import {
  GENERAL_NAV_MAP,
  USER_AUTH_REQUIRE_NAV_MAP,
} from "../../Constants/NavMap";
import "./css/nav.scss";
import i18next from "i18next";
import saveLanguage from "../../Actions/UserPreferenceAction";
import { Button, Dropdown, Space, Menu, Skeleton } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Buildernav = ({ location, Routers, activeSidebar, setActiveSidebar }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth, message, webSetting } = useSelector((state) => state);

  const [
    { getLanguage, offCanvasShow, LanguageShow, LogOutShow, authRequired },
    setState,
  ] = useReducer(reducerHelper, {
    getLanguage: null,
    offCanvasShow: false,
    LanguageShow: false,
    LogOutShow: false,
    authRequired: null,
  });

  useEffect(() => {
    if (getLanguage === undefined) {
      setState((prevState) => ({
        ...prevState,
        getLanguage: LOCAL_LANGUAGE,
      }));
    }
  }, [getLanguage]);

  useEffect(() => {
    if (LOCAL_USER_TOKEN !== null) {
      dispatch(GetUserProfile());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      LOCAL_USER_TOKEN !== null &&
      (window.location.pathname === AppRoutes.LOGIN ||
        window.location.pathname === AppRoutes.REGISTER)
    ) {
      history.goBack();
    }
  }, [LOCAL_USER_TOKEN, history]);

  useEffect(() => {
    const tempList = [];
    Routers.forEach((element) => {
      if (element.auth === true) {
        tempList.push(element.path);
      }
    });

    setState((prevState) => ({ ...prevState, authRequired: tempList }));
  }, [Routers]);

  useEffect(() => {
    if (
      authRequired !== null &&
      LOCAL_USER_TOKEN === null &&
      authRequired.includes(location.pathname)
    ) {
      history.push(AppRoutes.LOGIN);
    }
  }, [authRequired, LOCAL_USER_TOKEN, history, location]);

  useEffect(() => {
    if (
      message?.category === "notification" &&
      (message?.status === 200 || message?.status === 201)
    ) {
      NotificationManager.success(message?.message, "Success");
    } else if (
      message?.category === "notification" &&
      (message?.status === 404 || message?.status === 400)
    ) {
      NotificationManager.error(message?.message, "Failed");
    }
  }, [message]);

  useEffect(() => {
    if (auth?.account?.settings?.language !== null) {
      i18next.changeLanguage(auth?.account?.settings?.language);
      setState((prevState) => ({
        ...prevState,
        getLanguage: auth?.account?.settings?.language,
      }));
    } else {
      dispatch(saveLanguage());
    }
  }, [auth?.account?.settings?.language, dispatch]);

  const handleOffCanvasShow = () =>
    setState((prevState) => ({ ...prevState, offCanvasShow: true }));
  const handleOffCanvasClose = () =>
    setState((prevState) => ({ ...prevState, offCanvasShow: false }));

  const handleLanguageShow = () =>
    setState((prevState) => ({ ...prevState, LanguageShow: true }));
  const handleLanguageClose = () =>
    setState((prevState) => ({ ...prevState, LanguageShow: false }));

  const handleLogOutShow = () =>
    setState((prevState) => ({ ...prevState, LogOutShow: true }));
  const handleLogOutClose = () =>
    setState((prevState) => ({ ...prevState, LogOutShow: false }));

  const handleSaveLanguage = (language) => {
    i18next.changeLanguage(language);
    setState((prevState) => ({
      ...prevState,
      getLanguage: language,
    }));

    if (LOCAL_USER_TOKEN !== null) {
      dispatch(saveLanguage({ language: language }));
    }
  };

  const handleSidebar = () => {
    if (!activeSidebar) {
      setActiveSidebar(true);
    } else {
      setActiveSidebar(false);
    }
  };

  const handleCloseSidebar = () => {
    setActiveSidebar(false);
  };

  const handleAccountDropdownArray = (array, key, value) => {
    const newArray = [];

    array.map((element, index) => {
      if (element[key] === value) {
        newArray.push({
          label: (
            <Link to={element.navRoute}>
              <div className="navDropdownList">
                <div className="dropdownIcon">{element.icon}</div>
                <div>{Transl(element.navName)}</div>
              </div>
            </Link>
          ),
          key: index,
        });
      }
    });

    newArray.push({
      label: (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            handleLogOutShow();
          }}
        >
          <div className="navDropdownList">
            <div className="dropdownIcon">
              <Icon icon={logoutIcon} />
            </div>
            <div>Sign Out</div>
          </div>
        </a>
      ),
      key: newArray.length,
    });

    return newArray;
  };

  return (
    <>
      <Navbar
        className={`${webSetting.hideNav && "dismissNavbar"}`}
        expand="lg"
      >
        <div id="mobile-view">
          <Button
            className="offcanvas-initial-button"
            onClick={handleOffCanvasShow}
          >
            <b>
              <Icon icon="gg:menu" width="30" height="30" />
            </b>
          </Button>
        </div>

        {LOCAL_USER_TOKEN !== null && (
          <div
            className={
              activeSidebar
                ? "desktop-view-menu-icon active"
                : "desktop-view-menu-icon"
            }
            onClick={handleSidebar}
            data-key="sidebarOverlay"
          >
            <Icon icon="gg:menu" width="30" height="30" />
          </div>
        )}

        <Navbar.Brand
          className="text-center"
          onClick={() => {
            handleCloseSidebar();
          }}
        >
          <Link to={AppRoutes.HOME} className="logo">
            <img src={NAV_LOGO} height={40} alt={"Logo"} />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {LOCAL_USER_TOKEN !== null ? (
            <>
              {USER_AUTH_REQUIRE_NAV_MAP.map((element) => (
                <React.Fragment key={element.navName}>
                  {element.barType === "navbar" && (
                    <Nav
                      className="nav"
                      onClick={() => {
                        handleCloseSidebar();
                      }}
                    >
                      <Link to={element.navRoute} className="link">
                        {Transl(element.navName)}
                      </Link>
                    </Nav>
                  )}
                </React.Fragment>
              ))}
              <Nav
                className="nav"
                onClick={() => {
                  handleCloseSidebar();
                }}
              >
                <Dropdown
                  overlay={
                    <Menu
                      items={handleAccountDropdownArray(
                        USER_AUTH_REQUIRE_NAV_MAP,
                        "barType",
                        "my-account-dropdown"
                      )}
                    />
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Space style={{ cursor: "pointer" }}>
                    {auth?.account?.user?.first_name?.toUpperCase() ?? (
                      <Skeleton active={true} />
                    )}
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </Nav>
            </>
          ) : (
            <>
              {GENERAL_NAV_MAP.map((element) => (
                <Nav key={element.navName} className="nav">
                  <Link to={element.navRoute} className="link">
                    {element.icon !== null && element.icon}
                    <span className={element.icon === null ? "" : "navText"}>
                      {Transl(element.navName)}
                    </span>
                  </Link>
                </Nav>
              ))}
            </>
          )}
        </Navbar.Collapse>

        <Nav
          className="nav"
          onClick={() => {
            handleCloseSidebar();
            handleLanguageShow();
          }}
        >
          <b className={LanguageShow ? "language active" : "language"}>
            <Icon icon="ic:baseline-language" width="30" height="30" />
          </b>
        </Nav>
      </Navbar>
      <NotificationContainer />

      <OffCanvasNav
        user={LOCAL_USER_TOKEN}
        auth={auth}
        offCanvasShow={offCanvasShow}
        handleOffCanvasShow={handleOffCanvasShow}
        handleOffCanvasClose={handleOffCanvasClose}
        handleLogOutShow={handleLogOutShow}
      />

      <LanguageModal
        getLanguage={getLanguage}
        LanguageShow={LanguageShow}
        handleLanguageClose={handleLanguageClose}
        handleSaveLanguage={handleSaveLanguage}
      />

      <LogOutModal
        LogOutShow={LogOutShow}
        handleLogOutClose={handleLogOutClose}
      />
    </>
  );
};

export default Buildernav;
