import { message } from "antd";
import { NotificationManager } from "react-notifications";

import {
  GET_ALL_SUBSCRIPTION_REQUEST,
  GET_ALL_SUBSCRIPTION_SUCCESS,
  GET_ALL_SUBSCRIPTION_FAIL,
  GET_SELECTED_SUBSCRIPTION_REQUEST,
  GET_SELECTED_SUBSCRIPTION_SUCCESS,
  GET_SELECTED_SUBSCRIPTION_FAIL,
} from "../Constants/ActionType";
import { API_TABLE_ROWS_PER_PAGE } from "../Constants/AppConfig";
import AuthApi from "../Inceptors/AuthApi";

export const getAllSubscription = (page, limit, filter) => (dispatch) => {
  dispatch({ type: GET_ALL_SUBSCRIPTION_REQUEST });
  let url = `/api/v1/subscription?page=${page !== undefined ? page : 1}&limit=${
    limit !== undefined ? limit : API_TABLE_ROWS_PER_PAGE
  }`;
  filter?.map((v) => {
    if (v.key === "status" && parseInt(v.value) === -1) {
      return null;
    }
    url += `&filter[${v.key}]=${v.value}`;
    return url;
  });
  AuthApi.get(url)
    .then((response) => {
      dispatch({
        type: GET_ALL_SUBSCRIPTION_SUCCESS,
        payload: response?.data?.response?.data,
      });
    })
    .catch(() => {
      dispatch({ type: GET_ALL_SUBSCRIPTION_FAIL });
    });
};

export const getSubscriptionDetail = (id) => (dispatch) => {
  dispatch({ type: GET_SELECTED_SUBSCRIPTION_REQUEST });
  let url = `/api/v1/subscription/${id}`;
  AuthApi.get(url)
    .then((response) => {
      dispatch({
        type: GET_SELECTED_SUBSCRIPTION_SUCCESS,
        payload: response?.data?.response?.data,
      });
    })
    .catch((err) => {
      message.error(err?.response?.data?.error);
      dispatch({ type: GET_SELECTED_SUBSCRIPTION_FAIL });
    });
};

export const cancelSubscription = (values) => (dispatch) => {
  let url = `/api/v1/cancel/subscription`;
  AuthApi.post(url, values)
    .then(() => {
      dispatch(getAllSubscription());
    })
    .catch((err) => {
      NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
};

export const renewSubscription = (values) => (dispatch) => {
  let url = `/api/v1/renew/subscription`;
  AuthApi.post(url, values)
    .then(() => {
      dispatch(getAllSubscription());
    })
    .catch((err) => {
      NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
};

export const changeSubscription = (values) => (dispatch) => {
  let url = `/api/v1/change/subscription`;
  AuthApi.post(url, values)
    .then(() => {
      dispatch(getAllSubscription());
    })
    .catch((err) => {
      NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
};
