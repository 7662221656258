import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ResendEmailConfirmation } from "../../../Actions";

const RegisterEmailConfirmation = () => {
    const dispatch = useDispatch();
    const { register } = useSelector((state) => state);

    const resendConfirmEmail = () => {
        dispatch(ResendEmailConfirmation({ email: register.email }));
    };

    const resetRegister = () => {
        dispatch({
            type: "RESET_STEP",
        });
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col lg={10}>
                    <h3 className="text-center">
                        Your account has been registered.
                    </h3>

                    <br />
                    <br />

                    <h5>Please proceed to your email for confirmation.</h5>

                    <br />
                </Col>
            </Row>

            <Row className="justify-content-md-center">
                <Col lg={10}>
                    <h5>
                        If you did not receive a confirmation email, click the
                        button below to resend a confirmation email.
                    </h5>
                </Col>
            </Row>
            <br />
            <Row className="justify-content-md-center">
                <Col className="text-center" lg={3}>
                    <Button className="w-100" onClick={resendConfirmEmail}>
                        Resend
                    </Button>
                </Col>
            </Row>
            <br />
            <Row className="justify-content-md-center">
                <Col className="text-center" lg={3}>
                    <h3>or</h3>
                </Col>
            </Row>
            <br />
            <Row className="justify-content-md-center">
                <Col className="text-center" lg={4}>
                    <Button className="w-100" onClick={resetRegister}>
                        Create another account
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default RegisterEmailConfirmation;
