import React from "react";
import { Form, Input } from "antd";
import { Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";

export const PersonalInfoFormComponent = () => {
    return (
        <>
            <Row>
                <Col lg={3}>
                    <h3>
                        First Name&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>

                <Col lg={9}>
                    <Form.Item
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your first name!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter First Name" />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <h3>
                        Last Name&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>

                <Col lg={9}>
                    <Form.Item
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your last name!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Last Name" />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <h3>
                        Contact Number&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>

                <Col lg={9}>
                    <Form.Item
                        name="contact"
                        rules={[
                            {
                                required: true,
                                message: "Please input your contact number!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Contact Number" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
