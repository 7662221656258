import { SWITCH_LANGUAGE } from "../Constants/ActionType";
import AuthApi from "../Inceptors/AuthApi";

const saveLanguage = (values) => (dispatch) => {
  AuthApi.post(
    "api/v1/user/language",
    {
      setting_name: "language",
    },
    {
      headers: {
        "X-Builderx-Language": values.language,
      },
    }
  ).then(() => {
    dispatch({
      type: SWITCH_LANGUAGE,
      language: values.language,
    });
  });
};

export default saveLanguage;
