import { Card, Col, Row, Skeleton, Switch } from "antd";
import _ from "lodash";
import React from "react";
import { Icon } from "@iconify/react";
import linkChain from "@iconify/icons-akar-icons/link-chain";
import dateSolid from "@iconify/icons-clarity/date-solid";
import editIcon from "@iconify/icons-bxs/edit";
import trashIcon from "@iconify/icons-bi/trash";
import moment from "moment";
import EmptyComponent from "../../../../Components/Empty/EmptyComponent";
import emptyDataAsset from "../../../../Assets/Images/Global/undraw_no_data_re_kwbl.svg";
import { t } from "i18next";
import "./PageGridListing.scss";
import { useSelector } from "react-redux";

const PageGridListing = ({
  loading,
  data,
  changePageStatus,
  deletePage,
  onDelete,
  editPage,
  ...props
}) => {
  const numPerRows = 4;
  const { selectedStore } = useSelector(({ coreApp }) => {
    return {
      selectedStore: coreApp.selectedStore,
    };
  });
  const emptyComponentData = {
    image: emptyDataAsset,
    imageStyle: { height: "100%" },
    description: <span style={{ fontSize: "1rem" }}>No Data Found.</span>,
  };

  return (
    <Row>
      {loading ? (
        <Skeleton style={{ marginTop: "10px" }} active />
      ) : (
        _.isEmpty(data) && (
          <EmptyComponent
            image={emptyComponentData.image}
            imageStyle={emptyComponentData.imageStyle}
            description={emptyComponentData.description}
          />
        )
      )}
      {!loading &&
        _.chunk(data, numPerRows).map((v) => {
          return v.map(
            ({
              id,
              page_name,
              status,
              page_mode,
              page_route,
              updated_at,
              store_id,
              loading = false,
            }) => {
              let actArr = [
                <span onClick={() => onDelete(id, store_id)}>
                  <Icon icon={trashIcon} /> {t("common.delete")}
                </span>,
              ];
              if (page_mode === "Builder") {
                actArr.push(
                  <span onClick={() => {}}>
                    <Icon icon={editIcon} /> {t("common.builder")}
                  </span>
                );
              } else {
                actArr.push(
                  <span onClick={() => editPage(id, page_mode, store_id)}>
                    <Icon icon={linkChain} />{t("common.edit")}
                  </span>
                );
              }
              return (
                <Col
                  className="page-grid-listing-wrapper"
                  lg={6}
                  xl={6}
                  xs={24}
                  sm={24}
                  md={24}
                >
                  <Card actions={actArr.reverse()} bordered={true}>
                    <Card.Meta
                      title={page_name}
                      description={
                        <>
                          <Icon icon={linkChain} />{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`https://${selectedStore.primary_domain.domain}/${page_route}`}
                          >
                            {page_route}
                          </a>
                          <br />
                          <Icon icon={dateSolid} />{" "}
                          {moment(updated_at).format("DD MMM YYYY")}
                          <br />
                          {t("common.pageMode")}: {page_mode}
                          <br />
                          Status:{" "}
                          <Switch
                            loading={loading}
                            onChange={() => changePageStatus(id)}
                            defaultChecked={status}
                          />
                        </>
                      }
                    />
                  </Card>
                </Col>
              );
            }
          );
        })}
    </Row>
  );
};

export default PageGridListing;
