import React from 'react';
import { Pagination, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { API_TABLE_ROWS_PER_PAGE, API_TABLE_ROWS_PER_PAGE_OPTIONS } from '../../Constants/AppConfig';
const ACTIVE_COLOR = '#1890ff';
const DEFAULT_COLOR = '#d9d9d9';

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  .ant-pagination-total-text {
    position: absolute;
    left: 1% !important;
    font-weight: normal !important;
  }

  .ant-pagination-item-active a:not([href]):not([tabindex]) {
    color: ${ACTIVE_COLOR} !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item a:not([href]):not([tabindex]) {
    a {
      color: ${DEFAULT_COLOR} !important;
    }
    &:hover {
      a {
        color: ${ACTIVE_COLOR} !important;
      }
    }
  }
`;

const CustomPagination = ({
  text,
  onShowSizeChange,
  onChange,
  dataSource,
  pageSizeOptions,
  displayTotalText = true,
  showLessItems,
  size = 'default',
  showSizeChanger = true,
  itemRender,
  intl
}) => {

  return (
    <ConfigProvider locale="en">
      <div style={{ width: '100%', position: 'relative' }}>
        <StyledPagination
          className="ant-pagination"
          showSizeChanger={showSizeChanger}
          onChange={onChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={dataSource?.current_page || 1}
          current={dataSource?.current_page}
          total={dataSource?.total || 1}
          pageSizeOptions={pageSizeOptions || API_TABLE_ROWS_PER_PAGE_OPTIONS}
          showTotal={(_, range) => {
            const rangeFrom = dataSource?.data?.length ? range[0] : 0;
            const rangeTo = dataSource?.data?.length ? range[1] : 0;
            const ttl = dataSource?.data?.length ? dataSource.total : 0;

          return displayTotalText
            ? `${Number.isNaN(rangeFrom) ? 0 : rangeFrom}-${
                Number.isNaN(rangeTo) ? 0 : rangeTo
              } of ${ttl} ${text}`
            : '';
        }}
        pageSize={dataSource?.per_page || API_TABLE_ROWS_PER_PAGE}
        showLessItems={showLessItems}
        size={size}
        itemRender={itemRender}
      />
      </div>
    </ConfigProvider>
  );
};

export default CustomPagination;
