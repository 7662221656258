import React from "react";
import { Modal, Button } from "antd";
import { Icon } from "@iconify/react";
import timesIcon from "@iconify/icons-humbleicons/times";
import "./index.scss";
import _ from "lodash";

const CustomModal = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      className={`${props.className} custom-modal`}
      title={
        <div className="row justify-content-between">
          {_.isUndefined(props?.footer) && (
            <div className="col-auto">
              <span onClick={props.onOk} className="header-button">
                Confirm
              </span>
            </div>
          )}

          <div style={{ textAlign: "center" }} className="col-auto">
            <span style={{ textOverflow: "ellipsis" }}>{props.title}</span>
          </div>
          <div className="col-auto">
            <span
              onClick={props.onCancel}
              className="header-button modal-close-button"
            >
              <Icon icon={timesIcon} />
            </span>
          </div>
        </div>
      }
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
