import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Transl } from "../../Helpers/LanguageHelper";
import { USER_AUTH_REQUIRE_NAV_MAP } from "../../Constants/NavMap";
import Styles from "./Sidebar.module.scss";

const Sidebar = ({ activeSidebar, setActiveSidebar }) => {
  const handleSidebarClose = () => {
    setActiveSidebar(false);
  };

  const handleClick = (e) => {
    if (e.target.getAttribute("data-key") === "sidebarOverlay") {
      setActiveSidebar(false);
    }
  };

  return (
    <>
      {activeSidebar && (
        <div
          className={Styles.sidebarOverlay}
          onClick={handleClick}
          data-key="sidebarOverlay"
        />
      )}
      <div
        className={
          activeSidebar
            ? `${Styles.userSidebar} ${Styles.active}`
            : Styles.userSidebar
        }
      >
        <div className={Styles.userSidebarContainer}>
          {USER_AUTH_REQUIRE_NAV_MAP.map((element) => (
            <React.Fragment key={element.navName}>
              {element.barType === "sidebar" && (
                <Link
                  to={element.navRoute}
                  className="link"
                  onClick={handleSidebarClose}
                >
                  <div className={Styles.userSidebarContent}>
                    <div className={Styles.sidebarTextContainer}>
                      {Transl(element.navName)}
                    </div>
                    <div className={Styles.sidebarIconContainer}>
                      <Icon icon="akar-icons:chevron-right" />
                    </div>
                  </div>
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
