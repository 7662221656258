import {
  GET_WEBSTORE_DOMAIN,
  GET_WEBSTORE_DOMAIN_SUCCESS,
  GET_WEBSTORE_DOMAIN_FAILED,
  UPDATE_WEBSTORE_DOMAIN_SETTING,
  UPDATE_WEBSTORE_DOMAIN_SETTING_FAILED,
  UPDATE_WEBSTORE_DOMAIN_SETTING_SUCCESS,
} from "../Constants/ActionType";
import { API_TABLE_ROWS_PER_PAGE } from "../Constants/AppConfig";
import BuilderApi from "../Inceptors/BuilderApi";
import { NotificationManager } from "react-notifications";
import { message } from "antd";
import _ from "lodash";

export const getAllWebstoreDomains =
  (page, limit, filter = {},sort = null) =>
  (dispatch) => {
    dispatch({ type: GET_WEBSTORE_DOMAIN });
    let url = `/api/v1/domain?page=${page}&limit=${limit}`;
    if (!_.isNull(sort)){
        url += `&sort=${sort}`;
    }
    BuilderApi.get(url)
      .then((response) => {
        let d = response?.data?.response?.data ?? [];
        d.data = (d?.data ?? []).map((v) => {
          v.loading = false;
          return v;
        });
        dispatch({
          type: GET_WEBSTORE_DOMAIN_SUCCESS,
          payload: d,
        });
      })
      .catch((err) => {
        dispatch({ type: GET_WEBSTORE_DOMAIN_FAILED });
      });
  };

export const updateWebstoreDomainSetting =
  (id, values) => (dispatch) => {
    dispatch({ type: UPDATE_WEBSTORE_DOMAIN_SETTING, id });
    let url = `/api/v1/domain/${id}`;
    BuilderApi.put(url, values)
      .then((response) => {
        message.success("Domain setting save successfully!");
        dispatch({ type: UPDATE_WEBSTORE_DOMAIN_SETTING_SUCCESS, id });
        if (["is_secure", "is_primary"].includes(values.key)) {
          let d = response?.data?.response?.data ?? [];
          d.data = (d?.data ?? []).map((v) => {
            v.loading = false;
            return v;
          });
          dispatch({
            type: GET_WEBSTORE_DOMAIN_SUCCESS,
            payload: d,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UPDATE_WEBSTORE_DOMAIN_SETTING_FAILED });
      });
  };


  export const createWebstoreDomain = (values,callback = null) => (dispatch) => {
    let url = `/api/v1/domain`;
    BuilderApi.post(url, values)
    .then(() => {
      message.success("Domain created successfully!");
      callback("success", null);
      dispatch(getAllWebstoreDomains(1,API_TABLE_ROWS_PER_PAGE,{},null));
    })
    .catch((err) => {
        if (err?.response?.data?.errors) {
            callback("failed", err);
            return;
        }
        NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
  }

  export const deleteWebstoreDomain = (id) => (dispatch) => {
    let url = `/api/v1/domain/${id}`;
    BuilderApi.delete(url)
      .then(() => {
        message.success("Domain delete successfully!");
        dispatch(getAllWebstoreDomains(1,API_TABLE_ROWS_PER_PAGE,{},null));
      })
      .catch((err) => {
        NotificationManager.error(err?.response?.data?.error ?? err?.message);
      });
  };