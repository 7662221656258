import React, { useEffect } from "react";

import "antd/dist/antd.css";
import './css/bootstrap.css';
import './css/style.scss'; // Global Styling
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import { BrowserRouter as Router, useHistory } from "react-router-dom";
import RouteIndex from "./Routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchSelectedStoreDetail, ActivateMobileView } from "./Actions";

const App = ({ ...props }) => {
    const storeId = useSelector(
        ({ coreApp }) => coreApp.selectedStoreId,
        shallowEqual
    );
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!_.isNull(storeId)) {
            dispatch(fetchSelectedStoreDetail(storeId, history));
        }
    }, [storeId, dispatch, history]);

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            dispatch(ActivateMobileView());
        }
    }, [dispatch]);

    //adv routing: https://stackoverflow.com/questions/56711663/react-router-v5-0-nested-routes
    return (
        <Router>
            <RouteIndex {...props} />
        </Router>
    );
};

export default App;
