import _ from "lodash";
import moment from "moment";

export const reducerHelper = (state, action) => {
  if (typeof action === "function") return action(state);
  return { ...state, ...action };
};

export const newTab = (href) => {
  const a = document.createElement("a");
  a.href = href;
  a.setAttribute("target", "_blank");
  a.click();
};

export const randomNumber = () => {
  return Math.floor(Math.random() * 100 + 1);
};

export const formatMalaysiaTime = (givenDate) => {
  if (process.env.MAIN_ENV === "development")
    return new Date(givenDate).toLocaleString();
  const date = new Date(givenDate);
  return new Date(new Date(date).valueOf() + 28850882).toLocaleString();
};

export const formatDate = (d, formatType = false) => {
  let date = d;
  if (!d) return null;
  date = moment(d);
  if (formatType) return date.format(formatType);
  return date.format("YYYY/MM/DD HH:mm:ss");
};

export const convertWindowSearchToKeyPair = (wSearch) => {
  const urlParams = new URLSearchParams(wSearch);
  const params = Object.fromEntries(urlParams);
  return params;
};

export const formErrorHandler = (form, response, field = null) => {
  const errors = Object.entries(
    response?.data?.error ?? response?.data?.errors
  ).reduce((carry, [key, value]) => {
    return [...carry, { name: [field == null ? key : field], errors: [value] }];
  }, []);
  form.setFields(errors);
};

export const generateInitialListingBasedOnTemplate = (listingTemplate) => {
    return _.map(listingTemplate,'internalKey').reduce((carry,v) => {
        carry[v] = "";
        return carry;
    },{});
}
export const refreshPage = () => {
  window.location.reload(false);
};

export const isValidURL = (string) => {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
};