import React, { useState } from "react";
import { useSelector } from "react-redux";
import Styles from "./YouIDivDesign.module.scss";

import Sidebar from "./../Sidebar/Sidebar";
import { GenerateBuilderNav } from "../../Services/RouteService";
import { useLocation } from "react-router-dom";
import Routers from "../../Constants/RouterMap";

const YouIDivDesign = (props) => {
    const location = useLocation();
    const { hideNav } = useSelector(({ webSetting }) => {
        return {
            hideNav: webSetting.hideNav,
        };
    });
    const [activeSidebar, setActiveSidebar] = useState(false);

    return (
        <>
            <GenerateBuilderNav
                location={location}
                Routers={Routers}
                activeSidebar={activeSidebar}
                setActiveSidebar={setActiveSidebar}
            />
            <Sidebar
                activeSidebar={activeSidebar}
                setActiveSidebar={setActiveSidebar}
            />
            <div className={Styles.youIBackground} />
            <div
                className={
                    hideNav
                        ? `${Styles.youIBody} ${Styles.navActive}`
                        : Styles.youIBody
                }
            >
                {props.children}
            </div>
        </>
    );
};

export default YouIDivDesign;
