import React, { useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Alert, Button } from "antd";
import { AuthenticateAccount, ResendEmailConfirmation } from "../../../Actions";
import { useDispatch, useSelector } from "react-redux";
import { AuthFormComponent } from "../../../Components/Form";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../Constants";
import { Transl, reducerHelper } from "../../../Helpers";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import YouIHalfWidthContent from "../../../Components/DivDesign/YouIHalfWidthContent";

export const LoginMain = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { auth } = useSelector((state) => state);

    const [
        { getEmail, alertStatus, showBgImage, resendLinkWording, bgImage },
        setState,
    ] = useReducer(reducerHelper, {
        getEmail: null,
        alertStatus: null,
        showBgImage: true,
        resendLinkWording: Transl("resend_email_confirmation_link"),
        bgImage:
            "url('https://i.pinimg.com/originals/d9/db/26/d9db265464bcce3d64d4aba23bbee5d3.jpg')",
    });

    const handleSubmit = (value) => {
        setState((prevState) => ({ ...prevState, getEmail: value.email }));
        dispatch(
            AuthenticateAccount(
                {
                    email: value.email,
                    password: value.password,
                },
                setState
            )
        );
    };

    const handleResendEmailConfirmation = () => {
        dispatch(
            ResendEmailConfirmation({
                email: getEmail,
            })
        );
    };

    return (
        <>
            <YouIDivDesign>
                <YouIHalfWidthContent
                    title={Transl("sign_in")}
                    secondCol={
                        <>
                            {showBgImage && (
                                <Col
                                    lg={6}
                                    style={{
                                        backgroundImage: bgImage,
                                    }}
                                />
                            )}
                        </>
                    }
                >
                    <Form
                        className="auth-form"
                        form={form}
                        onFinish={(e) => handleSubmit(e)}
                    >
                        {alertStatus !== null && (
                            <>
                                <Alert
                                    message="Login Failed"
                                    description={
                                        alertStatus === 302 ? (
                                            <>
                                                <span>{auth?.error}</span>
                                                <br />
                                                <a
                                                    href={() => false}
                                                    className="link"
                                                    onClick={
                                                        handleResendEmailConfirmation
                                                    }
                                                >
                                                    {resendLinkWording}
                                                </a>
                                            </>
                                        ) : (
                                            auth?.error
                                        )
                                    }
                                    type="error"
                                    showIcon
                                    closable
                                />
                            </>
                        )}

                        <br />
                        <br />
                        <AuthFormComponent />
                        <br />

                        <Row>
                            <Col
                                lg={12}
                                className="forget-password-link font-18"
                            >
                                <Link to={AppRoutes.FORGETPASSWORD}>
                                    {Transl("forget_password")}
                                </Link>
                            </Col>
                        </Row>

                        <br />
                        <br />
                        <Row className="justify-content-md-center">
                            <Col md={4}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className="w-100"
                                    >
                                        {Transl("sign_in")}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                        <br />

                        <Row>
                            <Col lg={12} className="sign-up-link font-18 link">
                                Don't have an account?&nbsp;
                                <Link to={AppRoutes.REGISTER}>
                                    Sign up here
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </YouIHalfWidthContent>
            </YouIDivDesign>
        </>
    );
};
