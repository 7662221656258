import { AppRoutes } from "./Routes";
import UpdateProfileMain from "./../Routes/Profile/UpdateProfile";

import HomeMain from "./../Routes/Home";
import {
  ConfirmEmailMain,
  ForgetPasswordMain,
  LoginMain,
  RegisterMain,
  ResetPasswordMain,
} from "./../Routes/Auth";

import ImageManager from "./../Routes/ImageManager";
import StoreMain from "./../Routes/Store";
import TestingMain from "./../Routes/TestingPage";
import SubscriptionMain from "../Routes/Profile/MySubscription/Subscription/index";

const Routers = [
  {
    path: "/",
    key: "home",
    component: (props) => <HomeMain {...props} />,
    exact: true,
    auth: false,
  },
  {
    path: AppRoutes.LOGIN,
    key: "login",
    component: (props) => <LoginMain {...props} />,
    exact: true,
    auth: false,
  },
  {
    path: AppRoutes.REGISTER,
    key: "register",
    component: (props) => <RegisterMain {...props} />,
    exact: true,
    auth: false,
  },
  {
    path: AppRoutes.CONFIRMEMAIL,
    key: "email-confirm",
    component: (props) => <ConfirmEmailMain {...props} />,
    exact: true,
    auth: false,
  },
  {
    path: AppRoutes.PROFILE,
    key: "profile",
    component: (props) => <UpdateProfileMain {...props} />,
    exact: true,
    auth: true,
  },
  {
    path: AppRoutes.FORGETPASSWORD,
    key: "forget-password",
    component: (props) => <ForgetPasswordMain {...props} />,
    exact: true,
    auth: false,
  },
  {
    path: AppRoutes.RESETPASSWORD,
    key: "reset-password",
    component: (props) => <ResetPasswordMain {...props} />,
    exact: true,
    auth: false,
  },
  {
    path: AppRoutes.IMAGEMANAGER,
    key: "image-manager",
    component: (props) => <ImageManager {...props} />,
    exact: true,
    auth: true,
  },
  {
    path: AppRoutes.STORE,
    key: "store-management",
    component: (props) => <StoreMain {...props} />,
    exact: null,
    auth: true,
  },
  {
    path: AppRoutes.SUBSCRIPTION,
    key: "subscription",
    component: (props) => <SubscriptionMain {...props} />,
    exact: true,
    auth: true,
  },
  {
    path: AppRoutes.TESTING,
    key: "testing",
    component: (props) => <TestingMain {...props} />,
    exact: true,
    auth: false,
  },
];

export default Routers;
