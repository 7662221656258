import React, { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { convertWindowSearchToKeyPair, reducerHelper } from "../../../Helpers";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Form, Button } from "antd";
import { ResetPassword, ValidateResetPasswordToken } from "../../../Actions";
import { useHistory } from "react-router-dom";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import { PasswordFormComponent } from "../../../Components/Form";
import YouIHalfWidthContent from "../../../Components/DivDesign/YouIHalfWidthContent";
import { LOCAL_USER_TOKEN } from "../../../Constants";

export const ResetPasswordMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = useReducer(reducerHelper, {
        queryParams: [],
    });

    useEffect(() => {
        if (LOCAL_USER_TOKEN !== null) {
            localStorage.removeItem("user");
        }
    }, [LOCAL_USER_TOKEN]);

    useEffect(() => {
        const search = location.search;
        setState((prevState) => ({
            ...prevState,
            queryParams: convertWindowSearchToKeyPair(search),
        }));

        const user_email = state?.queryParams?.email;
        const user_token = state?.queryParams?.token;

        if (user_email !== undefined && user_token !== undefined) {
            dispatch(
                ValidateResetPasswordToken(
                    {
                        email: user_email,
                        token: user_token,
                    },
                    history
                )
            );
        }
    }, [
        location,
        history,
        dispatch,
        state?.queryParams?.email,
        state?.queryParams?.token,
    ]);

    const handleSubmit = (values) => {
        dispatch(
            ResetPassword(
                {
                    email: state?.queryParams?.email,
                    password: values.password,
                    token: state?.queryParams?.token,
                },
                history
            )
        );
    };

    return (
        <YouIDivDesign>
            <YouIHalfWidthContent title={"Reset Password"}>
                <Form className="auth-form" onFinish={handleSubmit}>
                    <PasswordFormComponent />
                    <br />
                    <Row>
                        <Col lg={12}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Reset Password
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </YouIHalfWidthContent>
        </YouIDivDesign>
    );
};
