import React, { useImperativeHandle, useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = React.forwardRef((props, ref) => {
  const { onChange, color } = props;
  const [pickerVisible, setPickerVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    closeColorPicker() {
      handleClosePicker();
    },
  }));

  const handleOpenPicker = (evt) => {
    evt.stopPropagation();
    setPickerVisible(true);
  };

  const handleClosePicker = () => {
    setPickerVisible(false);
  };

  const handleOnChangeColor = (color, evt) => {
    evt.stopPropagation();
    onChange(color.rgb);
  };

  return pickerVisible ? (
    <>
      <div
        className="cover"
        style={{
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        }}
        onClick={handleClosePicker}
      />
      <SketchPicker color={color} onChange={handleOnChangeColor} />
    </>
  ) : (
    <div
      style={{
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      }}
      className="swatsh"
      onClick={handleOpenPicker}
    >
      <div style={{ width: "100px", height: "50px", backgroundColor: color }} />
    </div>
  );
});

export default ColorPicker;