import React, { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { convertWindowSearchToKeyPair, reducerHelper } from "../../../Helpers";
import { useDispatch } from "react-redux";
import { Col, Row, Container } from "react-bootstrap";
import { ConfirmEmail } from "../../../Actions";
import { AppRoutes, LOCAL_USER_TOKEN } from "../../../Constants";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import _ from "lodash";

export const ConfirmEmailMain = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [state, setState] = useReducer(reducerHelper, {
        queryParams: [],
    });

    useEffect(() => {
        if (LOCAL_USER_TOKEN !== null) {
            localStorage.removeItem("user");
        }
    }, [LOCAL_USER_TOKEN]);

    useEffect(() => {
        if (!_.isUndefined(LOCAL_USER_TOKEN) || !_.isEmpty(LOCAL_USER_TOKEN)) {
            localStorage.removeItem("user");
        }
    }, []);

    useEffect(() => {
        const search = location.search;
        setState((prevState) => ({
            ...prevState,
            queryParams: convertWindowSearchToKeyPair(search),
        }));

        const user_email = state?.queryParams?.email;

        if (user_email != null) {
            dispatch(
                ConfirmEmail(
                    {
                        email: user_email,
                    },
                    history
                )
            );
        }
    }, [location, history, dispatch, state?.queryParams?.email]);

    return (
        <>
            <YouIDivDesign>
                <Row className="justify-content-md-center">
                    <Col lg={6} className="you-i-Col">
                        <div className="you-i-half-width-container">
                            <h1 className="half-width-page-title">
                                Confirm E-mail
                            </h1>
                            <hr />
                            <Container className="mt-5">
                                <h4 className="text-center">Congratulations</h4>

                                <Row className="justify-content-md-center text-center mt-5">
                                    <Col lg={8}>
                                        <p>
                                            <h5>
                                                You have verified your email.
                                            </h5>
                                        </p>

                                        <p>
                                            <h5>
                                                Please proceed to &nbsp;
                                                <Link to={AppRoutes.LOGIN}>
                                                    Login
                                                </Link>
                                                &nbsp; to find out more
                                                features.
                                            </h5>
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </YouIDivDesign>
        </>
    );
};
