import React from "react";
import { Transl } from "../../Helpers/LanguageHelper";
import { Modal } from "antd";
import { SignOut } from "../../Actions";
import { useDispatch } from "react-redux";

const LogOutModal = ({ LogOutShow, handleLogOutClose }) => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(SignOut());
    };

    return (
        <Modal
            title={Transl("sign_out")}
            visible={LogOutShow}
            onOk={handleLogout}
            okText={Transl("sign_out")}
            onCancel={handleLogOutClose}
            cancelText={Transl("cancel")}
        >
            {Transl("sign_out_alert")}
        </Modal>
    );
};

export default LogOutModal;
