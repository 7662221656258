import React, { useEffect } from "react";
import { Route, Switch, useParams, useHistory, withRouter } from "react-router";
import Page404 from "../../Components/404/Page404";
import { AppRoutes } from "../../Constants";
import BuilderMain from "../Builder";
import DomainManagement from "./DomainManagement";
import ImageManagement from "./ImageManagement/index";
import ListStore from "./listStore";
import PageManagement from "./PageManagement";
import PageEditor from "./PageManagement/Editor";
import StoreDetail from "./StoreDetail/index";

const StoreMain = ({ ...props }) => {
  const { path } = useParams();
  const history = useHistory();
  // const AsyncStorePageComponent = ({ ...props }) => {
  //   const PageManagement = React.lazy(() => import("./PageManagement"));
  //   return (
  //     <>
  //       <Suspense fallback={<p>loading...</p>}>
  //         <PageManagement {...props} />
  //       </Suspense>
  //     </>
  //   );
  // };

  // const AsyncStorePageComponent = Loadable({
  //   loader: () => import("./PageManagement"),
  //   loading:() => <p>loading...</p>,
  // });

  const Routers = [
    {
      path: AppRoutes.STORE,
      key: "stores",
      component: (props) => <ListStore {...props} />,
    },
    {
      path: AppRoutes.STOREDETAIL,
      key: "store_detail",
      component: (props) => <StoreDetail {...props} />,
    },
    {
      path: AppRoutes.STOREPAGES,
      key: "store_pages",
      component: (props) => <PageManagement {...props} />,
    },
    {
      path: AppRoutes.STOREPAGEEDITOR,
      key: "store_page_editor",
      component: (props) => <PageEditor {...props} />,
    },
    {
      path: AppRoutes.STOREDOMAIN,
      key: "store_page_domain",
      component: (props) => <DomainManagement {...props} />,
    },
    {
      path: AppRoutes.STOREBUILDER,
      key: "store_builder",
      component: (props) => <BuilderMain {...props} />,
    },
    {
      path: AppRoutes.STOREIMAGE,
      key: "store_image",
      component: (props) => <ImageManagement {...props} />,
    },
  ];

  return (
    <Switch>
      {Routers.map(({ component, path, key, props }) => {
        return <Route exact key={key} path={path} render={component} />;
      })}
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default withRouter(StoreMain);
