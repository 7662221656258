import { SWITCH_LANGUAGE } from "../Constants/ActionType";

const PreferenceInitState = {
  language: null,
};

export const UserPreferenceReducer = (state = PreferenceInitState, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
};
