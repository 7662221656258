import React from "react";
import YouIDivDesign from "../../Components/DivDesign/YouIDivDesign";
import { Button, Dropdown, Space, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ActivateNavbar, DeactivateNavbar } from "../../Actions";
import { DownOutlined } from "@ant-design/icons";
import YouIFullWidthContent from "../../Components/DivDesign/YouIFullWidthContent";
import BreadcrumbComponent from "../../Components/Breadcrumb/BreadcrumbComponent";

const HomeMain = () => {
  const { webSetting } = useSelector((state) => state);
  const dispatch = useDispatch();

  const toggleNavbar = () => {
    if (webSetting.hideNav) {
      dispatch(ActivateNavbar());
    } else {
      dispatch(DeactivateNavbar());
    }
  };

  const HomeBreadcrumb = () => {
    return <BreadcrumbComponent />;
  };

  const menu = (
    <Menu
      items={[
        {
          label: <a href="https://www.antgroup.com">1st menu item</a>,
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">2nd menu item</a>,
          key: "1",
        },
        {
          type: "divider",
        },
        {
          label: "3rd menu item",
          key: "3",
        },
      ]}
    />
  );

  return (
    <>
      <YouIDivDesign>
        <YouIFullWidthContent breadcrumbComp={<HomeBreadcrumb />}>
          <Button type="primary" onClick={toggleNavbar}>
            Toggle Navbar
          </Button>
          &nbsp;
          <Dropdown overlay={menu} trigger={["click"]} placement="bottom">
            <a href="/" onClick={(e) => e.preventDefault()}>
              <Space>
                Click me
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </YouIFullWidthContent>
      </YouIDivDesign>
    </>
  );
};

export default HomeMain;
