import {
  FETCH_IMAGE_FAILURE,
  FETCH_IMAGE_REQUEST,
  FETCH_IMAGE_SUCCESS,
} from "../Constants/ActionType";

const ImageState = {
  images: [],
  loading: false,
  error: null,
};

export const ImageReducer = (state = ImageState, action) => {
  switch (action.type) {
    case FETCH_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        images: [],
      };

    case FETCH_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: action.payload,
      };

    case FETCH_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        images: [],
      };

    default:
      return state;
  }
};
