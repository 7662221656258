import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import translationEN from "./../Assets/Locales/en/index";
import translationZHCN from "./../Assets/Locales/zh-CN/index";
import translationZHTW from "./../Assets/Locales/zh-TW/index";

const fallbackLng = ["en"];
const availableLanguages = ["en", "zh-CN", "zh-TW"];

const resources = {
    en: {
        translation: translationEN,
    },
    "zh-CN": {
        translation: translationZHCN,
    },
    "zh-TW": {
        translation: translationZHTW,
    },
};

i18next
    .use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true,
        },

        react: {
            useSuspense: false,
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
