import { combineReducers } from "redux";
import { AuthReducer, RegisterReducer } from "./AuthReducer";
import { CountryReducer, StateReducer } from "./LocationReducer";
import { MessageReturnReducer } from "./MessageReturnReducer";
import { UserPreferenceReducer } from "./UserPreferenceReducer";
import { ImageReducer } from "./StoreImageReducer";
import { WebSettingReducer } from "./WebSettingReducer";
import { StepCounterReducer } from "./StepCounterReducer";
import { StoreReducer } from "./StoreReducer";
import { WebstorePageReducer } from "./WebstorePageReducer";
import { WebstoreDomainReducer } from "./WebstoreDomainReducer";
import { CoreAppReducer } from "./CoreAppReducer";
import { BlockManagerReducer } from "./BlockManagerReducer";
import { SubscriptionReducer } from "./SubscriptionReducer";
import { PackageReducer } from "./PackageReducer";
import { webstoreBuilderPages } from "./WebstoreBuilderReducer";
import { BuilderStateReducer } from "./BuilderReducer";

const combineReducer = combineReducers;

const rootReducer = combineReducer({
  coreApp: CoreAppReducer,
  auth: AuthReducer,
  country: CountryReducer,
  stateLocation: StateReducer,
  step: StepCounterReducer,
  register: RegisterReducer,
  message: MessageReturnReducer,
  userPreference: UserPreferenceReducer,
  image: ImageReducer,
  webSetting: WebSettingReducer,
  store: StoreReducer,
  webstorePage: WebstorePageReducer,
  webstoreDomain: WebstoreDomainReducer,
  blockManager: BlockManagerReducer,
  subscription: SubscriptionReducer,
  planPackage: PackageReducer,
  webstoreBuilderPages: webstoreBuilderPages,
  webstoreBuilderPages: webstoreBuilderPages,
  builderState: BuilderStateReducer,
});

export default rootReducer;
