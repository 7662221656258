import React from "react";
import { Card } from "antd";
import Styles from "./YouIMyContent.module.scss";

const YouIMyContent = ({ title, enableMinHeight = false, ...props }) => {
  return (
    <div className={Styles.youIMyContentContainer}>
      <Card
        title={<span className={Styles.cardTitle}>{title}</span>}
        className={`${Styles.youICard} ${
          enableMinHeight && Styles.cardMinHeight
        }`}
      >
        {props.children}
      </Card>
    </div>
  );
};

export default YouIMyContent;
