import React from "react";
import { Row, Col, Card, Tag, Modal } from "antd";
import styled from "./../Subscription.module.scss";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import {
  cancelSubscription,
  renewSubscription,
} from "../../../../../Actions/SubscriptionAction";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Transl } from "../../../../../Helpers";
import { isMobile } from "react-device-detect";

const SubscriptionCard = ({
  showInvoiceModal,
  showPackageModal,
  showPaymentMethodModal,
  subscriptionData,
  subscriptionLoading,
}) => {
  const dispatch = useDispatch();

  const handleCancelSubscription = () => {
    Modal.confirm({
      title: "Cancel Subscription",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to perform cancel subscription?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () =>
        dispatch(cancelSubscription({ subscription_id: subscriptionData.id })),
      centered: true,
    });
  };
  const handleRenewSubscription = () => {
    Modal.confirm({
      title: "Renew Subscription",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to perform renew subscription?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () =>
        dispatch(
          renewSubscription({
            subscription_id: subscriptionData.id,
            package_id: subscriptionData.package_id,
          })
        ),
      centered: true,
    });
  };
  return (
    <Col md={12}>
      <Card
        className={styled.subscriptionCard}
        loading={subscriptionLoading}
        title={
          <>
            {isMobile ? (
              <div className={styled.tagRight}>
                <div>
                  <span style={{ fontSize: "15px" }}>
                    {subscriptionData?.stores?.store_name}
                  </span>
                  <br />
                  <small>
                    ({subscriptionData?.stores?.primary_domain.domain})
                  </small>
                </div>
                <div>
                  <Tag color={subscriptionData.status === 1 ? "blue" : "red"}>
                    {subscriptionData.status === 1
                      ? Transl("subscription.active")
                      : Transl("subscription.inactive")}
                  </Tag>
                </div>
              </div>
            ) : (
              <div className={styled.tagRight}>
                <div>
                  <span style={{ fontSize: "20px" }}>
                    {subscriptionData?.stores?.store_name}
                  </span>
                  <small>
                    ({subscriptionData?.stores?.primary_domain.domain})
                  </small>
                </div>
                <div>
                  <Tag color={subscriptionData.status === 1 ? "blue" : "red"}>
                    {subscriptionData.status === 1
                      ? Transl("subscription.active")
                      : Transl("subscription.inactive")}
                  </Tag>
                </div>
              </div>
            )}
          </>
        }
        actions={[
          <div onClick={showInvoiceModal}>
            <Icon icon="tabler:file-invoice" /> {Transl("subscription.invoice")}
          </div>,
          <div onClick={showPackageModal}>
            <Icon icon="eos-icons:package-outlined" />{" "}
            {Transl("subscription.package")}
          </div>,
          <div onClick={showPaymentMethodModal}>
            <Icon icon="fluent:payment-20-regular" />{" "}
            {Transl("subscription.payment")}
          </div>,
        ]}
        style={{
          margin: "5px 10px",
        }}
      >
        <Row gutter={[8, 0]}>
          <Col md={12}>
            <Icon icon="clarity:store-solid" />{" "}
            {Transl("subscription.store.id")}: {subscriptionData?.stores?.id}
          </Col>
          <Col md={12}>
            <Icon icon="clarity:date-line" /> {Transl("subscription.enddate")}:{" "}
            {subscriptionData.next_billing_date}
          </Col>
          <Col md={12}>
            <Icon icon="wpf:renew-subscription" />{" "}
            {Transl("subscription.subscription")}:{" "}
            {subscriptionData.package.package_name}
          </Col>
          <Col md={12}>
            <Icon icon="game-icons:duration" />{" "}
            {Transl("subscription.duration")}:{" "}
            {subscriptionData.package.duration} {Transl("subscription.day")}
          </Col>
        </Row>
        <Row className={styled.link}>
          {isMobile ? (
            <Col
              ls={24}
              style={{
                display: "flex",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {subscriptionData.status === 1 && (
                <span
                  className="custom-span-button"
                  style={{ color: "red" }}
                  onClick={handleCancelSubscription}
                >
                  <Icon icon="iconoir:cancel" /> {Transl("subscription.cancel")}
                </span>
              )}
              {subscriptionData.status === 0 && (
                <span
                  className="custom-span-button"
                  style={{ color: "blue" }}
                  onClick={handleRenewSubscription}
                >
                  <Icon icon="carbon:renew" /> {Transl("subscription.renew")}
                </span>
              )}
            </Col>
          ) : (
            <Col md={24} style={{ textAlign: "center" }}>
              {subscriptionData.status === 1 && (
                <span
                  className="custom-span-button"
                  style={{ color: "red" }}
                  onClick={handleCancelSubscription}
                >
                  <Icon icon="iconoir:cancel" /> {Transl("subscription.cancel")}
                </span>
              )}
              {subscriptionData.status === 0 && (
                <span
                  className="custom-span-button"
                  style={{ color: "blue" }}
                  onClick={handleRenewSubscription}
                >
                  <Icon icon="carbon:renew" /> {Transl("subscription.renew")}
                </span>
              )}
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  );
};

export default SubscriptionCard;
