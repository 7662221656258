import React from "react";
import { Form, Input } from "antd";
import { Row, Col } from "react-bootstrap";
import { Transl } from "../../Helpers";

export const AuthFormComponent = () => {
    return (
        <>
            <Row>
                <Col lg={12}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: Transl("field.required", {
                                    field: Transl("email"),
                                }),
                            },
                            {
                                type: "email",
                                message: Transl("valid_email_alert"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={Transl("field.placeholder.enter", {
                                field: Transl("email"),
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: Transl("field.required", {
                                    field: Transl("password"),
                                }),
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder={Transl("field.placeholder.enter", {
                                field: Transl("password"),
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
