import {
  GET_ALL_SUBSCRIPTION_REQUEST,
  GET_ALL_SUBSCRIPTION_SUCCESS,
  GET_ALL_SUBSCRIPTION_FAIL,
  GET_SELECTED_SUBSCRIPTION_REQUEST,
  GET_SELECTED_SUBSCRIPTION_SUCCESS,
  GET_SELECTED_SUBSCRIPTION_FAIL,
} from "../Constants/ActionType";

const INIT_STATE = {
  loading: false,
  data: [],
  selectedSubscription: {},
};

export const SubscriptionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true };
    case GET_ALL_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_ALL_SUBSCRIPTION_FAIL:
      return { ...state, loading: false, data: [] };
    case GET_SELECTED_SUBSCRIPTION_REQUEST:
      return { ...state, selectedSubscription: { loading: true } };
    case GET_SELECTED_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedSubscription: { loading: false, data: action.payload },
      };
    case GET_SELECTED_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        selectedSubscription: { loading: false, data: {} },
      };
    default:
      return { ...state };
  }
};
