import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Input } from "antd";
import { Icon } from "@iconify/react";
import { Transl } from "../../Helpers";

export const PasswordFormComponent = () => {
    const confirmPasswordError = Transl("password.notMatch");

    return (
        <>
            <Row>
                <Col lg={3}>
                    <h3>
                        {Transl("new.password")}&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>
                <Col lg={9}>
                    <Form.Item
                        name="password"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: Transl("field.required", {
                                    field: Transl("new.password"),
                                }),
                            },
                            {
                                pattern: new RegExp(/[0-9]{1,}/),
                                message: Transl("passRegex.requirementChar", {
                                    field: Transl("passRegex.number"),
                                }),
                            },
                            {
                                pattern: new RegExp(/[a-z]{1,}/),
                                message: Transl("passRegex.requirementChar", {
                                    field: Transl("passRegex.smallerChar"),
                                }),
                            },

                            {
                                pattern: new RegExp(/[A-Z]{1,}/),
                                message: Transl("passRegex.requirementChar", {
                                    field: Transl("passRegex.upperChar"),
                                }),
                            },
                            {
                                pattern: new RegExp(/[#?!@$%^&]{1,}/),
                                message: Transl("passRegex.requirementChar", {
                                    field: Transl("passRegex.specialChar"),
                                }),
                            },
                            {
                                pattern: new RegExp(
                                    /[0-9a-zA-Z#?!@$%^&]{8,32}/
                                ),
                                message: Transl("passRegex.8Char"),
                            },
                        ]}
                    >
                        <Input.Password
                            placeholder={Transl("field.placeholder.enter", {
                                field: Transl("new.password"),
                            })}
                            allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <h3>
                        {Transl("confirm.password")}&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>

                <Col lg={9}>
                    <Form.Item
                        name="confirmPassword"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: Transl("field.required", {
                                    field: Transl("confirm.password"),
                                }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue("password") === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(confirmPasswordError)
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            placeholder={Transl("field.placeholder.enter", {
                                field: Transl("confirm.password"),
                            })}
                            allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
