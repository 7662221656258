import React, { useState } from "react";
import UploadImage from "../Upload/UploadImage";
import { message, Modal } from "antd";
import { useDispatch } from "react-redux";
import { uploadSingleImage } from "../../Actions/StoreImageAction";
import { Transl } from "../../Helpers";

const UploadImageModal = ({
  uploadImageModalShow,
  setUploadImageModalShow,
}) => {
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);

  const handleOk = () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("image", file);
      });

      dispatch(
        uploadSingleImage({
          image: formData,
        })
      );
      setUploadImageModalShow(false);
      setFileList([]);
    } else {
      message.error("No file is uploaded.");
    }
  };

  const handleCancel = () => {
    setFileList([]);
    setUploadImageModalShow(false);
  };

  return (
    <Modal
      title={Transl("field.upload", {
        field1: Transl("upload"),
        field2: Transl("image"),
      })}
      open={uploadImageModalShow}
      okText={Transl("upload")}
      onOk={handleOk}
      cancelText={Transl("cancel")}
      onCancel={handleCancel}
    >
      <UploadImage fileList={fileList} setFileList={setFileList} />
    </Modal>
  );
};

export default UploadImageModal;
