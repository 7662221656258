import React from "react";
import { Modal } from "antd";
import { LanguageMap } from "./../../Constants/LanguageMap";
import { Transl } from "../../Helpers/LanguageHelper";

const LanguageModal = ({
    getLanguage,
    LanguageShow,
    handleLanguageClose,
    handleSaveLanguage,
}) => {
    return (
        <Modal
            title={Transl("language")}
            visible={LanguageShow}
            onCancel={handleLanguageClose}
            footer={null}
        >
            <ul>
                {Object.keys(LanguageMap)?.map((language) => (
                    <li
                        className={
                            getLanguage === language
                                ? "language-button active"
                                : "language-button"
                        }
                        key={language}
                        onClick={() => {
                            handleLanguageClose();
                            handleSaveLanguage(language);
                        }}
                    >
                        {LanguageMap[language].label}
                    </li>
                ))}
            </ul>
        </Modal>
    );
};

export default LanguageModal;
