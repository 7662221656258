import {
  GET_WEBSTORE_BUILDER_PAGE_SETTING_FAILED,
  GET_WEBSTORE_BUILDER_PAGE_SETTING_SUCCESS,
  GET_WEBSTORE_BUILDER_PAGE_SETTING,
} from "../Constants/ActionType";

const INIT_STATE = {
  loading: true,
  data: {},
  selectedPage: {},
  pageListing: [],
};

export const webstoreBuilderPages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WEBSTORE_BUILDER_PAGE_SETTING:
      return { ...state, loading: true, pageListing: [] };
    case GET_WEBSTORE_BUILDER_PAGE_SETTING_FAILED:
      return { ...state, loading: false, pageListing: [] };
    case GET_WEBSTORE_BUILDER_PAGE_SETTING_SUCCESS:
      return { ...state, loading: false, pageListing: action.data };
    default:
      return { ...state };
  }
};
