import React, { useReducer, useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ActivateNavbar,
  DeactivateNavbar,
  getWebstoreBuilderPageSetting,
  setSelectedStore,
} from "../../Actions/index";
import YouIDivDesign from "../../Components/DivDesign/YouIDivDesign";
import Iframe from "react-iframe";
import { reducerHelper } from "../../Helpers";
import BuilderHeader from "./Component/Header";
import Draggable from "react-draggable";
import "./index.scss";
import ConfigurationModal from "./Component/ConfigurationModal/index";
import _ from "lodash";
import BlockManager from "./Component/BlockManager/index";
import GridSortModal from "./Component/GridSortModal/index";
import CreatePageModal from "./Component/CreatePageModal/index";
import AdvancedPageManagement from "./Component/AdvancedPageManagement/index";
import CustomModal from "../../Components/Custom/Modal/index";
import { useHistory, useLocation } from "react-router-dom";
import { convertWindowSearchToKeyPair } from "../../Helpers/Helpers";
import ImageManagerModal from "../../Components/Modal/ImageManagerModal";

const BuilderMain = ({ ...props }) => {
  const location = useLocation();
  const history = useHistory();

  const { hideNav, pageListing, storeId, builderState } = useSelector(
    ({ webSetting, webstoreBuilderPages, coreApp, builderState }) => {
      return {
        hideNav: webSetting?.hideNav,
        pageListing: webstoreBuilderPages.pageListing,
        storeId: coreApp?.selectedStoreId,
        builderState: builderState,
      };
    }
  );

  const [builderPages, setBuilderPages] = useState([
    {
      key: 0,
      value: "addNewPage",
      title: "Create New Page",
      page_uuid: null,
      isModal: -1,
    },
  ]);

  const dispatch = useDispatch();

  const [
    {
      storeFrontAllThemeSettings,
      newBuilderPage,
      userDefinedPageIdLoaded,
      ...state
    },
    setState,
  ] = useReducer(reducerHelper, {
    grid: {
      col: 3,
      row: 1,
    },
    previewIframe: {
      width: "100%",
      height: "100%",
    },
    selectedBlock: {},
    blockManagerVisible: false,
    selectedBlockSetting: {},
    builderUIControls: {
      isLeftClose: false,
      isRightClose: false,
      grid: {
        left: 2,
        center: 8,
        right: 2,
      },
    },
    addBlockInfo: {},
    loading: false,
    iframeLoading: true,
    projectId: "aasdsa232",
    selectedPage: "home",
    requiredUpdate: false,
    storeFrontAllThemeSettings: {},
    addBlockPosition: null,
    gridSortModalVisible: false,
    selectedSectionSetting: [],
    createPageModalVisibility: false,
    storefrontPreviewLoaded: false,
    advPgMgtModalVisible: false,
    newBuilderPage: {},
    userDefinedPageIdLoaded: location?.state?.pageId ?? null,
  });

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  useEffect(() => {
    if (!_.isEmpty(pageListing)) {
      const backendPages = pageListing.reduce((carry, v) => {
        carry.push({
          key: v.page,
          value: v.pagePath,
          title: v.page,
          pageId: v.pageId,
        });
        return carry;
      }, []);
      const refetchBuilderPages = [
        {
          key: 0,
          value: "addNewPage",
          title: "Create New Page",
          isModal: -1,
        },
        ...backendPages,
      ];
      setBuilderPages(refetchBuilderPages);
      setState((prev) => ({
        ...prev,
        selectedPage: _.first(backendPages).value ?? null,
      }));
    }
  }, [pageListing]);

  useEffect(() => {
    if (!_.isEmpty(storeFrontAllThemeSettings)) {
      const storeFrontUpdatedPages = storeFrontAllThemeSettings.map((v) => {
        return { key: v.page, value: v.pagePath, title: v.page };
      });

      setBuilderPages([
        {
          key: 0,
          value: "addNewPage",
          title: "Create New Page",
          isModal: -1,
        },
        ...storeFrontUpdatedPages,
      ]);

      const latestBuilderPagefirstPagePath = !_.isEmpty(newBuilderPage)
        ? newBuilderPage
        : _.first(storeFrontUpdatedPages).value ?? null;
      setState((prev) => ({
        ...prev,
        selectedPage: latestBuilderPagefirstPagePath,
        newBuilderPage: {},
      }));
      handleChangeRoutePage(latestBuilderPagefirstPagePath);
    }
  }, [storeFrontAllThemeSettings]);

  useEffect(() => {
    if (state.storefrontPreviewLoaded && !_.isEmpty(userDefinedPageIdLoaded)) {
      handleChangeRoutePage(
        builderPages.find((v) => v.pageId == userDefinedPageIdLoaded).value ??
          null
      );
      setState((prev) => ({
        ...prev,
        userDefinedPageIdLoaded: null,
      }));
    }
  }, [state?.storefrontPreviewLoaded]);

  const blockManipulationInStoreFrontEventListener = (evt) => {
    if (evt.data?.reqKey == "yiBuilder") {
      if (evt.data.action == "edit") {
        setState((prev) => ({
          ...prev,
          selectedBlock: evt.data.data,
        }));
        showModal();
      } else if (evt?.data?.action == "retrieveLatestThemeSetting") {
        setState((prev) => ({
          ...prev,
          storeFrontAllThemeSettings: evt?.data?.data?.settings,
        }));
      } else if (evt?.data?.action == "openAddBlockManagerModal") {
        setState((prev) => ({
          ...prev,
          blockManagerVisible: true,
          selectedBlock: {
            blockId: evt?.data?.data?.blockId,
            sectionId: evt?.data?.data?.sectionId,
          },
          addBlockPosition: evt?.data?.data?.position,
        }));
        getLatestThemeSettingFromStoreFront();
      } else if (evt?.data?.action == "selectedBlockSetting") {
        setState((prev) => ({
          ...prev,
          gridSortModalVisible: true,
          selectedSectionSetting: evt?.data?.data,
        }));
      } else if (evt?.data?.action == "saveToBackend") {
        if (_.isBoolean(evt?.data?.data?.loading)) {
          message.success("Save successfully!");
          notifyToStoreFrontHandler({
            action: "resetBackendSaveWebsiteSettingProcessing",
          });
        }
      } else if (evt?.data?.action == "siteFullyLoaded") {
        setState((prev) => ({
          ...prev,
          storefrontPreviewLoaded: true,
        }));
      } else {
      }
    }
  };

  useEffect(() => {
    dispatch(setSelectedStore(props?.match?.params?.storeId));
    dispatch(getWebstoreBuilderPageSetting());
    window.addEventListener(
      "message",
      blockManipulationInStoreFrontEventListener
    );

    return () => {
      window.removeEventListener(
        "message",
        blockManipulationInStoreFrontEventListener
      );
    };
  }, []);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = (e) => {
    setOpen(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const toggleNavbar = () => {
    if (hideNav) {
      dispatch(ActivateNavbar());
    } else {
      dispatch(DeactivateNavbar());
    }
  };

  const handleIframePreview = (mode) => {
    let width = "100%";
    switch (mode) {
      case "tablet":
        width = "1080px";
        break;
      case "mobile":
        width = "500px";
        break;
      case "desktop":
        width = "100%";
        break;
      default:
        width = "100%";
        break;
    }
    setState((prev) => ({
      ...prev,
      previewIframe: {
        ...prev.previewIframe,
        width,
      },
    }));
  };

  const setChange = (
    page,
    blockId,
    settingMode,
    internalKey,
    modifyKey,
    modifyData
  ) => {
    setState((prev) => ({
      ...prev,
      selectedBlock: {
        ...prev.selectedBlock,
        settings: {
          ...prev.selectedBlock.settings,
          settings: Object.entries(prev.selectedBlock.settings.settings).reduce(
            (carry, v) => {
              let [key, val] = v;
              if (key == settingMode) {
                val[internalKey][modifyKey] = modifyData;
              }
              carry[key] = val;
              return carry;
            },
            {}
          ),
        },
      },
    }));
  };

  const handleSaveGlobal = () => {
    notifyToStoreFrontHandler({
      action: "savePageSettings",
    });
  };

  const notifyToStoreFrontHandler = (dat) => {
    document
      .getElementById("storefront-preview-iframe")
      .contentWindow.postMessage(
        {
          reqKey: "yiBuilder",
          blockId: state?.selectedBlock?.blockId ?? null,
          ...dat,
        },
        "*"
      );
  };

  useEffect(() => {
    let timerCounter = null;
    if (!_.isEmpty(state?.selectedBlock)) {
      timerCounter = setTimeout(() => {
        notifyToStoreFrontHandler({
          blockData: state?.selectedBlock?.settings,
          action: "updateBlockSetting",
          page: state?.selectedPage,
        });
      }, 500);
    }

    return () => {
      clearTimeout(timerCounter);
    };
  }, [state?.selectedBlock]);

  const openBlockManager = () => {
    setState((prev) => ({
      ...prev,
      blockManagerVisible: true,
      selectedBlock: null,
      addBlockPosition: null,
    }));
    getLatestThemeSettingFromStoreFront();
  };

  const notifyStoreFrontForAddBlock = (blockData, position, blockId = null) => {
    notifyToStoreFrontHandler({
      blockData: blockData,
      action: "addBlockComponent",
      blockId: blockId,
      page: state?.selectedPage,
      position: position,
    });
  };

  const getLatestThemeSettingFromStoreFront = () => {
    notifyToStoreFrontHandler({
      action: "retrieveThemeSetting",
    });
  };

  const handleAddBlock = (blockData) => {
    notifyStoreFrontForAddBlock(
      blockData,
      state?.addBlockPosition ?? "DOWN",
      state?.selectedBlock?.blockId
    );
  };

  const handleGridSortModalCancel = () => {
    setState((prev) => ({
      ...prev,
      gridSortModalVisible: false,
    }));
    notifyToStoreFrontHandler({
      action: "forceEndSwapRawBlockComponent",
    });
  };

  const handleGridSortModalOk = () => {
    setState((prev) => ({
      ...prev,
      gridSortModalVisible: false,
    }));
  };

  const handleChangeRoutePage = (val) => {
    setState((prev) => ({
      ...prev,
      selectedPage: builderPages.find((v) => v.value == val)?.value ?? null,
    }));
    notifyToStoreFrontHandler({ action: "changeRoute", navigateUrl: val });
  };

  const handleCreatePageModalVisible = (val) => {
    setState((prev) => ({
      ...prev,
      createPageModalVisibility: val,
    }));
  };

  const handleCreatePageModal = () => {
    handleCreatePageModalVisible(true);
  };

  const handleCreatePageSubmit = (dat) => {
    setBuilderPages((prev) => [
      ...prev,
      { key: dat.page, title: dat.page, value: dat.pagePath },
    ]);
    setState((prev) => ({
      ...prev,
      selectedPage: dat?.pagePath,
      newBuilderPage: dat?.pagePath,
    }));
    handleCreatePageModalVisible(false);
    notifyToStoreFrontHandler({
      action: "addNewPage",
      data: { pagePath: dat?.pagePath, page: dat.page },
    });
    getLatestThemeSettingFromStoreFront();
  };

  const handleAdvPgMgtCancel = () => {
    setState((prev) => ({ ...prev, advPgMgtModalVisible: false }));
  };

  const handleAdvPgMgtOk = () => {
    setState((prev) => ({ ...prev, advPgMgtModalVisible: false }));
  };

  const handleOpenAdvancedPageManagementModal = () => {
    getLatestThemeSettingFromStoreFront();
    setState((prev) => ({ ...prev, advPgMgtModalVisible: true }));
  };

  return (
    <React.Fragment>
      <YouIDivDesign>
        <div
          className="container-fluid"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <BuilderHeader
            state={state}
            storeId={storeId}
            setState={setState}
            toggleComponents={null}
            addBlockLayout={null}
            handleIframePreview={handleIframePreview}
            handleSaveGlobal={handleSaveGlobal}
            loading={state?.loading ?? false}
            notifyStoreFront={null}
            iframeLoading={state?.iframeLoading}
            toggleNavbar={toggleNavbar}
            showModal={showModal}
            openBlockManager={openBlockManager}
            notifyToStoreFrontHandler={notifyToStoreFrontHandler}
            handleChangeRoutePage={handleChangeRoutePage}
            pages={builderPages}
            handleCreatePageModal={handleCreatePageModal}
            selectedPage={state?.selectedPage}
            storefrontPreviewLoaded={state?.storefrontPreviewLoaded}
            handleOpenAdvancedPageManagementModal={
              handleOpenAdvancedPageManagementModal
            }
            {...props}
          />
          <div
            className="row justify-content-center"
            style={{ height: "80vh" }}
          >
            <div className="col-12" style={{ textAlign: "center" }}>
              <Iframe
                //url={process.env.REACT_APP_BUILDER_IFRAME_URL}
                // url="http://localhost:3000/home?isPreview=1"
                url="https://webstore.conn3ct.me/home?isPreview=1"
                width={state?.previewIframe?.width ?? "100%"}
                height={state?.previewIframe?.height ?? "100%"}
                id="storefront-preview-iframe"
                className="myClassname"
                display="initial"
                style={{ height: "inherit" }}
                position="relative"
                onLoad={() => {
                  setState({ iframeLoading: false });
                }}
              />
            </div>
          </div>
        </div>
        <CustomModal
          className="builder-configuration-modal"
          centered
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }} // fix eslintjsx-a11y/mouse-events-have-key-events
              // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
              onFocus={() => {}}
              onBlur={() => {}} // end
            >
              Block Configuration
            </div>
          }
          open={open}
          footer={null}
          onCancel={handleCancel}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          width={1080}
        >
          <ConfigurationModal
            setChange={setChange}
            selectedBlock={state?.selectedBlock}
          />
        </CustomModal>
        <GridSortModal
          handleCancel={handleGridSortModalCancel}
          handleOk={handleGridSortModalOk}
          gridSortData={state?.selectedSectionSetting}
          gridSortModalVisible={state?.gridSortModalVisible}
          notifyToStoreFrontHandler={notifyToStoreFrontHandler}
        />
        <BlockManager
          modalVisible={state?.blockManagerVisible}
          selectedBlock={state?.selectedBlock}
          handleModalVisible={(status) => {
            setState((prev) => ({
              ...prev,
              blockManagerVisible: status,
              addBlockInfo: {},
            }));
          }}
          addBlock={handleAddBlock}
        />
        <CreatePageModal
          modalVisible={state?.createPageModalVisibility}
          builderPages={builderPages}
          handleCreatePageSubmit={handleCreatePageSubmit}
          handleModalVisible={handleCreatePageModalVisible}
        />
        <ImageManagerModal
          imageManagerModalShow={builderState.imageManager.modalOpen}
          setChange={setChange}
        />
        <AdvancedPageManagement
          advPgMgtModalVisible={state?.advPgMgtModalVisible}
          handleAdvPgMgtOk={handleAdvPgMgtOk}
          handleAdvPgMgtCancel={handleAdvPgMgtCancel}
          storeFrontAllThemeSettings={storeFrontAllThemeSettings}
          notifyToStoreFrontHandler={notifyToStoreFrontHandler}
          handleCreatePageModal={handleCreatePageModal}
        />
      </YouIDivDesign>

      <Button
        className="global-toggle-navbar"
        style={{ float: "right", top: "50vh" }}
        onClick={toggleNavbar}
      >
        {hideNav ? "Show" : "Hide"}
      </Button>
    </React.Fragment>
  );
};

export default BuilderMain;
