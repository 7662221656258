import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import ImageManagerComponent from "../ImageManager/ImageManagerComponent";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { closeImageModal } from "../../Actions/BuilderAction";
import Styles from "./ImageManagerModal.module.scss";
import { Icon } from "@iconify/react";
import baselineClose from "@iconify/icons-ic/baseline-close";

const ImageManagerModal = ({
  imageManagerModalShow,
  multipleSelect,
  setChange = null,
  disableSetChange = false,
}) => {
  const dispatch = useDispatch();
  const {
    page,
    blockId,
    settingMode,
    internalKey,
    modifyKey,
    data,
    selectedIndex,
  } = useSelector(({ builderState }) => {
    return {
      page: builderState?.imageManager?.page,
      blockId: builderState?.imageManager?.blockId,
      settingMode: builderState?.imageManager?.settingMode,
      internalKey: builderState?.imageManager?.internalKey,
      modifyKey: builderState?.imageManager?.modifyKey,
      data: builderState?.imageManager?.modifyData,
      selectedIndex: builderState?.imageManager?.selectedIndex,
    };
  });
  const [isModal] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  useEffect(() => {
    if (!imageManagerModalShow) {
      setSelectedImage("");
    }
  }, [imageManagerModalShow]);

  const handleOk = () => {
    if (selectedImage.length === 0) return;

    if (!disableSetChange && !multipleSelect) {
      let newData = data.map((element, index) => {
        if (index === selectedIndex) {
          element["img"] = selectedImage[0];
        }
        return element;
      });

      setChange(page, blockId, settingMode, internalKey, modifyKey, newData);
    }

    dispatch(closeImageModal());
  };

  const handleCancel = () => {
    dispatch(closeImageModal());
  };

  const removeSelectedImage = (index) => {
    const tempArray = [...selectedImage];
    tempArray.splice(index, 1);
    setSelectedImage(tempArray);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) return;

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <Modal
        className="widerModal"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            Image
          </div>
        }
        visible={imageManagerModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1800}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <ImageManagerComponent
          isModal={isModal}
          multipleSelect={multipleSelect}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
        {multipleSelect && selectedImage.length !== 0 ? (
          <div className={Styles.selectedImageOuterContainer}>
            <span>Selected Image:</span>
            <div className={Styles.selectedImageInnerContainer}>
              {selectedImage.map((element, index) => (
                <div
                  className={Styles.imageContainer}
                  key={`${element}Container`}
                >
                  <div
                    className={Styles.closeButton}
                    onClick={() => removeSelectedImage(index)}
                  >
                    <Icon icon={baselineClose} />
                  </div>
                  <img src={element} />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default ImageManagerModal;
