export const NextStepAction = () => {
    return {
        type: "INCREASE_STEP",
    };
};

export const PreviousStepAction = () => {
    return {
        type: "DECREASE_STEP",
    };
};
