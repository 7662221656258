import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./Reducers";
import Spinner from "./Components/Spinner/Spinner";

import "./Constants/i18nextInit";

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
    <Suspense fallback={<Spinner fullHeight />}>
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>,
    document.getElementById("root")
);
