import { Card, Col, Row, Skeleton } from "antd";
import React, { useEffect } from "react";
import { AppRoutes } from "../../../Constants";
import "./index.scss";
import appsIcon from '@iconify/icons-charm/apps';
import { Icon } from "@iconify/react";

import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import YouIFullWidthContent from "../../../Components/DivDesign/YouIFullWidthContent";
import BreadcrumbComponent from "../../../Components/Breadcrumb/BreadcrumbComponent";

import { Breadcrumb } from "antd";
import { Link, useHistory } from "react-router-dom";
import storeIcon from "@iconify/icons-dashicons/store";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStore } from "../../../Actions";
import _ from "lodash";
import { NAV_LOGO } from "../../../Constants/Variables";

const StoreDetail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedStore } = useSelector(({ coreApp }) => {
    return {
      selectedStore: coreApp?.selectedStore,
    };
  });

  useEffect(() => {
    if (props?.match?.params?.storeId) {
      dispatch(setSelectedStore(props?.match?.params?.storeId));
    }
  }, [dispatch, props?.match?.params?.storeId, history]);

  const StoreManagementBreadcrumb = () => {
    return (
      <BreadcrumbComponent>
         <Breadcrumb.Item>
          <Icon icon={storeIcon} />
          &nbsp;&nbsp;
          <Link to={AppRoutes.STORE}> Store</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Icon icon={appsIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(AppRoutes.STOREDETAIL, ":storeId", selectedStore?.id)}
          >
            {" "}
            {selectedStore?.store_name} Apps
          </Link>
        </Breadcrumb.Item>
      </BreadcrumbComponent>
    );
  };

  const pageIntro = [
    {
      title: "Page Management",
      img: NAV_LOGO,
      description: "Page Management allows you to manage your pages easily!",
      route: _.replace(AppRoutes.STOREPAGES, ":storeId", selectedStore?.id),
    },
    {
      title: "Domain Management",
      img: NAV_LOGO,
      description:
        "With API management, you can integrate your existing dataset easily into your stores!",
      route: _.replace(AppRoutes.STOREDOMAIN, ":storeId", selectedStore?.id),
    },
    {
      title: "Webstore Builder",
      img: NAV_LOGO,
      description:
        "With API management, you can integrate your existing dataset easily into your stores!",
      route: _.replace(AppRoutes.STOREBUILDER, ":storeId", selectedStore?.id),
    },
    {
      title: "Menu Management",
      img: NAV_LOGO,
      description: "Menu manage allows you to customise the menu sets!",
      route: "/test",
    },
    {
      title: "Image Management",
      img: NAV_LOGO,
      description: "You can easily manage your image in manage management!",
      route: _.replace(AppRoutes.STOREIMAGE, ":storeId", selectedStore?.id),
    },
    // {
    //   title: "API Management",
    //   img: "https://conn3ct.me/images/Transparent-Logo.png",
    //   description:
    //     "With API management, you can integrate your existing dataset easily into your stores!",
    //   route: "/test",
    // },
  ];

  return (
    <>
      <YouIDivDesign>
        <YouIFullWidthContent
          breadcrumbComp={<StoreManagementBreadcrumb />}
          withFooter
          customContentStyle={{ height: "100%" }}
          customBackgroundStyle={{ height: "100%", minHeight: "100%" }}
          title={`${selectedStore.store_name ?? ""} Store Apps`}
          // additionalPageTitleContent={
          //   <>Selected Store: </>
          // }
        >
          <div className="site-card-wrapper">
            <Row>
              {_.isEmpty(selectedStore) ? (
                <Skeleton active />
              ) : (
                pageIntro.map(({ title, img, description, route }) => {
                  return (
                    <Col
                      className="store-detail-card-wrapper"
                      onClick={() => history.push(route)}
                      lg={6}
                      xl={6}
                      xs={24}
                      sm={24}
                      md={24}
                    >
                      <Card
                        className="store-detail-card"
                        bordered={true}
                        cover={
                          <img alt={`Management Cover ${title}`} src={img} />
                        }
                      >
                        <Card.Meta title={title} description={description} />
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </div>
        </YouIFullWidthContent>
      </YouIDivDesign>
    </>
  );
};

export default StoreDetail;
