import React from "react";
import { Icon } from "@iconify/react";
import alignRightOutlined from '@iconify/icons-ant-design/align-right-outlined';
import alignCenterOutlined from '@iconify/icons-ant-design/align-center-outlined';
import alignLeftOutlined from '@iconify/icons-ant-design/align-left-outlined';

const TextAlignment = ({
  handleClick,
  defaultValue,
  ...props
}) => {
  return (
    <div className="row">
      <div className="col-lg-4" style={{fontSize:"15px"}}>
        <div
          className="btn-group float-right builder-custom-editor-container builder-view-control-group"
          role="group"
          aria-label="Basic example"
          style={{ justifyContent: "center" }}
        >
          <span onClick={() => handleClick('text-alignment-left')} className={`custom-span-button view-controls ${defaultValue == "text-alignment-left" && "active"}`}>
            <Icon icon={alignLeftOutlined} />
          </span>
          <span onClick={() => handleClick('text-alignment-center')} className={`custom-span-button view-controls ${defaultValue == "text-alignment-center" && "active"}`}>
            <Icon icon={alignCenterOutlined} />
          </span>
          <span onClick={() => handleClick('text-alignment-right')} className={`custom-span-button view-controls ${defaultValue == "text-alignment-right" && "active"}`}>
            <Icon icon={alignRightOutlined} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TextAlignment;
