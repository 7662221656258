export const AppRoutes = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGETPASSWORD: "/forget-password",
  RESETPASSWORD: "/forgot-password",
  CONFIRMEMAIL: "/email-confirm",
  PROFILE: "/profile",
  PROJECT: "/project",
  IMAGEMANAGER: "/image-manager",
  BUILDER: "/builder",
  STORE: "/store",
  STOREMAIN: "/store/*",
  STORELISTING: "/store/all",
  STOREDETAIL: "/store/:storeId",
  STOREPAGES: "/store/:storeId/pages",
  STOREPAGEEDITOR: "/store/:storeId/page/:pageId",
  STOREDOMAIN: "/store/:storeId/domain",
  STOREIMAGE: "/store/:storeId/image",
  TESTING: "/testing",
  STOREBUILDER: "/store/:storeId/builder",
  SUBSCRIPTION: "/subscription",
  USERSETTING: "user-setting",
  USERAPP: "user-app",
};
