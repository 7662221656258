import {
  CLOSE_IMAGE_MODAL,
  INSERT_IMAGE_MODAL_DATA,
  OPEN_MULTIPLE_SELECT_IMAGE_MODAL,
  OPEN_SINGLE_SELECT_IMAGE_MODAL,
} from "../Constants/ActionType";

export const openSingleSelectImageModal = () => {
  return {
    type: OPEN_SINGLE_SELECT_IMAGE_MODAL,
  };
};

export const openMultipleSelectImageModal = () => {
  return {
    type: OPEN_MULTIPLE_SELECT_IMAGE_MODAL,
  };
};

export const updateImageModalData = (values) => {
  return {
    type: INSERT_IMAGE_MODAL_DATA,
    page: values.page,
    blockId: values.blockId,
    settingMode: values.settingMode,
    internalKey: values.internalKey,
    modifyKey: values.modifyKey,
    modifyData: values.modifyData,
    selectedIndex: values.selectedIndex,
  };
};

export const closeImageModal = () => {
  return {
    type: CLOSE_IMAGE_MODAL,
  };
};
