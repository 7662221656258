/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
/// <reference path= "../../index.d.ts" />
import React from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/image_manager.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/line_breaker.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/quick_insert.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/file.min';

import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/js/third_party/spell_checker.min';

const CKEditor = React.forwardRef(
  (
    {
      model,
      onModelChange,
      onInputEvent,
      onInitialize,
      onBlur,
      disableUploadImage,
      disableQuickInsert,
      disableEmoticons,
      customHeight = null,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <FroalaEditorComponent
          ref={ref}
          model={model}
          onModelChange={onModelChange}
          config={{
            videoUpload: false,
            key: null,
            height:(customHeight !== null ? customHeight : ""),
            // imageUploadParam: 'uploads',
            // imageUploadParams: { model_folder: 'texteditor' },
            // imageAllowedTypes: ['jpeg', 'png', 'jpg'],
            // imageManagerLoadParams: { page: 1, tag: 'Uploaded Images' },
            // imageUploadURL: `${REACT_APP_API_URL}/upload`,
            // imageMaxSize: 2 * 1024 * 1024,
            // imageManagerLoadURL: `${REACT_APP_API_URL}/upload`,
            // imageManagerDeleteURL: `${REACT_APP_API_URL}/upload/editor/delete`,
            // imageDefaultWidth: 0,
            // fileUploadParams: { model_folder: 'texteditor/uploadedFile' },
            // fileAllowedTypes: ['application/pdf', 'application/msword'],
            // fileUploadURL: `${REACT_APP_API_URL}/upload`,
            // fileMaxSize: 15 * 1024 * 1024,
            // requestHeaders: {
            //   Authorization: `Bearer ${getCookie('accessTokenInv')}`,
            //   'Store-ID': getCookie('std'),
            // },
            fillEmptyBlocks: false,
            toolbarSticky: false,
            // Button Layout for Froala Editor
            toolbarButtons: {
              moreText: {
                buttons: [
                  'bold',
                  'italic',
                  'underline',
                  'strikeThrough',
                  'subscript',
                  'superscript',
                  'fontFamily',
                  'fontSize',
                  'textColor',
                  'backgroundColor',
                  'inlineClass',
                  'inlineStyle',
                  'clearFormatting',
                  'markdown',
                ],
                align: 'left',
                buttonsVisible: 3,
              },
              moreParagraph: {
                buttons: [
                  'alignLeft',
                  'alignCenter',
                  'formatOLSimple',
                  'alignRight',
                  'alignJustify',
                  'formatOL',
                  'formatUL',
                  'paragraphFormat',
                  'paragraphStyle',
                  'lineHeight',
                  'outdent',
                  'indent',
                  'quote',
                ],
                align: 'left',
                buttonsVisible: 3,
              },
              moreRich: {
                buttons: [
                  ...(disableUploadImage ? [] : ['insertImage']),
                  'insertLink',
                  'insertFile',
                  'fileCustomManager',
                  'insertTable',
                  ...(disableEmoticons ? [] : ['emoticons']),
                  'specialCharacters',
                  'embedly',
                  'insertVideo',
                  'insertHR',
                ],
                align: 'left',
                buttonsVisible: 4,
              },
              moreMisc: {
                buttons: [
                  'undo',
                  'redo',
                  'fullscreen',
                  'print',
                  'getPDF',
                  //'spellChecker',
                  'selectAll',
                  'html',
                  'help',
                ],
                align: 'right',
                buttonsVisible: 2,
              },
            },
            // plugins that are enabled currently for the Froala Editor
            pluginsEnabled: [
              'align',
              'charCounter',
              'codeBeautifier',
              'codeView',
              'colors',
              'draggable',
              'embedly',
              'emoticons',
              'entities',
              'file',
              // can be found in FileManager.js
              'fileCustomManager',
              'fontFamily',
              'fontSize',
              'fullscreen',
              'help',
              'image',
              'imageTUI',
              'imageManager',
              'inlineStyle',
              'inlineClass',
              'lineBreaker',
              'lineHeight',
              'link',
              'lists',
              'paragraphFormat',
              'paragraphStyle',
              'print',
              ...(disableQuickInsert ? [] : ['quickInsert']),
              'quote',
              'save',
              'specialCharacters',
              //'spellChecker',
              'table',
              'track_changes',
              'markdown',
              'url',
              'video',
              'wordPaste',
            ],
            events: {
              blur() {
                if (onBlur) onBlur(this);
              },
              initialized() {
                const document = this.doc;
                const logo = document.querySelector('#fr-logo');
                if (!logo) {
                  return;
                }
                logo.remove();
                if (onInitialize) onInitialize(this);
              },
              input: onInputEvent,
            },
          }}
          tag="textarea"
          {...props}
        />
        {/* <FileManagerModal /> */}
      </>
    );
  },
);

export default CKEditor;