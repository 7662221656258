import React, { useEffect } from "react";
import { Input, notification, Form, Switch } from "antd";
import { formErrorHandler } from "../../../../../Helpers";
import { useDispatch } from "react-redux";
import { createWebstoreDomain } from "../../../../../Actions";
import _ from "lodash";
import CustomModal from "../../../../../Components/Custom/Modal/index";

const CreateDomainModal = ({
  createDomainModalVisible,
  modalSubmit,
  handleCreateDomainModalCancel,
  handleCreateDomainFinish,
  storeId,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleCreateDomainFinishFailed = () => {
    notification["error"]({
      message: "Form Validation",
      description: "Please fill-in all the required fields",
    });
  };
  const [form] = Form.useForm();

  const handleModalOk = () => {
    form.submit();
    modalSubmit();
  };

  const handleCreateDomainFinishSuccess = (values) => {
    if (_.isUndefined(values?.status)) {
      values.status = false;
    }
    handleCreateDomainFinish(values);
    dispatch(
      createWebstoreDomain(values, (status, err) => {
        if (status === "failed" && err !== null) {
          formErrorHandler(form, err?.response, null);
        } else {
          handleCreateDomainModalCancel();
        }
      })
    );
  };

  useEffect(() => {
    if (!createDomainModalVisible) {
      form.resetFields();
    }
  }, [createDomainModalVisible, form]);

  return (
    <CustomModal
      title="Create Domain"
      visible={createDomainModalVisible}
      onOk={handleModalOk}
      onCancel={handleCreateDomainModalCancel}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleCreateDomainFinishSuccess}
        onFinishFailed={handleCreateDomainFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Domain"
          name="domain"
          rules={[
            {
              required: true,
              message: "Domain is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Switch />
        </Form.Item>
        <Form.Item label="SSL" name="is_secure">
          <Switch />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default CreateDomainModal;
