import { Tag, Button, Switch, Table, Modal } from "antd";
import React from "react";
import { Icon } from "@iconify/react";
import trashIcon from "@iconify/icons-bi/trash";
import padlockIcon from "@iconify/icons-charm/padlock";
import { useDispatch } from "react-redux";
import {
    deleteWebstoreDomain,
    updateWebstoreDomainSetting,
} from "../../../../Actions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

const IndexTableColumn = ({
    webstoreDomain,
    setState,
    pagination,
    filters,
    storeId,
    ...props
}) => {
    const dispatch = useDispatch();

    const handleIsSecureOnChange = (id) => {
        dispatch(updateWebstoreDomainSetting(id, { key: "is_secure" }));
    };

    const handleStatusOnChange = (id) => {
        dispatch(updateWebstoreDomainSetting(id, { key: "status" }));
    };

    const handleMarksAsPrimary = (id) => {
        dispatch(updateWebstoreDomainSetting(id, { key: "is_primary" }));
    };

    const performDeleteDomain = (id) => {
        Modal.confirm({
            title: "Domain Deletion",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure to perform delete?",
            okText: "Confirm",
            cancelText: "Cancel",
            onOk: () => dispatch(deleteWebstoreDomain(id)),
        });
    };

    const columns = [
        {
            title: "Domain",
            dataIndex: "domain",
            sorter: true,
            render: (text, record) => {
                return (
                    <>
                        {text}{" "}
                        {record?.is_secure === 1 && (
                            <Tag color="gold">
                                <Icon icon={padlockIcon} /> Secure
                            </Tag>
                        )}
                        {record?.is_primary === 1 && (
                            <Tag color="blue">Primary</Tag>
                        )}
                    </>
                );
            },
            width: "50%",
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                return (
                    <Switch
                        loading={record?.loading}
                        onChange={() => handleStatusOnChange(record?.id)}
                        defaultChecked={text}
                    />
                );
            },
            width: "20%",
        },
        {
            title: "SSL",
            dataIndex: "is_secure",
            render: (text, record) => {
                return (
                    <Switch
                        onChange={() => handleIsSecureOnChange(record?.id)}
                        defaultChecked={text}
                        loading={record?.loading}
                    />
                );
            },
        },
        {
            title: "Action",
            dataIndex: "is_primary",
            render: (text, record) => {
                if (text === 0 && record?.is_deletable == 1) {
                    return (
                        <>
                            <Button
                                loading={record?.loading}
                                onClick={() => handleMarksAsPrimary(record?.id)}
                                type="link"
                            >
                                Mark As Primary
                            </Button>
                            <Button
                                type="text"
                                onClick={() => performDeleteDomain(record?.id)}
                                icon={<Icon icon={trashIcon} />}
                            >
                                Delete
                            </Button>
                        </>
                    );
                } else {
                    return <></>;
                }
            },
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setState((prev) => ({
            ...prev,
            pagination: {
                page: pagination?.current,
                limit: pagination?.pageSize,
            },
            filters,
            sort: _.isUndefined(sorter?.order)
                ? null
                : `${sorter?.order === "ascend" ? "+" : "-"}${sorter?.field}`,
        }));
    };

    return (
        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={webstoreDomain?.data?.data}
            pagination={false}
            loading={webstoreDomain?.loading}
            onChange={handleTableChange}
        />
    );
};

export default IndexTableColumn;
