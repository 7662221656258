import "./style.scss";
import { Tabs } from "antd";
import React from "react";
const { TabPane } = Tabs;

const TableTab = ({
  onChange,
  tabLists,
  defaultActiveKey,
  activeKey,
  ...props
}) => {
  return (
    <Tabs
      {...props}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      onChange={onChange}
    >
      {(tabLists ?? []).map((tab, key) => {
        return (
          <TabPane tab={tab?.name ?? "null"} key={tab?.key ?? key}>
            {tab.content}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default TableTab;
