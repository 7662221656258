import React, { useEffect, useReducer } from "react";
import { InputNumber, Select } from "antd";
import { reducerHelper } from "../../../Helpers/Helpers";
import _ from "lodash";

const ContainerDimensionEditor = ({ onChange, defaultValue, ...props }) => {
  const unitOptions = ["px", "pt", "cm", "mm"];

  const dimensionArray = _.isString(defaultValue) ? defaultValue.split(/[ ,]+/) : [];

  const [{
    unitDimension,
    numericDimension
  },setState] = useReducer(reducerHelper,{
    unitDimension:dimensionArray.map((v) => {
        return v.replace(/[0-9]/g, '');
    }),
    numericDimension:dimensionArray.map((v) => {
        return parseInt(v);
    }),
  })

  const changeUnit = (val,index) => {
    setState((prev) => ({
        ...prev,
        unitDimension:(prev.unitDimension).map((v,i) => {
            if (i == index){
                v = val;
            }
            return v;
        })
    }));

  }

  const changeDimensionValue = (val,index) => {
    setState((prev) => ({
        ...prev,
        numericDimension:(prev.numericDimension).map((v,i) => {
            if (i == index){
                v = val;
            }
            return v;
        })
    }))
  }

  useEffect(() => {
    const updatedValue = (numericDimension).reduce((carry,item,index) => {
        return [...carry,`${item}${unitDimension[index]}`];
    },[]);
    onChange(updatedValue.join(" "));
  },[numericDimension,unitDimension]);

  const renderSelection = (df,index) => {
    return (
      <Select onChange={(val) => changeUnit(val,index)} defaultValue={df}>
        {unitOptions.map((v) => {
          return <Select.Option value={v}>{v}</Select.Option>;
        })}
      </Select>
    );
  };

  const dimensionValueExcludeUnit = (val) => {
    const w = parseInt(val);
    return w;
  }

  // const
  return (
    <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
      <div className="row justify-content-center">
        <div className="col-auto">
          <InputNumber onChange={(val) => changeDimensionValue(val,0)} defaultValue={dimensionValueExcludeUnit(dimensionArray[0])} min="0" />
          {renderSelection("px",0)}
        </div>
      </div>
      <div className="row justify-content-flex-start">
        <div className="col-auto align-self-center">
          <InputNumber onChange={(val) => changeDimensionValue(val,3)} defaultValue={dimensionValueExcludeUnit(dimensionArray[3])} min="0" />
          {renderSelection("px",3)}
        </div>
        <div
          style={{
            height: "70px",
            width: "70px",
            margin: "10px",
            backgroundColor: "grey",
          }}
        ></div>
        <div className="col-auto align-self-center">
          <InputNumber onChange={(val) => changeDimensionValue(val,1)} defaultValue={dimensionValueExcludeUnit(dimensionArray[1])} min="0" />
          {renderSelection("px",1)}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <InputNumber onChange={(val) => changeDimensionValue(val,2)} defaultValue={dimensionValueExcludeUnit(dimensionArray[2])} min="0" />
          {renderSelection("px",2)}
        </div>
      </div>
    </div>
  );
};

export default ContainerDimensionEditor;
