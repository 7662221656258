import React from "react";
import { Form, Input, Button } from "antd";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChangePassword } from "../../../../Actions";
import {
  PasswordFormComponent,
} from "../../../../Components/Form";
import { Transl } from "../../../../Helpers";

const ChangePasswordComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const handleSubmitPassword = (value) => {
    dispatch(
        ChangePassword({
            current_password: value.currentPassword,
            new_password: value.password,
            confirm_password: value.confirmPassword,
        }),
        history
    );
    form.resetFields();
};

  return (
    <Form onFinish={handleSubmitPassword} form={form}>
      <Row>
        <Col lg={3}>
          <h3>
            {Transl("current.password")}
            :&nbsp;
          </h3>
        </Col>
        <Col lg={9}>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: Transl("field.required", {
                  field: Transl("current.password"),
                }),
              },
            ]}
          >
            <Input.Password
              placeholder={Transl("field.placeholder.enter", {
                field: Transl("current.password"),
              })}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <PasswordFormComponent />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordComponent;
