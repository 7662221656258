import {
    DISABLE_FOOTER,
    DISABLE_MOBILE_VIEW,
    ENABLE_FOOTER,
    ENABLE_MOBILE_VIEW,
    HIDE_NAVBAR,
    SHOW_NAVBAR,
} from "../Constants/ActionType";

export const ActivateNavbar = () => {
    return {
        type: SHOW_NAVBAR,
    };
};

export const DeactivateNavbar = () => {
    return {
        type: HIDE_NAVBAR,
    };
};

export const ActivateMobileView = () => {
    return {
        type: ENABLE_MOBILE_VIEW,
    };
};

export const DeactivateMobileView = () => {
    return {
        type: DISABLE_MOBILE_VIEW,
    };
};

export const ActivateFooter = () => {
    return {
        type: ENABLE_FOOTER,
    };
};

export const DeactivateFooter = () => {
    return {
        type: DISABLE_FOOTER,
    };
};
