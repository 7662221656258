import { GET_WEBSTORE_BUILDER_PAGE_SETTING, GET_WEBSTORE_BUILDER_PAGE_SETTING_FAILED, GET_WEBSTORE_BUILDER_PAGE_SETTING_SUCCESS } from "../Constants/ActionType";
import BuilderApi from "../Inceptors/BuilderApi"

export const getWebstoreBuilderPageSetting = () => (dispatch) => {
    dispatch({type:GET_WEBSTORE_BUILDER_PAGE_SETTING});
    BuilderApi.get("/api/builder/page/settings").then((response) => {
        dispatch({type:GET_WEBSTORE_BUILDER_PAGE_SETTING_SUCCESS,data:response?.data?.response?.data.settings});
    }).catch((err) =>{
        console.error(err.message);
        dispatch({type:GET_WEBSTORE_BUILDER_PAGE_SETTING_FAILED});
    });
}