import AuthApi from "../Inceptors/AuthApi";

export const CountryLocationAction = () => (dispatch) => {
    dispatch({ type: "FETCH_COUNTRY_REQUEST" });
    AuthApi.get("/api/v1/country")
        .then(function (response) {
            dispatch({
                type: "FETCH_COUNTRY_SUCCESS",
                payload: response.data?.response?.data ?? [],
            });
        })
        .catch(function (error) {
            dispatch({
                type: "FETCH_COUNTRY_FAILURE",
                error: error.message ?? error?.response?.data?.error ?? null,
            });
        });
};

export const StateLocationAction = (country) => (dispatch) => {
    dispatch({ type: "FETCH_STATE_REQUEST" });
    AuthApi.get("/api/v1/state/" + country)
        .then(function (response) {
            dispatch({
                type: "FETCH_STATE_SUCCESS",
                payload: response.data?.response?.data ?? [],
            });
        })
        .catch(function (error) {
            dispatch({
                type: "FETCH_STATE_FAILURE",
                error: error.message ?? error?.response?.data?.error ?? null,
            });
        });
};
