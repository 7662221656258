import common from "./common.json";
import main from "./translation.json";
import builder from "./modules/builder.json";
import subscription from "./modules/subscription.json";
import planpackage from "./modules/planpackage.json";
import imageManagement from "./modules/imageManagement.json";

const translationZHCN = {
  ...common,
  ...main,
  ...builder,
  ...subscription,
  ...planpackage,
  ...imageManagement,
};
export default translationZHCN;
