import React, { useState, useReducer, useEffect, useRef } from "react";
import YouIDivDesign from "../../../../Components/DivDesign/YouIDivDesign";
import YouIMyContent from "../../../../Components/DivDesign/YouIMyContent";
import { reducerHelper, Transl } from "../../../../Helpers";

import styled from "./Subscription.module.scss";
import { Icon } from "@iconify/react";
import CustomPagination from "../../../../Components/CustomPagination";
import SearchPanel from "Components/SearchPanel";
import TableTab from "Components/TableTab";
import CustomRangePicker from "../../../../Components/Custom/RangePicker";
import moment from "moment";
import { API_TABLE_ROWS_PER_PAGE } from "../../../../Constants/AppConfig";

import { Row, Col, Card, Button, Tag, Table, Tabs, Form, Radio } from "antd";
import { getAllSubscription } from "../../../../Actions/SubscriptionAction";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionCard from "./Component/SubscriptionCard";
import PackageModal from "../../../../Components/PackageModal/PackageModal";
import { getAllPlanPackage } from "../../../../Actions/PackageAction";
import { isMobileOnly, isTablet } from "react-device-detect";
import CustomModal from "../../../../Components/Custom/Modal/index";

const SubscriptionMain = ({ ...props }) => {
  const dispatch = useDispatch();

  const { subscriptionLoading, subscriptionData, planPackageDetail } =
    useSelector(({ subscription, planPackage }) => {
      return {
        subscriptionLoading: subscription?.loading,
        subscriptionData: subscription?.data,
        planPackageDetail: planPackage,
      };
    });

  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isPaymentMethodOpen, setIsPaymentMethodOpen] = useState(false);
  const [
    isChangeSubscriptionSelectorOpen,
    setIsChangeSubscriptionSelectorOpen,
  ] = useState(false);
  const [isManualPaymentMethodOpen, setIsManualPaymentMethodOpen] =
    useState(false);
  const [isModifyPaymentMethodOpen, setIsModifyPaymentMethodOpen] =
    useState(false);
  const [isManualPaymentRequestOpen, setIsManualPaymentRequestOpen] =
    useState(false);
  const [controlVisible, setControlVisible] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();

  const searchRef = useRef(null);

  const [filterForm] = Form.useForm();

  const initialSearchOptions = "store_name";

  const searchOptions = [
    {
      key: "Store Name",
      value: "store_name",
    },
    {
      key: "Domain",
      value: "domain",
    },
  ];

  const tabLists = [
    {
      name: Transl("common.all"),
      key: -1,
    },
    {
      name: Transl("common.active"),
      key: 1,
    },
    {
      name: Transl("common.inactive"),
      key: 0,
    },
  ];

  const onPaginationChange = (page, pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page,
        limit: pageSize,
      },
    }));
  };

  const onShowSizeChange = (current, size) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page: current,
        limit: size,
      },
    }));
  };

  const onTabOnChange = (key) => {
    let filterObjectExclStatus = filterObject.filter((v) => v.key !== "status");
    let filterTagExclStatus = filterTag.filter((v) => v.key !== "status");
    let stsValue = Transl("common.all");

    if (key !== -1) {
      filterObjectExclStatus = [
        ...filterObjectExclStatus,
        { key: "status", value: key },
      ];
      switch (parseInt(key)) {
        case 0:
          stsValue = Transl("common.inactive");
          break;
        case 1:
          stsValue = Transl("common.active");
          break;
        default:
          break;
      }
    }

    filterTagExclStatus = [
      ...filterTagExclStatus,
      { key: "status", value: `${Transl("common.status")} : ${stsValue}` },
    ];
    setFilter((prev) => ({
      ...prev,
      filterObject: filterObjectExclStatus,
      filterTag: filterTagExclStatus,
    }));
    setState((prev) => ({
      ...prev,
      defaultActiveKey: key,
    }));
  };

  const handleNextBillingDatePicker = (date, dateString) => {
    if (_.isNull(date)) {
      filterForm.setFieldsValue({
        next_billing_date: "",
      });
      return;
    }
    filterForm.setFieldsValue({
      next_billing_date: [moment(date[0]), moment(date[1])],
    });
  };

  const handleExpiryDatePicker = (date, dateString) => {
    if (_.isNull(date)) {
      filterForm.setFieldsValue({
        end_date: "",
      });
      return;
    }
    filterForm.setFieldsValue({
      end_date: [moment(date[0]), moment(date[1])],
    });
  };

  const filterOptions = [
    {
      label: "Next Billing Date",
      name: "next_billing_date",
      component: <CustomRangePicker onChange={handleNextBillingDatePicker} />,
    },
    {
      label: "Expiry Date",
      name: "end_date",
      component: <CustomRangePicker onChange={handleExpiryDatePicker} />,
    },
  ];

  const onSearchChange = (value) => {
    const searchOptionsKey = _.map(searchOptions, "value");
    const searchOptDisplayText =
      _.first(searchOptions.filter((v) => v.value === value)).key ?? "";
    setFilter((prev) => ({
      ...prev,
      filterObject: filterObject.filter(
        (v) => !searchOptionsKey.includes(v.key)
      ),
      filterTag: filterTag.filter((v) => !searchOptionsKey.includes(v.key)),
      searchObject: value,
      searchObjectDisplayText: searchOptDisplayText,
    }));
  };

  const handleTagDimiss = (key) => {
    if (key === "status") {
      setState((prev) => ({
        ...prev,
        defaultActiveKey: -1,
      }));
    }
    filterForm.setFieldsValue({ [key]: "" });
    setFilter((prev) => ({
      ...prev,
      filterTag: filterTag.filter((v) => v.key !== key),
      filterObject: filterObject.filter((v) => v.key !== key),
    }));
  };

  const captureSearchFieldValue = () => {
    const searchVal = searchRef?.current?.input?.value;
    const searchOptionsKey = _.map(searchOptions, "value");
    setFilter((prev) => ({
      ...prev,
      searchValue: searchVal,
      filterObject: [
        ...filterObject.filter((v) => !searchOptionsKey.includes(v.key)),
        { key: searchObject, value: searchVal },
      ],
      filterTag: [
        ...filterTag.filter((v) => !searchOptionsKey.includes(v.key)),
        {
          key: searchObject,
          value: `${searchObjectDisplayText} : ${searchVal}`,
        },
      ],
    }));
  };

  const getLabelFromFilterOptions = (name) => {
    return filterOptions.find((v) => v.name === name)?.label;
  };

  const handleFilterModalSubmit = () => {
    setState((prev) => ({ ...prev, modalVisible: false }));
    filterForm.submit();
  };

  const handleFilterSubmit = (values) => {
    const fTag = [];
    const fObject = [];
    let rmvKey = [];
    Object.entries(values)
      .filter(([k, v]) => !_.isUndefined(v))
      .map(([k, v]) => {
        let filterDisplayVal = v;
        let filterVal = v;
        if (k === "next_billing_date" || k === "end_date") {
          if (_.isEmpty(v)) {
            rmvKey.push(k);
            return null;
          }
          filterDisplayVal = `${v[0].format("YYYY-MM-DD")} to ${v[1].format(
            "YYYY-MM-DD"
          )}`;
          filterVal = `${v[0].format("YYYY-MM-DD")},${v[1].format(
            "YYYY-MM-DD"
          )}`;
        }
        fTag.push({
          key: k,
          value: `${getLabelFromFilterOptions(k)} : ${filterDisplayVal}`,
        });
        fObject.push({ key: k, value: filterVal });
        return v;
      });
    const fTagKey = _.map(fTag, "key");
    const fObjectKey = _.map(fObject, "key");
    setFilter((prev) => ({
      ...prev,
      filterObject: [
        ...filterObject.filter((v) => !fObjectKey.includes(v.key)),
        ...fObject.filter((v) => !rmvKey.includes(v.key)),
      ],
      filterTag: [
        ...filterTag.filter((v) => !fTagKey.includes(v.key)),
        ...fTag.filter((v) => !rmvKey.includes(v.key)),
      ],
    }));
  };
  const [{ modalVisible, pagination, defaultActiveKey }, setState] = useReducer(
    reducerHelper,
    {
      defaultActiveKey: -1,
      pagination: {
        page: 1,
        limit: API_TABLE_ROWS_PER_PAGE,
      },
      filters: {
        status: -1,
      },
      optionLists: [
        {
          name: "Store Name",
          value: "store_name",
        },
      ],
    }
  );

  const [
    {
      searchValue,
      searchObject,
      searchObjectDisplayText,
      filterObject,
      filterTag,
    },
    setFilter,
  ] = useReducer(reducerHelper, {
    searchValue: "",
    searchObject: initialSearchOptions,
    searchObjectDisplayText: _.first(searchOptions, "value").key ?? "",
    filterTag: [],
    filterObject: [],
  });

  useEffect(() => {
    dispatch(
      getAllSubscription(pagination?.page, pagination?.limit, filterObject)
    );
    if (planPackageDetail.data.length === 0) {
      dispatch(getAllPlanPackage());
    }
  }, [pagination, filterObject]);

  const showPackageModal = (id) => {
    setSelectedSubscriptionId(id);
    setIsPackageModalOpen(true);
  };
  const showInvoiceModal = () => {
    setIsInvoiceModalOpen(true);
  };
  const showPaymentMethodModal = () => {
    setIsPaymentMethodOpen(true);
  };
  const showChangeSubscriptionSelectorModal = () => {
    setIsChangeSubscriptionSelectorOpen(true);
    setIsPackageModalOpen(false);
  };
  const showManualPaymentMethodModal = () => {
    setIsManualPaymentMethodOpen(true);
    setIsChangeSubscriptionSelectorOpen(false);
  };
  const showModifyPaymentMethodModal = () => {
    setIsModifyPaymentMethodOpen(true);
    setIsPaymentMethodOpen(false);
  };
  const showManualPaymentRequestModal = () => {
    setIsManualPaymentRequestOpen(true);
    setIsPaymentMethodOpen(false);
  };

  const handlePackageCancel = () => {
    setIsPackageModalOpen(false);
  };
  const handleInvoiceCancel = () => {
    setIsInvoiceModalOpen(false);
  };
  const handlePaymentMethodCancel = () => {
    setIsPaymentMethodOpen(false);
  };
  const handleChangeSubscriptionSelectorCancel = () => {
    setIsChangeSubscriptionSelectorOpen(false);
    setIsPackageModalOpen(true);
  };
  const handleManualPaymentMethodCancel = () => {
    setIsManualPaymentMethodOpen(false);
    setIsChangeSubscriptionSelectorOpen(true);
  };
  const handleModifyPaymentMethodCancel = () => {
    setIsModifyPaymentMethodOpen(false);
  };
  const handleManualPaymentRequestCancel = () => {
    setIsManualPaymentRequestOpen(false);
  };

  const invoiceColumns = [
    {
      title: "Invoice#",
      dataIndex: "invoiceid",
      key: "invoiceid",
      render: (_, { invoiceid }) => (
        <>
          <a>{invoiceid}</a>
          <br />
          <small style={{ textDecoration: "underline" }}>
            <a style={{ color: "black" }}>View Receipt</a>
          </small>
        </>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoicedate",
      key: "invoicedate",
    },
    {
      title: "Due Date",
      dataIndex: "duedate",
      key: "duedate",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      key: "invoicestatus",
      dataIndex: "invoicestatus",
      render: (_, { invoicestatus }) => (
        <>
          {
            <Tag color={invoicestatus === "Paid" ? "green" : "red"}>
              <Icon
                icon={
                  invoicestatus === "Paid"
                    ? "akar-icons:circle-check"
                    : "ant-design:close-circle-outlined"
                }
              />
              {invoicestatus}
            </Tag>
          }
        </>
      ),
    },
  ];
  const invoicesData = [
    {
      key: "1",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
    {
      key: "2",
      invoiceid: "SM00000111-1243",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Fail",
    },
    {
      key: "3",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
    {
      key: "4",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Fail",
    },
    {
      key: "5",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Fail",
    },
    {
      key: "6",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
    {
      key: "7",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
    {
      key: "8",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
    {
      key: "9",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
    {
      key: "10",
      invoiceid: "SM00000111-1233",
      invoicedate: "30/11/2022",
      duedate: "31/12/2022",
      total: "RM xx.xx",
      invoicestatus: "Paid",
    },
  ];

  const manualPaymentRequestColumns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
    },
    {
      title: "Upload Date",
      dataIndex: "uploaddate",
      key: "uploaddate",
    },
    {
      title: "Total Fee",
      dataIndex: "totalfee",
      key: "totalfee",
    },
    {
      title: "Status",
      key: "manualpaymentrequeststatus",
      dataIndex: "manualpaymentrequeststatus",
      render: (_, { manualpaymentrequeststatus }) => (
        <>
          {
            <Tag
              color={
                manualpaymentrequeststatus === "Approved" ? "green" : "blue"
              }
            >
              <Icon
                icon={
                  manualpaymentrequeststatus === "Approved"
                    ? "akar-icons:circle-check"
                    : "uis:process"
                }
              />
              {manualpaymentrequeststatus}
            </Tag>
          }
        </>
      ),
    },
  ];
  const manualPaymentRequestData = [
    {
      key: "1",
      no: "1",
      subscription: "Starter-Monthly",
      uploaddate: "31/12/2022",
      totalfee: "RM xx.xx",
      manualpaymentrequeststatus: "Approved",
    },
    {
      key: "2",
      no: "2",
      subscription: "Starter-Annually",
      uploaddate: "31/12/2022",
      totalfee: "RM xx.xx",
      manualpaymentrequeststatus: "Approved",
    },
    {
      key: "3",
      no: "3",
      subscription: "Premium-Monthly",
      uploaddate: "31/12/2022",
      totalfee: "RM xx.xx",
      manualpaymentrequeststatus: "Processing",
    },
    {
      key: "4",
      no: "4",
      subscription: "Value-Monthly",
      uploaddate: "31/12/2022",
      totalfee: "RM xx.xx",
      manualpaymentrequeststatus: "Approved",
    },
  ];

  return (
    <YouIDivDesign>
      <YouIMyContent title={Transl("subscription.title")} enableMinHeight>
        <SearchPanel
          initialSearchOptions={initialSearchOptions}
          searchOptions={searchOptions}
          searchValue={searchValue}
          searchObject={searchObject}
          searchObjectDisplayText={searchObjectDisplayText}
          filterTag={filterTag}
          filterObject={filterObject}
          onSearchChange={onSearchChange}
          handleTagDimiss={handleTagDimiss}
          captureSearchFieldValue={captureSearchFieldValue}
          ref={searchRef}
          withFilterModal
          filterOptions={filterOptions}
          openFilterModal={() =>
            setState((prev) => ({
              ...prev,
              modalVisible: true,
            }))
          }
          filterModalClose={() =>
            setState((prev) => ({
              ...prev,
              modalVisible: false,
            }))
          }
          filterModalVisible={modalVisible}
          handleFilterModalSubmit={handleFilterModalSubmit}
          handleFilterSubmit={handleFilterSubmit}
          form={filterForm}
          withDropDownSearch
          {...props}
        />
        <div style={{ marginTop: "10px" }} />
        <TableTab
          defaultActiveKey={defaultActiveKey}
          activeKey={String(defaultActiveKey)}
          onChange={onTabOnChange}
          tabLists={tabLists}
        />
        <Row>
          <Col md={isMobileOnly || isTablet ? 24 : 18}>
            <Row>
              {subscriptionData?.data?.map((data, index) => {
                return (
                  <SubscriptionCard
                    key={"card" + index}
                    showInvoiceModal={showInvoiceModal}
                    showPackageModal={() => showPackageModal(data.id)}
                    showPaymentMethodModal={showPaymentMethodModal}
                    subscriptionData={data ?? []}
                    subscriptionLoading={subscriptionLoading}
                  />
                );
              })}
            </Row>

            <div className={styled.pagination}>
              <CustomPagination
                text={Transl("subscription.pagination.text")}
                onShowSizeChange={onShowSizeChange}
                onChange={onPaginationChange}
                dataSource={subscriptionData}
              />
            </div>
          </Col>

          {isMobileOnly || isTablet ? null : (
            <Col md={6}>
              <div className={styled.advBox}>
                3
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </Col>
          )}
        </Row>

        <PackageModal
          isPackageModalOpen={isPackageModalOpen}
          handlePackageCancel={handlePackageCancel}
          showChangeSubscriptionSelectorModal={
            showChangeSubscriptionSelectorModal
          }
          selectedSubscriptionId={selectedSubscriptionId}
        />

        <CustomModal
          title="Invoice Records"
          open={isInvoiceModalOpen}
          footer={null}
          onCancel={handleInvoiceCancel}
          width={800}
        >
          <SearchPanel />

          {/* <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="All" key="1"></Tabs.TabPane>
            <Tabs.TabPane tab="Paid" key="2"></Tabs.TabPane>
            <Tabs.TabPane tab="Fail" key="3"></Tabs.TabPane>
          </Tabs> */}
          <Table
            columns={invoiceColumns}
            dataSource={invoicesData}
            pagination={{
              pageSize: 3,
            }}
          />
        </CustomModal>

        <CustomModal
          title="Payment Method"
          open={isPaymentMethodOpen}
          onCancel={handlePaymentMethodCancel}
          width={300}
          footer={null}
          centered
        >
          <Button onClick={showModifyPaymentMethodModal}>
            Modify Payment Method
          </Button>
          <br />
          <br />

          <Button onClick={showManualPaymentRequestModal}>
            Manual Payment Request
          </Button>
        </CustomModal>

        <CustomModal
          title="Change Subscription Payment Method"
          open={isChangeSubscriptionSelectorOpen}
          onCancel={handleChangeSubscriptionSelectorCancel}
          width={800}
          footer={[
            <Button onClick={handleChangeSubscriptionSelectorCancel}>
              Back
            </Button>,
            <Button onClick={showManualPaymentMethodModal}>Next</Button>,
          ]}
        >
          <Card
            title={
              <div>
                <span>Please choose the payment method:</span>
              </div>
            }
          >
            <Row gutter={[8, 0]}>
              <Col md={24}>
                <Radio>Manual Payment Method</Radio>
                <Radio>Auto Payment Method</Radio>
              </Col>
            </Row>
          </Card>
        </CustomModal>

        <CustomModal
          title="Modify Payment Method"
          open={isModifyPaymentMethodOpen}
          onCancel={handleModifyPaymentMethodCancel}
          width={800}
          footer={[
            <Button onClick={handleModifyPaymentMethodCancel}>Back</Button>,
            <Button onClick={handleModifyPaymentMethodCancel}>Save</Button>,
          ]}
        >
          <Card
            title={
              <div>
                <span>Please choose the payment method:</span>
              </div>
            }
          >
            <Row gutter={[8, 0]}>
              <Col md={24}>
                <Radio>Manual Payment Method</Radio>
                <Radio>Auto Payment Method</Radio>
              </Col>
            </Row>
          </Card>
        </CustomModal>

        <CustomModal
          title="Manual Payment Method"
          open={isManualPaymentMethodOpen}
          onCancel={handleManualPaymentMethodCancel}
        ></CustomModal>

        <CustomModal
          title="Manual Payment Request"
          open={isManualPaymentRequestOpen}
          onCancel={handleManualPaymentRequestCancel}
          width={800}
          footer={null}
        >
          <Table
            columns={manualPaymentRequestColumns}
            dataSource={manualPaymentRequestData}
            pagination={{
              pageSize: 3,
            }}
          />
        </CustomModal>
      </YouIMyContent>
    </YouIDivDesign>
  );
};

export default SubscriptionMain;
