import React, { useEffect } from "react";
import { Form, Input, notification } from "antd";
import CustomModal from "../../../Components/Custom/Modal/index";

const CreateStore = ({
  createStoreModalVisible,
  handleStoreModalVisible,
  handleCreateStore,
  modalMode,
  selectedStoreDetail,
}) => {
  const [form] = Form.useForm();
  const onFinishFailed = () => {
    notification["error"]({
      message: "Form Validation",
      description: "Please fill-in all the required fields",
    });
  };

  const modalSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createStoreModalVisible) {
      form.resetFields();
      if (modalMode === "edit") {
        form.setFieldsValue({ store_name: selectedStoreDetail?.store_name });
      }
    }
  }, [selectedStoreDetail?.store_name, modalMode, createStoreModalVisible, form]);

  return (
    <CustomModal
      title={`${modalMode === "create" ? "Create" : "Edit"} Store`}
      visible={createStoreModalVisible}
      onOk={modalSubmit}
      onCancel={handleStoreModalVisible}
      centered
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleCreateStore}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Store Name"
          name="store_name"
          rules={[
            {
              required: true,
              message: "Store Name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default CreateStore;
