import React, { useEffect, useReducer, useRef, useState } from "react";
import { Form, Input, Tabs, Card, Slider, Modal, Switch, Button } from "antd";
import FroalaEditor from "react-froala-wysiwyg";
import _ from "lodash";
import ColorPicker from "Components/BuilderConfiguration/ColorPicker";
import FontWeight from "../FontWeight/index";
import TextAlignment from "../TextAlignment/index";
import "./index.scss";
import { InputNumber } from "../../../../../node_modules/antd/lib/index";
import ContainerDimensionEditor from "../../../../Components/BuilderConfiguration/ContainerDimensionEditor/index";
import FontStyle from "../../../../Components/BuilderConfiguration/FontStyle/index";
import FontSize from "../../../../Components/BuilderConfiguration/FontSize/index";
const { TextArea } = Input;

const DynamicConfiguration = ({
  page,
  blockId,
  editorType,
  defaultValue,
  settingMode,
  internalKey,
  modifyKey,
  setChange,
  ...props
}) => {
  const colorPickerRef = useRef();

  const renderEditor = (
    page,
    blockId,
    editorType,
    defaultValue,
    settingMode,
    internalKey,
    modifyKey
  ) => {
    if (String(editorType) === "text") {
      return (
        <Input
          onChange={(evt) => {
            setChange(
              page,
              blockId,
              settingMode,
              internalKey,
              modifyKey,
              evt.target.value
            );
          }}
          value={defaultValue}
        />
      );
    } else if (String(editorType) === "colorPicker") {
      return (
        <ColorPicker
          ref={colorPickerRef}
          color={defaultValue ?? 'transparent'}
          onChange={(cl) =>
            setChange(
              page,
              blockId,
              settingMode,
              internalKey,
              modifyKey,
              `rgba(${cl.r},${cl.g},${cl.b},${cl.a})`
            )
          }
        />
      );
    } else if (String(editorType) === "textarea") {
      return (
        <TextArea
          rows={4}
          onChange={(evt) =>
            setChange(
              page,
              blockId,
              settingMode,
              internalKey,
              modifyKey,
              evt.target.value
            )
          }
          value={defaultValue}
        />
      );
    } else if (String(editorType) === "switch") {
      return (
        <Switch
          onChange={(checked) =>
            setChange(
              page,
              blockId,
              settingMode,
              internalKey,
              modifyKey,
              checked ? true : false
            )
          }
          defaultChecked={defaultValue ? true : false}
        />
      );
      // } else if (String(v.type) === "slider") {
      //   return (
      //     <Slider
      //       onChange={(val) => setChange(k, val, routePath)}
      //       defaultValue={v.value}
      //       step={2}
      //       marks={{
      //         2: "2px",
      //         4: "4px",
      //         6: "6px",
      //         8: "8px",
      //         10: "10px",
      //       }}
      //       min={2}
      //       max={10}
      //     />
      //   );
    } else if (String(editorType) == "editor") {
      return (
        <FroalaEditor
          model={defaultValue}
          attributes={false}
          onModelChange={(val) =>
            setChange(page, blockId, settingMode, internalKey, modifyKey, val)
          }
        />
      );
    } else if (String(editorType) == "fontWeightEditor"){
      return (<FontWeight defaultValue={defaultValue} setChange={(val) => {
        setChange(page, blockId, settingMode, internalKey, modifyKey, val)
      } }/>);
    } else if (String(editorType) == "textAlignmentEditor"){
      return (<TextAlignment defaultValue={defaultValue} handleClick={(val) => {
        setChange(page, blockId, settingMode, internalKey, modifyKey, val)
      }}/>);
    } else if (String(editorType) == "numericInput"){
      return (<InputNumber defaultValue={defaultValue} max={props?.settings?.config?.max} min={props?.settings?.config?.min} onChange={(val) => {
        setChange(page, blockId, settingMode, internalKey, modifyKey, val)
      }}/>);
    } else if (String(editorType) == "containerDimensionEditor") {
      return (
        <ContainerDimensionEditor defaultValue={defaultValue} onChange={(val) => {
          setChange(page, blockId, settingMode, internalKey, modifyKey, val)
        } }/>
      );
    } else if (String(editorType) == "fontStyle") {
      return (
        <FontStyle defaultValue={defaultValue} onChange={(val) => {
          setChange(page, blockId, settingMode, internalKey, modifyKey, val)
        } }/>
      );
    } else if (String(editorType) == "fontSize"){
      return (
      <FontSize defaultValue={defaultValue} onChange={
        (val) => {
          setChange(page, blockId, settingMode, internalKey, modifyKey, val)
          } 
        } />
      );
    }
    else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      {renderEditor(
        page,
        blockId,
        editorType,
        defaultValue,
        settingMode,
        internalKey,
        modifyKey
      )}
    </React.Fragment>
  );
};

export default DynamicConfiguration;
