export const GET_STORE = "GET_STORE";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAILED = "GET_STORE_FAILED";
export const GET_SELECTED_STORE = "GET_SELECTED_STORE";
export const GET_SELECTED_STORE_SUCCESS = "GET_SELECTED_STORE_SUCCESS";
export const GET_SELECTED_STORE_FAILED = "GET_SELECTED_STORE_FAILED";

//Page management
export const GET_PAGE_DETAIL = "GET_PAGE_DETAIL";
export const GET_PAGE_DETAIL_SUCCESS = "GET_PAGE_DETAIL_SUCCESS";
export const GET_PAGE_DETAIL_FAILED = "GET_PAGE_DETAIL_FAILED";
export const GET_PAGE = "GET_PAGE";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";
export const GET_PAGE_FAILED = "GET_PAGE_FAILED";
export const UPDATE_PAGE_STATUS = "UPDATE_PAGE_STATUS";
export const UPDATE_PAGE_STATUS_SUCCESS = "UPDATE_PAGE_STATUS_SUCCESS";
export const UPDATE_PAGE_STATUS_FAILED = "UPDATE_PAGE_STATUS_FAILED";

// Web Setting
export const HIDE_NAVBAR = "HIDE_NAVBAR";
export const SHOW_NAVBAR = "SHOW_NAVBAR";
export const ENABLE_MOBILE_VIEW = "ENABLE_MOBILE_VIEW";
export const DISABLE_MOBILE_VIEW = "DISABLE_MOBILE_VIEW";
export const ENABLE_FOOTER = "ENABLE_FOOTER";
export const DISABLE_FOOTER = "DISABLE_FOOTER";

// User Preference
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";

//Domain Management
export const GET_WEBSTORE_DOMAIN = "GET_WEBSTORE_DOMAIN";
export const GET_WEBSTORE_DOMAIN_SUCCESS = "GET_WEBSTORE_DOMAIN_SUCCESS";
export const GET_WEBSTORE_DOMAIN_FAILED = "GET_WEBSTORE_DOMAIN_FAILED";

export const UPDATE_WEBSTORE_DOMAIN_SETTING = "UPDATE_WEBSTORE_DOMAIN_STATUS";
export const UPDATE_WEBSTORE_DOMAIN_SETTING_SUCCESS =
  "UPDATE_WEBSTORE_DOMAIN_SETTING_SUCCESS";
export const UPDATE_WEBSTORE_DOMAIN_SETTING_FAILED =
  "UPDATE_WEBSTORE_DOMAIN_SETTING_FAILED";

//Auth Core
export const SELECT_SELECTED_STORE = "SELECT_SELECTED_STORE";
export const SET_SELECTED_STORE = "SET_SELECTED_STORE";

// Get Block Manager
export const GET_BLOCK_MANAGER = "GET_BLOCK_MANAGER";
export const GET_BLOCK_MANAGER_SUCCESS = "GET_BLOCK_MANAGER_SUCCESS";
export const GET_BLOCK_MANAGER_FAILED = "GET_BLOCK_MANAGER_FAILED";

// Subscription
export const GET_ALL_SUBSCRIPTION_REQUEST = "GET_ALL_SUBSCRIPTION_REQUEST";
export const GET_ALL_SUBSCRIPTION_SUCCESS = "GET_ALL_SUBSCRIPTION_SUCCESS";
export const GET_ALL_SUBSCRIPTION_FAIL = "GET_ALL_SUBSCRIPTION_FAIL";
export const GET_SELECTED_SUBSCRIPTION_REQUEST =
  "GET_SELECTED_SUBSCRIPTION_REQUEST";
export const GET_SELECTED_SUBSCRIPTION_SUCCESS =
  "GET_SELECTED_SUBSCRIPTION_SUCCESS";
export const GET_SELECTED_SUBSCRIPTION_FAIL = "GET_SELECTED_SUBSCRIPTION_FAIL";

//Package
export const GET_ALL_PLAN_PACKAGE_REQUEST = "GET_ALL_PLAN_PACKAGE_REQUEST";
export const GET_ALL_PLAN_PACKAGE_SUCCESS = "GET_ALL_PLAN_PACKAGE_SUCCESS";
export const GET_ALL_PLAN_PACKAGE_FAIL = "GET_ALL_PLAN_PACKAGE_FAIL";

export const GET_WEBSTORE_BUILDER_PAGE_SETTING =
  "GET_WEBSTORE_BUILDER_PAGE_SETTING";
export const GET_WEBSTORE_BUILDER_PAGE_SETTING_SUCCESS =
  "GET_WEBSTORE_BUILDER_PAGE_SETTING_SUCCESS";
export const GET_WEBSTORE_BUILDER_PAGE_SETTING_FAILED =
  "GET_WEBSTORE_BUILDER_PAGE_SETTING_FAILED";

// Image Management
export const FETCH_IMAGE_REQUEST = "FETCH_IMAGE_REQUEST";
export const FETCH_IMAGE_SUCCESS = "SUCCESS_FETCH_IMAGE";
export const FETCH_IMAGE_FAILURE = "FAIL_FETCH_IMAGE";

// Builder Redux
export const OPEN_SINGLE_SELECT_IMAGE_MODAL = "OPEN_SINGLE_SELECT_IMAGE_MODAL";
export const OPEN_MULTIPLE_SELECT_IMAGE_MODAL =
  "OPEN_MULTIPLE_SELECT_IMAGE_MODAL";
export const INSERT_IMAGE_MODAL_DATA = "INSERT_IMAGE_MODAL_DATA";
export const CLOSE_IMAGE_MODAL = "CLOSE_IMAGE_MODAL";
export const OPEN_ICON_BOX = "OPEN_ICON_BOX";
