import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";

import { Breadcrumb } from "antd";
import BreadcrumbComponent from "../../../Components/Breadcrumb/BreadcrumbComponent";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../Constants";

import ImageManagerComponent from "../../../Components/ImageManager/ImageManagerComponent";
import YouIFullWidthContent from "../../../Components/DivDesign/YouIFullWidthContent";
import { Icon } from "@iconify/react";
import storeIcon from "@iconify/icons-dashicons/store";
import windowIcon from "@iconify/icons-bi/window";
import { setSelectedStore } from "../../../Actions";

// Helpers
import { Transl } from "../../../Helpers";
import ImageManagerModal from "../../../Components/Modal/ImageManagerModal";

const ImageManagement = ({ ...props }) => {
  const dispatch = useDispatch();
  const { selectedStore, builderState } = useSelector(
    ({ coreApp, builderState }) => {
      return {
        selectedStore: coreApp.selectedStore,
        builderState: builderState,
      };
    }
  );

  const [isModal] = useState(false);

  useEffect(() => {
    dispatch(setSelectedStore(props?.match?.params?.storeId));
  }, []);

  const ImageManagementBreadcrumb = () => {
    return (
      <BreadcrumbComponent>
        <Breadcrumb.Item>
          <Icon icon={storeIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(AppRoutes.STOREDETAIL, ":storeId", selectedStore?.id)}
          >
            {selectedStore?.store_name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Icon icon={windowIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(AppRoutes.STOREIMAGE, ":storeId", selectedStore?.id)}
          >
            Image Management
          </Link>
        </Breadcrumb.Item>
      </BreadcrumbComponent>
    );
  };

  return (
    <>
      <YouIDivDesign>
        <YouIFullWidthContent
          breadcrumbComp={<ImageManagementBreadcrumb />}
          withTitle
          title={Transl("image.manager")}
        >
          <ImageManagerComponent isModal={isModal} />
        </YouIFullWidthContent>
      </YouIDivDesign>
      <ImageManagerModal
        imageManagerModalShow={builderState.imageManager.modalOpen}
        multipleSelect={builderState.imageManager.multipleSelect}
        disableSetChange
      />
    </>
  );
};

export default ImageManagement;
