import React from "react";
import { Tabs } from "antd";
import ConfigurationContainer from "../ConfigurationContainer/index";

const ConfigurationModal = ({ setChange, selectedBlock, ...props }) => {
  const itemLists = Object.entries(selectedBlock?.settings?.settings ?? []).map(
    ([k, v], i) => {
      if (_.isEmpty(v)) {
        return null;
      }
      return {
        label: _.capitalize(k),
        key: i,
        children: (
          <ConfigurationContainer
            setChange={setChange}
            selectedBlock={selectedBlock}
            mainGroup={k}
            iConfiguration={v}
            {...props}
          />
        ),
      };
    }
  );
  return (
    <Tabs
      defaultActiveKey={-1}
      onChange={() => {}}
      items={itemLists}
    />
  );
};

export default ConfigurationModal;
