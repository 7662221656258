import React, { useEffect } from "react";
import { Modal, Image, Tabs, Card, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getBlockManager } from "../../../../Actions";
import "./index.scss";
//import { isMobile } from "react-device-detect";
import { generateInitialListingBasedOnTemplate, randomNumber } from "../../../../Helpers/Helpers";
import uuid from 'react-uuid';
import _ from "lodash";
import CustomModal from "../../../../Components/Custom/Modal/index";
const { TabPane } = Tabs;
const { Meta } = Card;

const BlockManager = ({ modalVisible = false, handleModalVisible, selectedBlock, addBlock }) => {
  const dispatch = useDispatch();
  const { layouts, loading } = useSelector(({ blockManager }) => {
    return {
      layouts: blockManager?.layouts,
      loading: blockManager?.loading,
    };
  });

  useEffect(() => {
    if (modalVisible) {
      dispatch(getBlockManager());
    }
  }, [modalVisible]);

  const removeUnwantedDataKey = (blockData) => {
    const allowedKeys = [
        "blockGroup",
        "blockType",
        "position",
        "width",
        "settings"
    ];
    return Object.entries(blockData).reduce((carry,[k,v]) => {
        if (allowedKeys.includes(k)){
            carry[k] = v;
        }
        return carry;
    },{});
  }

  const checkIsEditorTypeIsListing = (dat) => {
    return Object.entries(dat).reduce((settingGroupCarry,[settingGroupKey,settingGroupValue]) => {
        let updatedSettingVal = Object.entries(settingGroupValue).reduce((carry,[k,v]) => {
            if (v.editorType == "dataListing" && _.isEmpty(v.data)){
                v.data = [generateInitialListingBasedOnTemplate(v.listingTemplate)];
            }
            carry[k] = v;
            return carry;
        },{});
        settingGroupCarry[settingGroupKey] = updatedSettingVal;
        return settingGroupCarry;
    },{});
  }

  const handleAddThisBlock = (evt, blockComponent) => {
    let selectedBlockComponent = removeUnwantedDataKey(blockComponent);
    selectedBlockComponent.blockId = uuid();
    selectedBlockComponent.sectionId = !_.isUndefined(selectedBlock?.sectionId) ? selectedBlock?.sectionId :uuid();
    
    selectedBlockComponent.settings = checkIsEditorTypeIsListing(blockComponent.settings);
    addBlock(selectedBlockComponent);
    handleModalVisible(false);
  };

  return (
    <>
      <CustomModal
        title="Block Manager"
        centered
        visible={modalVisible}
        onOk={() => handleModalVisible(false)}
        onCancel={() => handleModalVisible(false)}
        width={1000}
        className="block-manager-modal"
        footer={null}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Tabs tabPosition="left">
            {(layouts ?? []).map((v, k) => {
             
              return (
                <TabPane
                tab={
                  <>
                    <Image
                      width={200}
                      src={v?.blockPreviewImage}
                      preview={false}
                      className="block-category-image"
                    />
                    {v?.blockTitle && <Meta title={v?.blockTitle} />}
                  </>
                }
                key={k}
              >
                <div className="row">
                  {(v.layouts ?? []).map((blocks) => {
                   return ( <div
                    className="col-6 text-center"
                    style={{ cursor: "pointer" }}
                  >
                    <Card
                      onClick={(e) =>
                        handleAddThisBlock(e, blocks)
                      }
                    >
                      <Image
                        width={200}
                        src={blocks?.blockPreviewImage}
                        preview={false}
                        className="block-image"
                      />
                      {blocks?.blockTitle && <Meta title={blocks?.blockTitle} />}
                    </Card>
                  </div>);
                  })}
                </div>
              </TabPane>
              );
            })}
          </Tabs>
        )}
      </CustomModal>
    </>
  );
};

export default BlockManager;