import { Card, Col, Row, Skeleton, Badge } from "antd";
import React from "react";
import { AppRoutes } from "../../../Constants";
import "./StoreTable.scss";
import _ from "lodash";
import moment from "moment";
import { Icon } from "@iconify/react";
import editIcon from "@iconify/icons-akar-icons/edit";
import dateSolid from "@iconify/icons-clarity/date-solid";
import userRectangle from "@iconify/icons-bxs/user-rectangle";
import emptyDataAsset from "../../../Assets/Images/Global/undraw_no_data_re_kwbl.svg";
import EmptyComponent from "Components/Empty/EmptyComponent";
import { setSelectedStore } from "../../../Actions";
import { useDispatch } from "react-redux";
import { t } from "i18next";

const StoreTable = ({ history, loading, data, editStore }) => {
  const dispatch = useDispatch();

  const emptyComponentData = {
    image: emptyDataAsset,
    imageStyle: { height: "100%" },
    description: <span style={{ fontSize: "1rem" }}>No Data Found.</span>,
  };

  const handleOpenStore = (id) => {
    dispatch(setSelectedStore(id));
    history.push(_.replace(AppRoutes.STOREDETAIL, ":storeId", id));
  };

  return (
    <div className="site-card-wrapper">
      {_.isEmpty(data) && (
        <EmptyComponent
          image={emptyComponentData.image}
          imageStyle={emptyComponentData.imageStyle}
          description={emptyComponentData.description}
        />
      )}
      {_.chunk(data, 4).map((stores) => {
        return (
          <Row>
            {stores.map(
              ({
                id,
                store_name,
                store_image,
                primary_domain,
                created_at,
                author,
                status,
              }) => {
                return (
                  <Col
                    className="store-card-wrapper"
                    lg={6}
                    xl={6}
                    xs={24}
                    sm={24}
                    md={24}
                  >
                    <Badge.Ribbon
                      text={t(`common.${_.lowerCase(status)}`)}
                      color={status === "Active" ? "blue" : "red"}
                    >
                      <Card
                        loading={loading}
                        onClick={() => handleOpenStore(id)}
                        className="store-card"
                        cover={
                          <Skeleton loading={loading} avatar active>
                            <img alt={`Store Cover-${id}`} src={store_image} />
                          </Skeleton>
                        }
                        bordered={true}
                        actions={[
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              editStore(id);
                            }}
                          >
                            <Icon icon={editIcon} />
                            &nbsp;{t("common.edit")}
                          </span>,
                        ]}
                      >
                        <h3>{store_name}</h3>
                        <small style={{ fontSize: "18px" }}>
                          {primary_domain.domain}
                        </small>
                        <p>
                          <Icon icon={userRectangle} /> {author?.first_name} |{" "}
                          <Icon icon={dateSolid} />{" "}
                          {moment(created_at).format("MMMM Do YYYY, h:mm:ss a")}
                        </p>
                      </Card>
                    </Badge.Ribbon>
                  </Col>
                );
              }
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default StoreTable;
