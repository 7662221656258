import React from "react";
import { Icon } from "@iconify/react";
import boldOutlined from "@iconify/icons-ant-design/bold-outlined";
import underlineOutlined from "@iconify/icons-ant-design/underline-outlined";
import italicOutlined from "@iconify/icons-ant-design/italic-outlined";
import _ from "lodash";

const FontWeight = ({
    defaultValue,
    setChange,
    ...props
}) => {

    const handleChange = (selectedValue) => {
        let foundDefaultValue = defaultValue.find((v) => v == selectedValue);
        let dat = [];
        if (!_.isEmpty(foundDefaultValue)){
            dat = defaultValue.filter((v) => v !== selectedValue);
        }
        else{
            dat = defaultValue;
            dat.push(selectedValue);
        }
        setChange(dat);
    }
  return (
    <div className="row">
      <div className="col-lg-4" style={{fontSize:"15px"}}>
        <div
          className="btn-group builder-custom-editor-container builder-view-control-group"
          role="group"
          aria-label="Basic example"
          style={{ justifyContent: "center" }}
        >
          <span onClick={() => handleChange('text-weight-bold')} className={`custom-span-button view-controls ${defaultValue.includes("text-weight-bold") && "active"}`}>
            <Icon icon={boldOutlined} />
          </span>
          <span onClick={() => handleChange('text-weight-underline')} className={`custom-span-button view-controls ${defaultValue.includes("text-weight-underline") && "active"}`}>
            <Icon icon={underlineOutlined} />
          </span>
          <span onClick={() => handleChange('text-weight-italics')} className={`custom-span-button view-controls ${defaultValue.includes("text-weight-italics") && "active"}`}>
            <Icon icon={italicOutlined} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FontWeight;
