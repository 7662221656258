import React, { useEffect, useReducer } from "react";
import { InputNumber, Select } from "antd";
import { reducerHelper } from "../../../Helpers/Helpers";

const FontSize = ({ onChange, defaultValue, ...props }) => {
  const unitOptions = ["px", "pt", "cm", "mm"];

  const [{ unitDimension, numericDimension }, setState] = useReducer(
    reducerHelper,
    {
      unitDimension: defaultValue.replace(/[0-9]/g, ""),
      numericDimension: parseInt(defaultValue),
    }
  );

  useEffect(() => {
    onChange(`${numericDimension}${unitDimension}`);
  }, [unitDimension, numericDimension]);

  const changeUnit = (val) => {
    setState((prev) => ({ ...prev, unitDimension: val }));
  };

  const changeVal = (val) => {
    setState((prev) => ({ ...prev, numericDimension: val }));
  };
  return (
    <React.Fragment>
      <InputNumber
        min={0}
        max={100}
        defaultValue={numericDimension}
        onChange={changeVal}
      />
      <Select onChange={changeUnit} defaultValue={unitDimension}>
        {unitOptions.map((v) => {
          return <Select.Option value={v}>{v}</Select.Option>;
        })}
      </Select>
    </React.Fragment>
  );
};

export default FontSize;
