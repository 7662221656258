// Packages
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
// Design Frameworks
import { Row, Col } from "react-bootstrap";
import { Form, Button } from "antd";
// Actions
import {
    NextStepAction,
    PreviousStepAction,
    RegisterAccount,
    ValidateEmail,
} from "../../../Actions";
// Components
import {
    PersonalInfoFormComponent,
    CreateAccountFormComponent,
    AddressFormComponent,
} from "../../../Components/Form";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import RegisterEmailConfirmation from "./RegisterEmailConfirmation";
import YouIHalfWidthContent from "../../../Components/DivDesign/YouIHalfWidthContent";
// Helpers
import { Transl } from "../../../Helpers";

export const RegisterMain = () => {
    const [getPage, setPage] = useState();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { register, step } = useSelector((state) => state);

    useEffect(() => {
        const page = [
            {
                id: 1,
                page: <CreateAccountFormComponent />,
            },
            {
                id: 2,
                page: <PersonalInfoFormComponent />,
            },
            {
                id: 3,
                page: <AddressFormComponent />,
            },
            {
                id: 4,
                page: <RegisterEmailConfirmation />,
            },
        ];

        page.forEach((elem) => {
            step?.step === elem.id && setPage(elem.page);
        });
    }, [step?.step, dispatch, register?.email]);

    const previousPage = () => {
        dispatch(PreviousStepAction());
    };

    const handleSubmit = (values) => {
        switch (step?.step) {
            case 2:
                dispatch(NextStepAction());
                dispatch({
                    type: "INSERT_REGISTER_PAGE",
                    email: register?.email,
                    password: register?.password,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    contact: values.contact,
                });
                break;
            case 3:
                dispatch(
                    RegisterAccount({
                        email: register?.email,
                        password: register?.password,
                        first_name: register?.first_name,
                        last_name: register?.last_name,
                        contact: register?.contact,
                        address1: values.address1,
                        address2: values.address2,
                        city: values.city,
                        country: values.CountryLocation,
                        state: values.StateLocation,
                    })
                );
                break;
            default:
                dispatch(
                    ValidateEmail(
                        {
                            email: values.email,
                            password: values.password,
                        },
                        form
                    )
                );
        }
    };

    return (
        <>
            <YouIDivDesign>
                <YouIHalfWidthContent title={Transl("sign_up")}>
                    <Form
                        className="auth-form"
                        onFinish={handleSubmit}
                        form={form}
                    >
                        {getPage}
                        <br />
                        <Row>
                            {step?.step === 2 || step?.step === 3 ? (
                                <>
                                    <Col md={4} lg={3}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                className="w-100"
                                                onClick={() => previousPage()}
                                            >
                                                <Icon icon="akar-icons:chevron-left" />
                                                &nbsp;Back
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} lg={6} />
                                </>
                            ) : (
                                <>
                                    <Col md={8} lg={9} />
                                </>
                            )}

                            <Col md={4} lg={3}>
                                {(step?.step === 1 || step?.step === 2) && (
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="w-100"
                                        >
                                            Next{"  "}
                                            <Icon icon="akar-icons:chevron-right" />
                                        </Button>
                                    </Form.Item>
                                )}
                                {step?.step === 3 && (
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            className="w-100"
                                        >
                                            Sign Up
                                        </Button>
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </YouIHalfWidthContent>
            </YouIDivDesign>
        </>
    );
};
