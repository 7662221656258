import { 
    GET_BLOCK_MANAGER,
    GET_BLOCK_MANAGER_SUCCESS,
    GET_BLOCK_MANAGER_FAILED,
} from "../Constants/ActionType";
import BuilderApi from "../Inceptors/BuilderApi";

export const getBlockManager = () => (dispatch) => {
    dispatch({type:GET_BLOCK_MANAGER});
    BuilderApi.get("/api/v1/blockManager").then((response) => {
        dispatch({type:GET_BLOCK_MANAGER_SUCCESS,layouts:response?.data?.layouts});
    }).catch((err) => {
        dispatch({type:GET_BLOCK_MANAGER_FAILED});
    })
}