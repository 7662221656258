import React, { useEffect, useState } from "react";
import { Card, Image, Pagination, Button, Spin } from "antd";
import UploadImageModal from "../../Components/Modal/UploadImageModal";
import DeleteDataModal from "../../Components/Modal/DeleteDataModal";
import { Transl } from "../../Helpers";
// import { DeleteImage, GetImage } from "../../Actions/ResourceAction";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { BLOB_LINK } from "../../Constants";
import EmptyComponent from "../Empty/EmptyComponent";
import Styles from "./ImageManagerComponent.module.scss";
import {
  requestImage,
  getImage,
  deleteImage,
} from "../../Actions/StoreImageAction";
import {
  openMultipleSelectImageModal,
  openSingleSelectImageModal,
} from "../../Actions/BuilderAction";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const ImageManagerComponent = ({ isModal, ...props }) => {
  const dispatch = useDispatch();
  const { image } = useSelector((state) => state);

  // const [showContextMenu, setShowContextMenu] = useState(false);
  const [uploadImageModalShow, setUploadImageModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState({
    modalOpen: false,
    data: null,
  });
  const [imageAmount, setImageAmount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize] = useState(10);
  const [imageSelected, setImageSelected] = useState("");

  useEffect(() => {
    if (imageAmount === undefined) {
      dispatch(requestImage());
      dispatch(getImage());
    }
  }, [imageAmount, dispatch]);

  useEffect(() => {
    setImageAmount(image?.images.length);
  }, [image, setImageAmount]);

  useEffect(() => {
    if (
      !props.multipleSelect &&
      props.selectedImage &&
      props.selectedImage.length !== 0
    ) {
      setImageSelected(props.selectedImage[0]);
    }
  }, [props.selectedImage]);

  const showUploadImageModal = () => {
    setUploadImageModalShow(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalShow({
      modalOpen: false,
      data: null,
    });
  };

  const handleDelete = (value) => {
    dispatch(deleteImage({ filename: value }));
    handleDeleteModalClose();
  };

  const handlePage = (page) => {
    setCurrentPage(page);
  };

  const handleSelect = (link) => {
    if (!isModal) return;

    const tempArray = [...props.selectedImage];
    if (isModal && !tempArray.includes(link)) {
      if (!props.multipleSelect && tempArray.length === 0) {
        tempArray.push(link);
      } else if (!props.multipleSelect && tempArray.length > 0) {
        tempArray.splice(0, 1, link);
      } else if (props.multipleSelect) {
        tempArray.push(link);
      }
      props.setSelectedImage(tempArray);
    }
  };

  // const handleContextMenu = (event) => {
  //     event.preventDefault();
  //     console.log(event.target);
  //     if (showContextMenu) {
  //         setShowContextMenu(false);
  //     } else {
  //         setShowContextMenu(true);
  //     }
  // };

  return (
    <>
      <div className={Styles.imageManagerContentHeaderGroup}>
        {!isModal && (
          <div className={Styles.leftButtonContainer}>
            <Button
              type="primary"
              onClick={() => dispatch(openMultipleSelectImageModal())}
            >
              Modal
            </Button>
          </div>
        )}
        <div className={Styles.rightButtonContainer}>
          <Button type="primary" onClick={showUploadImageModal}>
            {Transl("upload")}
          </Button>
        </div>
      </div>
      <div className={Styles.contentContainer}>
        {image?.loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            {imageAmount === 0 ? (
              <EmptyComponent />
            ) : (
              <Image.PreviewGroup>
                <div
                  className={Styles.imageComponentContainer}
                  style={{ height: `${isModal && 60}vh` }}
                >
                  {image?.images
                    .slice(
                      pageDataSize * (currentPage - 1),
                      pageDataSize * (currentPage - 1) + pageDataSize
                    )
                    .map((data) => {
                      const FULL_LINK = `${BLOB_LINK}${data}`;

                      return (
                        <Card
                          hoverable
                          style={{
                            textAlign: "center",
                          }}
                          cover={
                            <Image
                              preview={isModal ? false : true}
                              src={FULL_LINK}
                            />
                          }
                          className={`${
                            isModal
                              ? Styles.modalResourceItem
                              : Styles.resourceItem
                          } ${
                            !props.multipleSelect &&
                            imageSelected === FULL_LINK &&
                            Styles.active
                          }`}
                          key={FULL_LINK}
                          onClick={() => handleSelect(FULL_LINK)}
                        >
                          {!isModal && (
                            <Button
                              type="primary"
                              onClick={() =>
                                setDeleteModalShow({
                                  modalOpen: true,
                                  data: data,
                                })
                              }
                              danger
                            >
                              {Transl("delete")}
                            </Button>
                          )}
                        </Card>
                      );
                    })}
                  {/* {!isModal && showContextMenu && (
                      <div className="resource-item-context-menu">
                          {Transl("delete")}
                      </div>
                  )} */}
                </div>
              </Image.PreviewGroup>
            )}
          </>
        )}
      </div>

      {imageAmount !== 0 && imageAmount > pageDataSize && (
        <div className={Styles.paginationContainer}>
          <Pagination
            defaultCurrent={1}
            total={imageAmount}
            defaultPageSize={pageDataSize}
            onChange={handlePage}
          />
        </div>
      )}
      <UploadImageModal
        uploadImageModalShow={uploadImageModalShow}
        setUploadImageModalShow={setUploadImageModalShow}
      />
      <DeleteDataModal
        modalShow={deleteModalShow}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
      />
    </>
  );
};

export default ImageManagerComponent;
