import { 
    GET_BLOCK_MANAGER,
    GET_BLOCK_MANAGER_SUCCESS,
    GET_BLOCK_MANAGER_FAILED,
} from "../Constants/ActionType";

const INIT_STATE = {
    loading: true,
    layouts:[]
};

export const BlockManagerReducer = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_BLOCK_MANAGER:
            return {...state, loading: true, layouts: []};
        case GET_BLOCK_MANAGER_SUCCESS:
            return {...state, loading: false, layouts: action.layouts};
        case GET_BLOCK_MANAGER_FAILED:
            return {...state, loading: false, layouts: []};
        default:
            return {...state};
    }
} 