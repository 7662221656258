import {
  GET_WEBSTORE_DOMAIN,
  GET_WEBSTORE_DOMAIN_SUCCESS,
  GET_WEBSTORE_DOMAIN_FAILED,
  UPDATE_WEBSTORE_DOMAIN_SETTING,
  UPDATE_WEBSTORE_DOMAIN_SETTING_SUCCESS,
  UPDATE_WEBSTORE_DOMAIN_SETTING_FAILED,
} from "../Constants/ActionType";

const INIT_STATE = {
  loading: true,
  data: {},
  selectedDomain: {},
  statusList: [],
};

const updateWebstoreDomainSetting = (state, action, loading = true) => {
  let d = state.data;
  d.data = (d.data ?? []).map((v) => {
    if (action.id === v.id) {
      v.loading = loading;
    }
    return v;
  });
  return { ...state, data: d };
};

export const WebstoreDomainReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WEBSTORE_DOMAIN:
      return { ...state, loading: true };
    case GET_WEBSTORE_DOMAIN_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_WEBSTORE_DOMAIN_FAILED:
      return { ...state, loading: false, data: {} };
    case UPDATE_WEBSTORE_DOMAIN_SETTING:
      return updateWebstoreDomainSetting(state, action, true);
    case UPDATE_WEBSTORE_DOMAIN_SETTING_SUCCESS:
    case UPDATE_WEBSTORE_DOMAIN_SETTING_FAILED:
      return updateWebstoreDomainSetting(state, action, false);
    default:
      return { ...state };
  }
};
