import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Transl } from "../../Helpers/LanguageHelper";
import {
  GENERAL_NAV_MAP,
  USER_AUTH_REQUIRE_NAV_MAP,
} from "../../Constants/NavMap";
import { Drawer, Button } from "antd";
import Styles from "./OffCanvasNav.module.scss";

const OffCanvasNav = ({
  user,
  auth,
  offCanvasShow,
  handleOffCanvasClose,
  handleOffCanvasShow,
  handleLogOutShow,
}) => {
  const [offCanvasWidth] = useState(280);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        title={
          <>
            {user === null ? (
              <>
                {GENERAL_NAV_MAP.map((element) => (
                  <React.Fragment key={element.navName}>
                    {element.offCanvas === "header" && (
                      <Link
                        to={element.navRoute}
                        className="link"
                        onClick={handleOffCanvasClose}
                      >
                        <div className={Styles.offCanvasBodyContent}>
                          <div className={Styles.offCanvasBodyTextContainer}>
                            {element.icon !== null && (
                              <div className={Styles.offCanvasIcon}>
                                {element.icon}
                              </div>
                            )}
                            {Transl(element.navName)}
                          </div>
                        </div>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                <div className={Styles.offCanvasBodyContent}>
                  <div className={Styles.offCanvasBodyTextContainer}>
                    {auth?.account?.user?.first_name?.toUpperCase()}
                  </div>
                </div>
                {USER_AUTH_REQUIRE_NAV_MAP.map((element) => (
                  <React.Fragment key={element.navName}>
                    {element.offCanvas === "header" && (
                      <Link
                        to={element.navRoute}
                        className="link"
                        onClick={handleOffCanvasClose}
                      >
                        <div className={Styles.offCanvasBodyContent}>
                          <div className={Styles.offCanvasBodyTextContainer}>
                            {element.icon !== null && (
                              <div className={Styles.offCanvasIcon}>
                                {element.icon}
                              </div>
                            )}
                            {Transl(element.navName)}
                          </div>
                        </div>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        }
        placement="left"
        onClose={handleOffCanvasClose}
        closable={false}
        open={offCanvasShow}
        width={offCanvasWidth}
        extra={
          user !== null && (
            <Button
              onClick={() => {
                showDrawer();
                handleOffCanvasClose();
              }}
            >
              Account&ensp;
              <Icon icon="akar-icons:chevron-right" />
            </Button>
          )
        }
      >
        {user === null ? (
          <>
            {GENERAL_NAV_MAP.map((element) => (
              <React.Fragment key={element.navName}>
                {element.offCanvas === "body" && (
                  <Link
                    to={element.navRoute}
                    className="link"
                    onClick={handleOffCanvasClose}
                  >
                    <div className={Styles.offCanvasBodyContent}>
                      <div className={Styles.offCanvasBodyTextContainer}>
                        {element.icon !== null && (
                          <div className={Styles.offCanvasIcon}>
                            {element.icon}
                          </div>
                        )}
                        {Transl(element.navName)}
                      </div>
                    </div>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {USER_AUTH_REQUIRE_NAV_MAP.map((element) => (
              <React.Fragment key={element.navName}>
                {element.offCanvas === "primary-body" && (
                  <Link
                    to={element.navRoute}
                    className="link"
                    onClick={handleOffCanvasClose}
                  >
                    <div className={Styles.offCanvasBodyContent}>
                      <div className={Styles.offCanvasBodyTextContainer}>
                        {element.icon !== null && (
                          <div className={Styles.offCanvasIcon}>
                            {element.icon}
                          </div>
                        )}
                        {Transl(element.navName)}
                      </div>
                      <div className={Styles.offCanvasRightArrowContainer}>
                        <Icon icon="akar-icons:chevron-right" />
                      </div>
                    </div>
                  </Link>
                )}
              </React.Fragment>
            ))}
            <hr />
            <div className={Styles.offCanvasBodyContent}>
              <div className={Styles.offCanvasBodyTextContainer}>
                <div onClick={handleLogOutShow}>{Transl("sign_out")}</div>
              </div>
            </div>
          </>
        )}
      </Drawer>
      <Drawer
        title="Account"
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        width={offCanvasWidth}
        extra={
          <Button
            onClick={() => {
              handleOffCanvasShow();
              onClose();
            }}
          >
            <Icon icon="akar-icons:chevron-left" />
            &ensp;Back
          </Button>
        }
      >
        {USER_AUTH_REQUIRE_NAV_MAP.map((element) => (
          <React.Fragment key={element.navName}>
            {element.offCanvas === "my-account-body" && (
              <Link
                to={element.navRoute}
                className="link"
                onClick={handleOffCanvasClose}
              >
                <div className={Styles.offCanvasBodyContent}>
                  <div className={Styles.offCanvasBodyTextContainer}>
                    {element.icon !== null && (
                      <div className={Styles.offCanvasIcon}>{element.icon}</div>
                    )}
                    {Transl(element.navName)}
                  </div>
                  <div className={Styles.offCanvasRightArrowContainer}>
                    <Icon icon="akar-icons:chevron-right" />
                  </div>
                </div>
              </Link>
            )}
          </React.Fragment>
        ))}
      </Drawer>
    </>
  );
};

export default OffCanvasNav;
