import {
  GET_PAGE,
  GET_PAGE_SUCCESS,
  GET_PAGE_FAILED,
  UPDATE_PAGE_STATUS,
  UPDATE_PAGE_STATUS_SUCCESS,
  UPDATE_PAGE_STATUS_FAILED,
  GET_PAGE_DETAIL,
  GET_PAGE_DETAIL_SUCCESS,
  GET_PAGE_DETAIL_FAILED,
} from "../Constants/ActionType";

const INIT_STATE = {
  loading: true,
  data: {},
  selectedPage: {},
  statusList: [],
  selectedPageLoading: false,
};

const updatePageStatus = (state, action, loading = true) => {
  let d = state.data;
  d.data = (d.data ?? []).map((v) => {
    if (action.id === v.id) {
      v.loading = loading;
    }
    return v;
  });
  return { ...state, data: d };
};

export const WebstorePageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAGE:
      return { ...state, loading: true };
    case GET_PAGE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_PAGE_FAILED:
      return { ...state, loading: false, data: {} };
    case UPDATE_PAGE_STATUS:
      return updatePageStatus(state, action, true);
    case UPDATE_PAGE_STATUS_SUCCESS:
    case UPDATE_PAGE_STATUS_FAILED:
      return updatePageStatus(state, action, false);
    case GET_PAGE_DETAIL:
      return { ...state, selectedPageLoading: true, selectedPage: {} };
    case GET_PAGE_DETAIL_SUCCESS:
    case GET_PAGE_DETAIL_FAILED:
      return { ...state, selectedPageLoading: false, selectedPage: action?.payload ?? {} };
    default:
      return { ...state };
  }
};
