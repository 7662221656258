import axios from "axios";
import { AppRoutes, LOCAL_USER_TOKEN } from "./../Constants";

const AuthApi = axios.create({
    baseURL: process.env.REACT_APP_AUTHAPI_URL ?? null,
    timeout: 30000,
    headers: { "X-Builderx-Language": localStorage.getItem("i18nextLng") },
});

AuthApi.defaults.headers.common.Authorization = `Bearer ${
    LOCAL_USER_TOKEN ?? ""
}`;
AuthApi.defaults.headers.post["Content-Type"] = "application/json";
AuthApi.defaults.headers.post.Accept = "application/json";
AuthApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }

        if (error.response) {
            if (error.response.data) {
                // NotificationManager.error(error.response.data.message);
            }
        }
        if (
            error?.response?.status === 401 &&
            ![AppRoutes.LOGIN, AppRoutes.REGISTER].includes(
                window.location.pathname
            )
        ) {
            localStorage.removeItem("user");
            localStorage.removeItem("refresh");
            window.location.assign(window.location.origin + AppRoutes.LOGIN);
        }

        return Promise.reject(error);
    }
);

export default AuthApi;
