import React from "react";
import { Button } from "antd";
import Styles from "./Page404.module.scss";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../Constants";

const Page404 = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push(AppRoutes.HOME);
    };

    return (
        <div className={Styles.page404Class}>
            <div className={Styles.contentContainer}>
                <div className={Styles.imageContainer}>
                    <img
                        src={require("../../Assets/Images/Global/undraw_page_not_found_re_e9o6.svg")}
                        alt="404 asset"
                        className={Styles.imageClass}
                    />
                </div>
                <div className={Styles.titleContainer}>
                    <h3>
                        <b>Page not found</b>
                    </h3>
                </div>
                <div className={Styles.messageContainer}>
                    <p>
                        We are sorry, the page you requested could not be found.
                    </p>
                    <p>Please go back to the homepage.</p>
                </div>
                <div className={Styles.buttonContainer}>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        onClick={handleClick}
                    >
                        Back to Home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Page404;
