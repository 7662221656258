const StepCounterInitState = {
    step: 1,
};

export const StepCounterReducer = (state = StepCounterInitState, action) => {
    switch (action.type) {
        case "INCREASE_STEP":
            return {
                ...state,
                step: state.step + 1,
            };

        case "DECREASE_STEP":
            return {
                ...state,
                step: state.step - 1,
            };

        case "RESET_STEP":
            return {
                ...state,
                step: 1,
            };

        default:
            return state;
    }
};
