import { Card, Col, Row, Skeleton } from "antd";
import React from "react";
import "./StoreTable.scss";

const MockStoreTable = () => (
  <div className="site-card-wrapper">
    <Row>
      {new Array(4).fill(null).map((v) => {
        return (
          <Col
            className="store-card-wrapper"
            lg={6}
            xl={6}
            xs={24}
            sm={24}
            md={24}
          >
            <Card
              loading={true}
              className="store-card"
              cover={<Skeleton loading={true} avatar active />}
              bordered={true}
            ></Card>
          </Col>
        );
      })}
    </Row>
  </div>
);

export default MockStoreTable;
