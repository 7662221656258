import React, { useEffect, useReducer } from "react";
import { Input, notification, Form, Modal, Switch, Select } from "antd";
import { formErrorHandler } from "../../../../Helpers";
import { useDispatch, useSelector } from "react-redux";
import { createPage, getWebstorePageDetail } from "../../../../Actions";
import { PageMode } from "../Constant/PageMode";
import _ from "lodash";
import { isValidURL, reducerHelper } from "../../../../Helpers/Helpers";
import { saveWebstorePageDetail } from "../../../../Actions/WebstorePageAction";
import { useHistory } from "react-router-dom";
import { Skeleton } from "../../../../../node_modules/antd/lib/index";
import CustomModal from "../../../../Components/Custom/Modal/index";

const CreatePageModal = ({
  createPageModalVisible,
  modalSubmit,
  handleCreatePageModalCancel,
  handleCreatePageFinish,
  storeId,
  modalMode,
  pageId = 0,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultPageMode = "Builder";

  const { selectedPage, selectedPageLoading } = useSelector(
    ({ webstorePage }) => {
      return {
        selectedPage: webstorePage?.selectedPage,
        selectedPageLoading: webstorePage?.selectedPageLoading,
      };
    }
  );

  const [{ selectedPageMode }, setState] = useReducer(reducerHelper, {
    selectedPageMode: defaultPageMode,
  });

  const handleChangePageMode = (selected) => {
    setState((prev) => ({
      ...prev,
      selectedPageMode: selected,
    }));
  };

  const handleCreatePageFinishFailed = () => {
    notification["error"]({
      message: "Form Validation",
      description: "Please fill-in all the required fields",
    });
  };
  const [form] = Form.useForm();

  const handleModalOk = () => {
    form.submit();
    modalSubmit();
  };

  const handleCreatePageFinishSuccess = (values) => {
    if (_.isUndefined(values?.status)) {
      values.status = false;
    }
    handleCreatePageFinish(values);
    if (modalMode == "create") {
      dispatch(
        createPage(values, (status, err) => {
          if (status === "failed" && err !== null) {
            formErrorHandler(form, err?.response, null);
          } else {
            handleCreatePageModalCancel();
          }
        })
      );
    } else {
      dispatch(
        saveWebstorePageDetail(pageId, values, history, (status, err) => {
          if (status == "success") {
            handleCreatePageModalCancel();
          } else {
            if (err?.response) {
              formErrorHandler(form, err?.response, null);
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    if (!createPageModalVisible) {
      form.resetFields();
      handleChangePageMode(defaultPageMode);
    } else {
      if (modalMode == "create") {
        form.setFieldsValue({ page_mode: defaultPageMode });
      } else {
        dispatch(getWebstorePageDetail(pageId, history));
      }
    }
  }, [createPageModalVisible, storeId, form]);

  useEffect(() => {
    if (!_.isEmpty(selectedPage)) {
      form.setFieldsValue(selectedPage);
      setState(
        (prev) => ({...prev,
        selectedPageMode: selectedPage.page_mode
      }));
    }
  }, [selectedPage]);

  return (
    <CustomModal
      title={`${_.capitalize(modalMode)} Page`}
      visible={createPageModalVisible}
      onOk={handleModalOk}
      centered
      okButtonProps={{
        disabled: modalMode == "edit" && selectedPageLoading == true,
      }}
      onCancel={handleCreatePageModalCancel}
    >
      {modalMode == "edit" && selectedPageLoading == true ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleCreatePageFinishSuccess}
          onFinishFailed={handleCreatePageFinishFailed}
          autoComplete="off"
        >
          {modalMode == "edit" && (
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>
          )}
          <Form.Item
            label="Page Name"
            name="page_name"
            rules={[
              {
                required: true,
                message: "Page Name is required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Page Mode" name="page_mode">
            <Select
              defaultValue={defaultPageMode}
              onChange={handleChangePageMode}
              value={defaultPageMode}
            >
              {Object.entries(PageMode ?? []).map(([k, v]) => {
                return <Select.Option value={v}>{v}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Status" valuePropName="checked" name="status">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Route Path"
            name="page_route"
            rules={[
              {
                required: true,
                message: "Route Path is required!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {selectedPageMode == PageMode.RedirectUrl && (
            <Form.Item
              label="Redirect Url"
              name="content"
              rules={[
                {
                  required: true,
                  message: "Redirect Url is required!",
                },
                {
                  validator: (__, value) => {
                    if (isValidURL(value)){
                      return Promise.resolve();
                    } else{
                      return Promise.reject(new Error('Redirect Url is not a valid url'))
                    }
                  }
                }
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      )}
    </CustomModal>
  );
};

export default CreatePageModal;
