import React, { useEffect, useReducer } from "react";
import { Breadcrumb, Button } from "antd";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import YouIFullWidthContent from "../../../Components/DivDesign/YouIFullWidthContent";
import BreadcrumbComponent from "../../../Components/Breadcrumb/BreadcrumbComponent";
import { AppRoutes } from "../../../Constants";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Icon } from "@iconify/react";
import appsIcon from "@iconify/icons-charm/apps";
import storeIcon from "@iconify/icons-dashicons/store";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import windowIcon from "@iconify/icons-bi/window";
import { reducerHelper } from "../../../Helpers";
import { API_TABLE_ROWS_PER_PAGE } from "../../../Constants/AppConfig";
import CustomPagination from "../../../Components/CustomPagination";
import IndexTableColumn from "./Component/IndexTableColumn";
import { getAllWebstoreDomains, setSelectedStore } from "../../../Actions";
import CreateDomainModal from "./Component/CreateDomain/CreateDomainModal";

const DomainManagement = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [
    { createDomainModalVisible, filters, sort, pagination, storeId },
    setState,
  ] = useReducer(reducerHelper, {
    createDomainModalVisible: false,
    pagination: {
      page: 1,
      limit: API_TABLE_ROWS_PER_PAGE,
    },
    storeId: null,
    filters: {},
    sort: "-created_at",
  });

  const { selectedStore, webstoreDomain } = useSelector(
    ({ coreApp, webstoreDomain }) => {
      return {
        selectedStore: coreApp?.selectedStore,
        webstoreDomain,
      };
    }
  );

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      storeId: props?.match?.params?.storeId,
    }));
    dispatch(setSelectedStore(props?.match?.params?.storeId));
  }, [props?.match?.params?.storeId, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(storeId)) {
      dispatch(
        getAllWebstoreDomains(
          pagination.page,
          API_TABLE_ROWS_PER_PAGE,
          {},
          sort
        )
      );
    }
  }, [
    dispatch,
    sort,
    history,
    props?.match?.params?.storeId,
    storeId,
    pagination,
  ]);

  const StoreDomainManagementBreadcrumb = () => {
    return (
      <BreadcrumbComponent>
        <Breadcrumb.Item>
          <Icon icon={storeIcon} />
          &nbsp;&nbsp;
          <Link to={AppRoutes.STORE}> Store</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Icon icon={appsIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(AppRoutes.STOREDETAIL, ":storeId", selectedStore?.id)}
          >
            {" "}
            {selectedStore?.store_name} Apps
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Icon icon={windowIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(
              AppRoutes.STOREPAGES,
              ":storeId",
              props?.match?.params?.storeId
            )}
          >
            Domain Management
          </Link>
        </Breadcrumb.Item>
      </BreadcrumbComponent>
    );
  };

  const showCreateDomain = () => {
    setState((prev) => ({
      ...prev,
      createDomainModalVisible: true,
    }));
  };

  const createDomainModalSubmit = () => {};

  const handleCreateDomainModalCancel = () => {
    setState((prev) => ({
      ...prev,
      createDomainModalVisible: false,
    }));
  };

  const handleCreateDomainFinish = (values) => {};

  const onPaginationChange = (page, pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page,
        limit: pageSize,
      },
    }));
  };

  const onShowSizeChange = (current, size) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page: current,
        limit: size,
      },
    }));
  };

  return (
    <>
      <YouIDivDesign>
        <YouIFullWidthContent
          breadcrumbComp={<StoreDomainManagementBreadcrumb />}
          additionalPageTitleContent={
            <Button type="primary" onClick={showCreateDomain}>
              Create Domain
            </Button>
          }
          customContentStyle={{ height: "100%" }}
          customBackgroundStyle={{
            height: "100%",
            minHeight: "100%",
          }}
          title="Domain Management"
          withTitle
        >
          <IndexTableColumn
            filters={filters}
            pagination={pagination}
            setState={setState}
            webstoreDomain={webstoreDomain}
            storeId={storeId}
          />
          <CreateDomainModal
            createDomainModalVisible={createDomainModalVisible}
            modalSubmit={createDomainModalSubmit}
            handleCreateDomainModalCancel={handleCreateDomainModalCancel}
            handleCreateDomainFinish={handleCreateDomainFinish}
            storeId={storeId}
          />
          <CustomPagination
            text="Domains"
            onShowSizeChange={onShowSizeChange}
            onChange={onPaginationChange}
            dataSource={webstoreDomain?.data}
          />
        </YouIFullWidthContent>
      </YouIDivDesign>
    </>
  );
};

export default withRouter(DomainManagement);
