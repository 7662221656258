import React, { useEffect, useState } from "react";
import { Row, Col, Card, Modal, Table, Button } from "antd";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSubscription,
  getSubscriptionDetail,
} from "../../Actions/SubscriptionAction";
import { Transl } from "../../Helpers";

const PackageModal = ({
  isPackageModalOpen,
  handlePackageCancel,
  showChangeSubscriptionSelectorModal,
  selectedSubscriptionId,
}) => {
  const dispatch = useDispatch();

  const { subscriptionDetail, planPackageDetail, subscriptionDetailLoading } =
    useSelector(({ subscription, planPackage }) => {
      return {
        subscriptionDetail: subscription?.selectedSubscription?.data,
        planPackageDetail: planPackage,
        subscriptionDetailLoading: subscription?.selectedSubscription?.loading,
      };
    });
  const [abled, setAbled] = useState(false);
  useEffect(() => {
    if (isPackageModalOpen) {
      dispatch(getSubscriptionDetail(selectedSubscriptionId));
    }
  }, [isPackageModalOpen]);
  const handleChangeSubscription = (id) => {
    dispatch(
      changeSubscription({
        subscription_id: subscriptionDetail.id,
        oldPackageId: subscriptionDetail.package_id,
        newPackageId: id,
      })
    );
    handlePackageCancel();
  };

  const packageModalSelect = Transl("package.select");
  const packagesColumns = [
    {
      title: Transl("package.plan.title"),
      key: "plan",
      width: "40%",
      render: (text, record, index) => {
        return index == 0 ? record.plans.plan_name : null;
      },
    },
    {
      title: Transl("package.title"),
      dataIndex: "package_name",
      key: "package",
      width: "30%",
    },
    {
      title: Transl("package.price"),
      key: "price",
      width: "15%",
      render: (text, record, index) => {
        return "RM " + record.price;
      },
    },
    {
      title: Transl("package.action"),
      key: "action",
      width: "15%",
      render: (text, record, index) => {
        return (
          <Button
            disabled={
              subscriptionDetail?.package?.package_name == record.package_name
                ? abled
                : setAbled(true)
            }
            type="primary"
            onClick={() => handleChangeSubscription(record.id)}
          >
            <Icon icon="ant-design:select-outlined" /> {packageModalSelect}
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={Transl("package.modal.title")}
      open={isPackageModalOpen}
      footer={null}
      onCancel={handlePackageCancel}
      width={800}
    >
      <Card
        loading={subscriptionDetailLoading}
        title={
          <div>
            <span>{Transl("package.subscription.title")}</span>
          </div>
        }
      >
        <Row gutter={[8, 0]}>
          <Col md={12}>
            <Icon icon="wpf:renew-subscription" />{" "}
            {Transl("package.current.plan")}:{" "}
            {subscriptionDetail?.package?.package_name}
          </Col>
          <Col md={12}>
            <Icon icon="clarity:date-line" /> {Transl("package.expiry")}:{" "}
            {subscriptionDetail?.next_billing_date}
          </Col>
        </Row>
      </Card>
      <br />
      <Table
        style={{ overflow: "auto" }}
        columns={packagesColumns}
        dataSource={planPackageDetail?.data?.data}
        pagination={{
          pageSize: 4,
        }}
      />
    </Modal>
  );
};

export default PackageModal;
