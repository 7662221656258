import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const Spinner = ({
    fullHeight = false,
    componentLoading = false,
    children = null,
}) => {
    return (
        <>
            {fullHeight ? (
                <div className="suspenseSpinnerContainer">
                    <Spin indicator={antIcon} />
                </div>
            ) : (
                <Spin indicator={antIcon} spinning={componentLoading}>
                    {children}
                </Spin>
            )}
        </>
    );
};

export default Spinner;
