import { Col, Row, Input, Select, Tag, Modal, Form, Button } from "antd";
import "./SearchPanel.scss";
import React, { forwardRef } from "react";
import { Icon } from "@iconify/react";
import searchIcon from "@iconify/icons-il/search";
import filterIcon from "@iconify/icons-bytesize/filter";
import styledComponents from "styled-components";
import { isMobile } from "react-device-detect";
import CustomModal from "../Custom/Modal/index";

const SearchPanel = forwardRef(
  (
    {
      initialSearchOptions = "",
      searchOptions = [],
      searchValue = "",
      searchObject = "",
      filterTag = [],
      filterObject = [],
      onSearchChange = null,
      captureSearchFieldValue = null,
      handleTagDimiss = null,
      withFilterModal = false,
      filterModalClose,
      filterModalVisible,
      filterOptions = [],
      openFilterModal = null,
      handleFilterModalSubmit = null,
      form,
      handleFilterSubmit,
      withDropDownSearch = false,
      defaultSearchLabel = null,
      rightComponent = null,
      ...props
    },
    searchRef
  ) => {
    const TagContainer = styledComponents((props) => <div {...props} />)`
  &{
    margin-left:10px !important;
  }
  @media only screen and (max-width: 768px) {
    &{
      margin-top:10px !important;
      margin-left: 0px !important;
    }
  }
`;
    const selectBefore = (
      !withDropDownSearch ? defaultSearchLabel :
      <Select
        dropdownMatchSelectWidth={false}
        defaultValue={initialSearchOptions}
        onChange={onSearchChange}
        className="select-before"
      >
        {searchOptions.map((v) => {
          return <Select.Option value={v.value}>{v.key}</Select.Option>;
        })}
      </Select>
    );

    const selectAfter = (
      <div onClick={captureSearchFieldValue}>
        <Icon
          style={{
            color: "black",
            cursor: "pointer",
            pointerEvents: "none",
          }}
          className="search-button"
          icon={searchIcon}
        />
      </div>
    );

    const handleInternalDimissTag = (key) => {
      if (form){
        form.setFieldsValue({[key]:undefined});
      }
      handleTagDimiss(key);
    }

    return (
      <div className="search-panel-container">
        <div className="row">
          <div className={`col-lg-${withFilterModal ? 5 : 6} col-md-${withFilterModal ? 5 : 6} col-xs-${withFilterModal ? 22 : 24} col-sm-${withFilterModal ? 22 : 24}`}
          >
            <Input
              className="search-panel"
              addonBefore={selectBefore}
              addonAfter={selectAfter}
              ref={searchRef}
              onPressEnter={captureSearchFieldValue}
            />
          </div>
          {withFilterModal && (
            <div className="filter-icon col-auto">
            <Button
                onClick={openFilterModal}
                style={{ color: "black", marginLeft: "10px", height: "38px" }}
                icon={<Icon icon={filterIcon} />}
              />
            </div>
            // <Col lg={1} md={1} xs={2} sm={2}>
              
            // </Col>
          )}
          <div className="filter-tag-listing col-auto">
          <TagContainer className="ml-2">
              {(filterTag ?? []).map((v) => {
                return (
                  <Tag
                    closable
                    key={v.key}
                    onClose={() => handleInternalDimissTag(v.key)}
                  >
                    {v.value}
                  </Tag>
                );
              })}
            </TagContainer>
          </div>
         {
          rightComponent && (
            <div className="col text-end">
            {rightComponent}
          </div>
          )
         }
          {/* <Col lg={12} md={12} xs={24} sm={24}>
            
          </Col> */}
        </div>
        {withFilterModal && (
          <CustomModal
            onCancel={filterModalClose}
            onOk={handleFilterModalSubmit}
            visible={filterModalVisible}
            width={768}
            title="Filter"
            centered
            className="search-panel-filter-modal"
          >
            <Form
              layout={"vertical"}
              form={form}
              onFinish={handleFilterSubmit}
            >
              <Row gutter={24}>
                {(filterOptions ?? []).map(({ label, name, component }) => {
                  return (
                    <Col span={isMobile ? 24 : 12}>
                      <Form.Item name={name} label={label}>
                        {component}
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </CustomModal>
        )}
      </div>
    );
  }
);

export default SearchPanel;
