import React from "react";
import { Route, Switch, withRouter } from "react-router";

import Page404 from "../Components/404/Page404";
import Routers from "../Constants/RouterMap";

const RouteIndex = ({ ...props }) => {
  return (
    <>
      <Switch>
        {Routers.map(({ component, path, key, element, exact }) => {
          if (exact) {
            return <Route exact key={key} path={path} render={component} />;
          } else {
            return <Route key={key} path={path} render={component} />;
          }
        })}
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(RouteIndex);
