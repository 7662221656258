import React from "react";
import { Button, Form, Input } from "antd";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ForgetPassword } from "../../../Actions";
import { Transl } from "../../../Helpers";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import YouIHalfWidthContent from "../../../Components/DivDesign/YouIHalfWidthContent";

export const ForgetPasswordMain = () => {
    const dispatch = useDispatch();

    const handleSubmit = (value) => {
        dispatch(
            ForgetPassword({
                email: value.email,
            })
        );
    };

    return (
        <>
            <YouIDivDesign>
                <YouIHalfWidthContent title={Transl("forget_password")}>
                    <Form className="auth-form" onFinish={handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please insert email!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your email" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <br />

                        <Row>
                            <Col lg={12}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Request Reset Password
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </YouIHalfWidthContent>
            </YouIDivDesign>
        </>
    );
};
