import YouIDivDesign from "../../Components/DivDesign/YouIDivDesign";
import YouIMyContent from "../../Components/DivDesign/YouIMyContent";
import { Row, Col, Card } from "antd";
import { Icon } from "@iconify/react";

const TestingMain = () => {
  return (
    <YouIDivDesign>
      <YouIMyContent title={"Testing"} enableMinHeight>
        <Row>
          <Col md={12}>
            <Card
              title={
                <div>
                  <span style={{ fontSize: "20px" }}>SG Store</span>
                  <small>(xxxxx@youibuilder.com)</small>
                </div>
              }
              actions={[
                <span>
                  <Icon icon="gg:menu" /> Invoice
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Upgrade Package
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Payment Method
                </span>,
              ]}
            >
              <Row gutter={[8, 0]}>
                <Col md={12}>Store ID: xxx</Col>
                <Col md={12}>Next Billing Date: 01.12.2022</Col>
                <Col md={12}>Subscription: Value-Yearly</Col>
                <Col md={12}>Duration: 365days</Col>
              </Row>
              <Row>
                <Col md={24} style={{ textAlign: "center" }}>
                  <a href="#" style={{ color: "red" }}>
                    Cancel Subscription
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <Card
              title={
                <div>
                  <span style={{ fontSize: "20px" }}>SG Store</span>
                  <small>(xxxxx@youibuilder.com)</small>
                </div>
              }
              actions={[
                <span>
                  <Icon icon="gg:menu" /> Invoice
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Upgrade Package
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Payment Method
                </span>,
              ]}
            >
              <Row gutter={[8, 0]}>
                <Col md={12}>Store ID: xxx</Col>
                <Col md={12}>Next Billing Date: 01.12.2022</Col>
                <Col md={12}>Subscription: Value-Yearly</Col>
                <Col md={12}>Duration: 365days</Col>
              </Row>
              <Row>
                <Col md={24} style={{ textAlign: "center" }}>
                  <a href="#" style={{ color: "red" }}>
                    Cancel Subscription
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title={
                <div>
                  <span style={{ fontSize: "20px" }}>SG Store</span>
                  <small>(xxxxx@youibuilder.com)</small>
                </div>
              }
              actions={[
                <span>
                  <Icon icon="gg:menu" /> Invoice
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Upgrade Package
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Payment Method
                </span>,
              ]}
            >
              <Row gutter={[8, 0]}>
                <Col md={12}>Store ID: xxx</Col>
                <Col md={12}>Next Billing Date: 01.12.2022</Col>
                <Col md={12}>Subscription: Value-Yearly</Col>
                <Col md={12}>Duration: 365days</Col>
              </Row>
              <Row>
                <Col md={24} style={{ textAlign: "center" }}>
                  <a href="#" style={{ color: "red" }}>
                    Cancel Subscription
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title={
                <div>
                  <span style={{ fontSize: "20px" }}>SG Store</span>
                  <small>(xxxxx@youibuilder.com)</small>
                </div>
              }
              actions={[
                <span>
                  <Icon icon="gg:menu" /> Invoice
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Upgrade Package
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Payment Method
                </span>,
              ]}
            >
              <Row gutter={[8, 0]}>
                <Col md={12}>Store ID: xxx</Col>
                <Col md={12}>Next Billing Date: 01.12.2022</Col>
                <Col md={12}>Subscription: Value-Yearly</Col>
                <Col md={12}>Duration: 365days</Col>
              </Row>
              <Row>
                <Col md={24} style={{ textAlign: "center" }}>
                  <a href="#" style={{ color: "red" }}>
                    Cancel Subscription
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title={
                <div>
                  <span style={{ fontSize: "20px" }}>SG Store</span>
                  <small>(xxxxx@youibuilder.com)</small>
                </div>
              }
              actions={[
                <span>
                  <Icon icon="gg:menu" /> Invoice
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Upgrade Package
                </span>,
                <span>
                  <Icon icon="gg:menu" /> Payment Method
                </span>,
              ]}
            >
              <Row gutter={[8, 0]}>
                <Col md={12}>Store ID: xxx</Col>
                <Col md={12}>Next Billing Date: 01.12.2022</Col>
                <Col md={12}>Subscription: Value-Yearly</Col>
                <Col md={12}>Duration: 365days</Col>
              </Row>
              <Row>
                <Col md={24} style={{ textAlign: "center" }}>
                  <a href="#" style={{ color: "red" }}>
                    Cancel Subscription
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
      </YouIMyContent>
    </YouIDivDesign>
  );
};

export default TestingMain;
