import React from "react";
import CustomModal from "../../../../Components/Custom/Modal/index";
import SortTableColumn from "./Component/SortTableColumn";

const GridSortModal = ({
  gridSortModalVisible,
  handleCancel,
  handleOk,
  gridSortData,
  notifyToStoreFrontHandler,
  ...props
}) => {
  return (
    <>
      <CustomModal
        title={<div>Layout Modal</div>}
        open={gridSortModalVisible}
        footer={null}
        width={1000}
        centered
        onCancel={handleCancel}
      >
        <SortTableColumn notifyToStoreFrontHandler={notifyToStoreFrontHandler} gridSortData={gridSortData}/>
      </CustomModal>
    </>
  );
};

export default GridSortModal;
