import React, { useEffect, useReducer } from "react";
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Switch } from "antd";
import YouIDivDesign from "../../../../Components/DivDesign/YouIDivDesign";
import YouIFullWidthContent from "../../../../Components/DivDesign/YouIFullWidthContent";
import BreadcrumbComponent from "../../../../Components/Breadcrumb/BreadcrumbComponent";
import { Icon } from "@iconify/react";
import storeIcon from "@iconify/icons-dashicons/store";
import windowIcon from "@iconify/icons-bi/window";
import { useDispatch, useSelector } from "react-redux";
import {
    getWebstorePageDetail,
    saveWebstorePageDetail,
    setSelectedStore,
} from "../../../../Actions";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { AppRoutes } from "../../../../Constants";
import { reducerHelper, Transl } from "../../../../Helpers";
import CKEditor from "../../../../Components/FroalaEditor";
import { formErrorHandler } from "../../../../Helpers/Helpers";
import { getAllPages } from "../../../../Actions/WebstorePageAction";
import { API_TABLE_ROWS_PER_PAGE } from "../../../../Constants/AppConfig";

const PageEditor = ({ ...props }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [{ editorContent }, setState] = useReducer(reducerHelper, {
        editorContent: "",
    });

    const { selectedPage } = useSelector(({ webstorePage }) => {
        return {
            selectedPage: webstorePage?.selectedPage,
        };
    });

    const [form] = Form.useForm();

    useEffect(() => {
        if (!_.isEmpty(selectedPage)) {
            form.setFieldsValue(selectedPage);
            setState((prev) => ({
                ...prev,
                editorContent:selectedPage.content
            }));
        }
    }, [form, selectedPage]);

    useEffect(() => {
        dispatch(setSelectedStore(props?.match?.params?.storeId));
        form.resetFields();
        dispatch(
            getWebstorePageDetail(
                props?.match?.params?.pageId,
                history
            )
        );
    }, [props?.match?.params]);

    const StorePageManagementBreadcrumb = () => {
        return (
            <BreadcrumbComponent>
                <Breadcrumb.Item>
                    <Icon icon={storeIcon} />
                    &nbsp;&nbsp;
                    <Link
                        to={_.replace(
                            AppRoutes.STOREDETAIL,
                            ":storeId",
                            props?.match?.params?.storeId
                        )}
                    >
                        {" "}
                        {selectedPage?.store?.store_name} {Transl("common.store")}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Icon icon={windowIcon} />
                    &nbsp;&nbsp;
                    <Link
                        to={_.replace(
                            AppRoutes.STOREPAGES,
                            ":storeId",
                            props?.match?.params?.storeId
                        )}
                    >
                        
                        {Transl("common.pageEditor")}
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Icon icon={windowIcon} />
                    &nbsp;&nbsp;
                    {selectedPage?.page_name}
                </Breadcrumb.Item>
            </BreadcrumbComponent>
        );
    };

    const onFinish = (values) => {
        dispatch(
            saveWebstorePageDetail(
                props?.match?.params?.pageId,
                values,
                history,
                (status,err) => {
                    if (status == 'success'){                
                        let pg = AppRoutes.STOREPAGES;
                        pg = _.replace(pg, ":storeId", props?.match?.params?.storeId);
                        history.push(pg);
                        dispatch(getAllPages(1, API_TABLE_ROWS_PER_PAGE, []));
                    } else {
                        if (err?.response){
                            formErrorHandler(form, err?.response, null);
                        }
                    }
                }
            )
        );
    };

    return (
        <>
            <YouIDivDesign>
                <YouIFullWidthContent
                    breadcrumbComp={<StorePageManagementBreadcrumb />}
                    additionalPageTitleContent={
                        <Button type="primary" onClick={() => form.submit()}>
                            Save
                        </Button>
                    }
                    withTitle
                    title={Transl("common.pageEditor")}
                    withFooter
                >
                    <Row>
                        <Col lg={24}>
                            <Card>
                                <Form
                                    onFinish={onFinish}
                                    form={form}
                                    layout="vertical"
                                >
                                    <Form.Item
                                        name="id"
                                        hidden={true}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="page_name"
                                        label="Page Name"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="page_route"
                                        label="Route Path"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        valuePropName="checked"
                                    >
                                        <Switch/>
                                    </Form.Item>
                                    <Form.Item name="content" label="Content">
                                        <CKEditor
                                            customHeight={1000}
                                            model={editorContent}
                                            attributes={false}
                                            onModelChange={
                                                (val) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        editorContent: val,
                                                    }));
                                                    form.setFieldsValue({content:val});
                                                }
                                            }
                                        />
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </YouIFullWidthContent>
            </YouIDivDesign>
        </>
    );
};

export default PageEditor;
