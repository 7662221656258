import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./YouIHalfWidthContent.module.scss";

const YouIHalfWidthContent = ({...props}) => {
    return (
        <div className={Styles.youIBodyContainer}>
            <Row className={`${Styles.row} justify-content-md-center`}>
                <Col lg={8} xl={6} className={Styles.youICol}>
                    <div className={Styles.youIHalfWidthContainer}>
                        <h1 className={Styles.halfWidthPageTitle}>{props.title}</h1>
                        <hr />
                        {props.children}
                    </div>
                </Col>
                {props?.secondCol}
            </Row>
            {props?.additionalRow?.map((element) => {
                return (
                    <Row className="justify-content-md-center">
                        <Col lg={8} xl={6} className={Styles.youICol}>
                            <div className={Styles.youIHalfWidthContainer}>
                                <h1 className={Styles.halfWidthPageTitle}>
                                    {element.title}
                                </h1>
                                <hr />
                                {element.component}
                            </div>
                        </Col>
                        {props?.secondCol}
                    </Row>
                );
            })}
        </div>
    );
};

export default YouIHalfWidthContent;
