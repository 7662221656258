import React from "react";
import { Empty } from "antd";
import emptyDataAsset from "../../Assets/Images/Global/undraw_no_data_re_kwbl.svg";

const EmptyComponent = ({
    image = emptyDataAsset,
    imageStyle = { height: 100 },
    description = <span style={{ fontSize: "1rem" }}>No Data Found.</span>,
}) => {
    return (
        <Empty
            image={image}
            imageStyle={imageStyle}
            description={description}
        />
    );
};

export default EmptyComponent;
