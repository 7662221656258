import React from "react";
import { Select } from "../../../../node_modules/antd/lib/index";

const FontStyle = ({ defaultValue, onChange, ...props }) => {
  let fontStyling = [
    {
      value: "H1",
      tagName: "h1",
    },
    {
      value: "H2",
      tagName: "h2",
    },
    {
      value: "H3",
      tagName: "h3",
    },
    {
      value: "H4",
      tagName: "h4",
    },
    {
      value: "H5",
      tagName: "h5",
    },
    {
      value: "span",
      tagName: "span",
    },
    {
      value: "p",
      tagName: "p",
    },
  ];
  return (
    <Select
      dropdownMatchSelectWidth={false}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {fontStyling.map(({ value, tagName }) => {
        return (
          <Select.Option value={value}>
            <tagName>{tagName}</tagName>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FontStyle;
