import React, { useEffect } from "react";
import { Input, notification, Form, Modal, Switch, Select } from "antd";
import { formErrorHandler } from "../../../../Helpers";

const CreatePageModal = ({
  modalVisible,
  handleCreatePageSubmit,
  handleModalVisible,
  builderPages,
  ...props
}) => {
  const [form] = Form.useForm();
  //const dispatch = useDispatch();

  const handleCreatePageSubmitOk = () => {
    form.submit();
  };

  const handleCreatePageFinishSuccess = (value) => {
    handleCreatePageSubmit(value);
  };

  const handleCreatePageFinishFailed = () => {
    notification["error"]({
      message: "Form Validation",
      description: "Please fill-in all the required fields",
    });
  };

  useEffect(() => {
    if (!modalVisible) {
      form.resetFields();
    }
  }, [modalVisible, form]);

  return (
    <Modal
      title="Create Page"
      centered
      visible={modalVisible}
      onOk={handleCreatePageSubmitOk}
      onCancel={() => handleModalVisible(false)}
      width={1000}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleCreatePageFinishSuccess}
        onFinishFailed={handleCreatePageFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Page Name"
          name="page"
          rules={[
            {
              required: true,
              message: "Page Name is required!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Page Path"
          name="pagePath"
          rules={[
            {
              required: true,
              message: "Page Path is required!",
            },
            {
              validator:(__,value) => {
                if ((_.map(builderPages,'value')).includes(value)){
                  return Promise.reject(new Error('Page Path is not unique'));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePageModal;
