import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
const { RangePicker } = DatePicker;

const CustomRangePicker = ({
    onChange
}) => {
    return (
        <RangePicker
      ranges={{
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'),moment().subtract(1, 'days')],
        'Last Month': [moment().subtract(1, 'months').startOf('month'),moment().subtract(1, 'months').endOf('month')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Week': [moment().subtract(1, 'weeks').startOf('week'),moment().subtract(1, 'weeks').endOf('week')],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
      }}
      onChange={onChange}
    />
    );
}

export default CustomRangePicker;