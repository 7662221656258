import React from "react";
import { Tabs, Card } from "antd";
import ListingEditor from "../../../../Components/BuilderConfiguration/ListingEditor/index";
import _ from "lodash";
import GenericFormEditor from "../../../../Components/BuilderConfiguration/GenericFormEditor/index";
import "./index.scss";

const ConfigurationContainer = ({
  setChange,
  mainGroup,
  selectedBlock,
  iConfiguration,
  ...props
}) => {
  const renderDynamicEditor = (editorType, datVal) => {
    switch (editorType) {
      case "dataListing":
        return (
          <ListingEditor
            selectedBlock={selectedBlock}
            setChange={setChange}
            mainGroup={mainGroup}
            settings={datVal}
          />
        );
      default:
        return (
          <GenericFormEditor
            selectedBlock={selectedBlock}
            mainGroup={mainGroup}
            setChange={setChange}
            settings={datVal}
          />
        );
    }
  };
  const itemLists = Object.entries(
    _.groupBy(iConfiguration, "group") ?? []
  ).map(([k, v], i) => {
    return {
      label: _.startCase(_.camelCase(k)),
      key: i,
      children: _.chunk(v, 2).map((d) => {
        return (
          <div className="row">
            {d.map((val) => {
              if (val.fullWidthInEditing) {
                return (
                  <div className="col-lg-12">
                    {!val.hideDisplayTitle && (
                      <div className="col-lg-12">
                        <label className="configuration-container-display-title-label">
                          {val.displayTitle}
                        </label>
                      </div>
                    )}
                    <div className="col-lg-12">
                      {renderDynamicEditor(val.editorType, val)}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={`col-lg-${d.length > 1 ? 6 : 12}`}>
                    {val.hideDisplayTitle ? (
                      <div className="col-lg-12">
                        {renderDynamicEditor(val.editorType, val)}
                      </div>
                    ) : (
                      <>
                        {/* {" "} */}
                        <Card
                          className="builder-configuration-form-card"
                          title={val.displayTitle}
                        >
                          {renderDynamicEditor(val.editorType, val)}
                        </Card>
                        {/* <div className="col-lg-12">
                          <label className="configuration-container-display-title-label">
                            {val.displayTitle}
                          </label>
                        </div>
                        <div className="col-lg-12">
                          {renderDynamicEditor(val.editorType, val)}
                        </div> */}
                      </>
                    )}
                    {/* {!val.hideDisplayTitle && (<div className="col-lg-12"><label className="configuration-container-display-title-label">{val.displayTitle}</label></div>)}
                      <div className="col-lg-12">{renderDynamicEditor(val.editorType, val)}</div> */}
                  </div>
                );
              }
            })}
          </div>
        );
      }),
    };
  });
  return <Tabs defaultActiveKey="-1" onChange={() => {}} items={itemLists} />;
};

export default ConfigurationContainer;
