// Packages
import React from "react";
import { useSelector } from "react-redux";
// Design Frameworks
import { Row, Col } from "react-bootstrap";
// CSS
import styled from "./YouIFullWidthContent.module.scss";
import _ from "lodash";

const YouIFullWidthContent = ({
    withTitle = false,
    title = null,
    ...props
}) => {
    const { webSetting } = useSelector((state) => state);

    return (
        <>
            <div className={styled.youIFullWidthBackground} />
            <div className={styled.youIFullWidthContainer}>
                <div className={styled.youIFullWidthContentContainer}>
                    {!webSetting.isMobile && (
                        <>
                            <div className={`${styled.fullWidthBreadcrumbContainer}`}>
                                <Row>
                                    <Col md={6}>{props.breadcrumbComp}</Col>
                                </Row>
                            </div>
                            <Row className={styled.fullWidthPageTitle}>
                                <Col md={6}>
                                    <h1>{(withTitle || (_.isString(title) && (title?.length ?? "") > 0)) && title}</h1>
                                </Col>
                                <Col md={6} className="text-end">
                                    {props.additionalPageTitleContent}
                                </Col>
                            </Row>
                        </>
                    )}
                    {webSetting.isMobile && (
                        <>
                            {/* <div className={`${styled.fullWidthBreadcrumbContainer}`}>
                                {props.breadcrumbComp}
                            </div> */}
                            <Row className={styled.fullWidthPageTitle}>
                                <Col xs={6}>
                                    <h2>{withTitle && title}</h2>
                                </Col>
                                <Col xs={6} className="text-end">
                                    {props.additionalPageTitleContent}
                                </Col>
                            </Row>
                        </>
                    )}

                    <hr />
                    {/* <Alert
                        message="Informational Notes"
                        description="Additional description and information about copywriting."
                        type="info"
                        showIcon
                        style={{ marginBottom: "1%" }}
                    /> */}
                    <div className={styled.innerContentContainer}>
                        {props.children}
                    </div>
                </div>
                {webSetting?.footer}
            </div>
        </>
    );
};

export default YouIFullWidthContent;
