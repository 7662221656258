import {
  //   SAVE_PAGE,
  //   SAVE_PAGE_SUCCESS,
  //   SAVE_PAGE_FAILED,
  GET_PAGE,
  GET_PAGE_SUCCESS,
  GET_PAGE_FAILED,
  UPDATE_PAGE_STATUS,
  UPDATE_PAGE_STATUS_SUCCESS,
  UPDATE_PAGE_STATUS_FAILED,
  GET_PAGE_DETAIL,
  GET_PAGE_DETAIL_SUCCESS,
  GET_PAGE_DETAIL_FAILED,
} from "../Constants/ActionType";
import { API_TABLE_ROWS_PER_PAGE } from "../Constants/AppConfig";
import BuilderApi from "../Inceptors/BuilderApi";
import { NotificationManager } from "react-notifications";
import { message } from "antd";
import { AppRoutes } from "../Constants";
import _ from "lodash";

export const getAllPages =
  (page, limit, filter = {}) =>
  (dispatch) => {
    dispatch({ type: GET_PAGE });
    let url = `/api/v1/page?page=${page}&limit=${limit}`;
    if (!_.isEmpty(filter)){
      filter.map((v) => {
        url += `&filter[${v.key}]=${v.value}`;
        return url;
      });
    }
    BuilderApi.get(url)
      .then((response) => {
        let d = response?.data?.response?.data ?? [];
        d.data = (d?.data ?? []).map((v) => {
          v.loading = false;
          return v;
        });
        dispatch({
          type: GET_PAGE_SUCCESS,
          payload: d,
        });
      })
      .catch((err) => {
        dispatch({ type: GET_PAGE_FAILED });
      });
  };

export const createPage = (values, callback) => (dispatch) => {
  let url = `/api/v1/page`;
  BuilderApi.post(url, values)
    .then(() => {
      message.success("Page created successfully!");
      callback("success", null);
      dispatch(getAllPages(1, API_TABLE_ROWS_PER_PAGE, []));
    })
    .catch((err) => {
      if (err?.response?.data?.errors) {
        callback("failed", err);
        return;
      }
      NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
};

export const updatePageStatus = (id) => (dispatch) => {
  dispatch({ type: UPDATE_PAGE_STATUS, id });
  let url = `/api/v1/page/${id}/status`;
  BuilderApi.post(url, {})
    .then((response) => {
      message.success("Page status updated successfully!");
      dispatch({ type: UPDATE_PAGE_STATUS_SUCCESS, id });
    })
    .catch((err) => {
      NotificationManager.error(err?.response?.data?.error ?? err?.message);
      dispatch({ type: UPDATE_PAGE_STATUS_FAILED, id });
    });
};

export const deleteWebstorePage = (id) => (dispatch) => {
  let url = `/api/v1/page/${id}`;
  BuilderApi.delete(url)
    .then(() => {
      message.success("Page delete successfully!");
      dispatch(getAllPages(1, API_TABLE_ROWS_PER_PAGE, []));
    })
    .catch((err) => {
      NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
};

export const getWebstorePageDetail = (id, history) => (dispatch) => {
  dispatch({ type: GET_PAGE_DETAIL });
  let url = `/api/v1/page/${id}`;
  BuilderApi.get(url)
    .then((response) => {
      dispatch({
        type: GET_PAGE_DETAIL_SUCCESS,
        payload: response?.data?.response?.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_PAGE_DETAIL_FAILED });
      if (err?.response?.status === 404) {
        NotificationManager.error("Page not found!");
        history.push(AppRoutes.STORE);
      } else {
        NotificationManager.error(err?.response?.data?.error ?? err?.message);
      }
    });
};

export const saveWebstorePageDetail =
  (id, values, history, callback = null) => (dispatch,getState) => {
    let url = `/api/v1/page/${id}`;
    BuilderApi.put(url, values)
      .then((response) => {
        message.success("Save successfully!");
        callback('success',response);
        // let pg = AppRoutes.STOREPAGES;
        // pg = _.replace(pg, ":storeId", getState()?.coreApp?.selectedStoreId);
        // history.push(pg);
        // dispatch(getAllPages(1, API_TABLE_ROWS_PER_PAGE, []));
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          NotificationManager.error("Page not found!");
          history.push(AppRoutes.STORE);
        } else {
          if (callback && err?.response?.data?.errors) {
            callback("error", err);
            return;
          }
          NotificationManager.error(err?.response?.data?.error ?? err?.message);
        }
      });
  };
