import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CountryLocationAction, StateLocationAction } from "../../Actions";
import { Icon } from "@iconify/react";
import { Transl } from "../../Helpers";

const Option = Select;

export const AddressFormComponent = () => {
    const dispatch = useDispatch();
    const { stateLocation, country, auth } = useSelector((state) => state);

    useEffect(() => {
        dispatch(CountryLocationAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            StateLocationAction(auth?.account?.address?.country_id ?? null)
        );
    }, [dispatch, auth?.account?.address]);

    const selectCountry = (value) => {
        dispatch(StateLocationAction(value ?? null));
    };

    return (
        <>
            <Row>
                <Col lg={3}>
                    <h3>Address Line 1</h3>
                </Col>

                <Col lg={9}>
                    <Form.Item name="address1">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <h3>Address Line 2</h3>
                </Col>

                <Col lg={9}>
                    <Form.Item name="address2">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <h3>City</h3>
                </Col>

                <Col lg={9}>
                    <Form.Item name="city">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <h3>
                        Country&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>

                <Col lg={9}>
                    <Form.Item
                        name="CountryLocation"
                        rules={[
                            {
                                required: true,
                                message: "Please select a country.",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={Transl("field.placeholder", {
                                field: Transl("country"),
                            })}
                            onChange={selectCountry}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            loading={country.loading}
                        >
                            {(country?.countries ?? []).map(
                                ({ country_name, id }) => {
                                    return (
                                        <Option value={id}>
                                            {country_name}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <h3>State</h3>
                </Col>

                <Col lg={9}>
                    <Form.Item name="StateLocation">
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={Transl("field.placeholder", {
                                field: Transl("state"),
                            })}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            loading={stateLocation.loading}
                        >
                            {(stateLocation?.stateLocation ?? []).map(
                                ({ state_name, id }) => {
                                    return (
                                        <Option value={id}>{state_name}</Option>
                                    );
                                }
                            )}
                        </Select>
                        <br />
                        <span>(Please select country before select state)</span>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
