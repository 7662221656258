const AuthInitState = {
    loading: false,
    error: null,
    status: null,
    account: {},
};

export const AuthReducer = (state = AuthInitState, action) => {
    switch (action.type) {
        case "USER_AUTH_REQUEST":
            return {
                ...state,
                loading: true,
                account: {},
                error: null,
                status: null,
            };

        case "USER_PROFILE":
            return {
                ...state,
                loading: false,
                account: action.profile,
                error: null,
                status: action.status,
            };

        case "USER_AUTH_FAILED":
            return {
                ...state,
                loading: false,
                account: {},
                error: action.error,
                status: action.status,
            };

        case "USER_CLEAR_ERROR":
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

const RegisterinitState = {
    email: null,
    password: null,
    first_name: null,
    last_name: null,
    contact: null,
};

export const RegisterReducer = (state = RegisterinitState, action) => {
    switch (action.type) {
        case "INSERT_REGISTER_PAGE":
            return {
                ...state,
                email: action.email,
                password: action.password,
                first_name: action.first_name,
                last_name: action.last_name,
                contact: action.contact,
            };

        default:
            return state;
    }
};
