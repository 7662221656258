import { AppRoutes } from "./Routes";
import { Icon } from "@iconify/react";
import accountIcon from "@iconify/icons-codicon/account";
import package2 from "@iconify/icons-lucide/package-2";
import appMenu from "@iconify/icons-fe/app-menu";
import settingsFilled from "@iconify/icons-ci/settings-filled";

// offCanvas: "header" / "body"
export const GENERAL_NAV_MAP = [
  {
    navName: "sign_in",
    navRoute: AppRoutes.LOGIN,
    offCanvas: "header",
    icon: <Icon icon="fa:sign-in" />,
  },
  {
    navName: "sign_up",
    navRoute: AppRoutes.REGISTER,
    offCanvas: "header",
    icon: null,
  },
];

// barType: "navbar" / "sidebar" / "my-account-dropdown"
// offCanvas: "header" / "primary-body" / "my-account-body"
export const USER_AUTH_REQUIRE_NAV_MAP = [
  {
    navName: "My Profile",
    navRoute: AppRoutes.PROFILE,
    barType: "my-account-dropdown",
    offCanvas: "my-account-body",
    icon: <Icon icon={accountIcon} />,
  },
  {
    navName: "My Subscription",
    navRoute: AppRoutes.SUBSCRIPTION,
    barType: "my-account-dropdown",
    offCanvas: "my-account-body",
    icon: <Icon icon={package2} />,
  },
  {
    navName: "My App",
    navRoute: "",
    barType: "my-account-dropdown",
    offCanvas: "my-account-body",
    icon: <Icon icon={appMenu} />,
  },
  {
    navName: "My Setting",
    navRoute: "",
    barType: "my-account-dropdown",
    offCanvas: "my-account-body",
    icon: <Icon icon={settingsFilled} />,
  },
  {
    navName: "Store",
    navRoute: AppRoutes.STORE,
    barType: "sidebar",
    offCanvas: "primary-body",
    icon: null,
  },
];
