import Footer from "../Components/Footer/Footer";
import {
    DISABLE_FOOTER,
    DISABLE_MOBILE_VIEW,
    ENABLE_FOOTER,
    ENABLE_MOBILE_VIEW,
    HIDE_NAVBAR,
    SHOW_NAVBAR,
} from "../Constants/ActionType";

const SettingInitState = {
    hideNav: false,
    isMobile: false,
    footer: null,
};

export const WebSettingReducer = (state = SettingInitState, action) => {
    switch (action.type) {
        case HIDE_NAVBAR:
            return {
                ...state,
                hideNav: true,
            };

        case SHOW_NAVBAR:
            return {
                ...state,
                hideNav: false,
            };

        case ENABLE_MOBILE_VIEW:
            return {
                ...state,
                isMobile: true,
            };

        case DISABLE_MOBILE_VIEW:
            return {
                ...state,
                isMobile: false,
            };

        case ENABLE_FOOTER:
            return {
                ...state,
                footer: <Footer />,
            };

        case DISABLE_FOOTER:
            return {
                ...state,
                footer: null,
            };

        default:
            return state;
    }
};
