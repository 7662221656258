import {
  GET_ALL_PLAN_PACKAGE_REQUEST,
  GET_ALL_PLAN_PACKAGE_SUCCESS,
  GET_ALL_PLAN_PACKAGE_FAIL,
} from "../Constants/ActionType";

const INIT_STATE = {
  loading: false,
  data: [],
};

export const PackageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PLAN_PACKAGE_REQUEST:
      return { ...state, loading: true };
    case GET_ALL_PLAN_PACKAGE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_ALL_PLAN_PACKAGE_FAIL:
      return { ...state, loading: false, data: [] };
    default:
      return { ...state };
  }
};
