import {
  GET_ALL_PLAN_PACKAGE_REQUEST,
  GET_ALL_PLAN_PACKAGE_SUCCESS,
  GET_ALL_PLAN_PACKAGE_FAIL,
} from "../Constants/ActionType";
import AuthApi from "../Inceptors/AuthApi";

export const getAllPlanPackage = () => (dispatch) => {
  dispatch({ type: GET_ALL_PLAN_PACKAGE_REQUEST });
  let url = `/api/v1/package`;
  AuthApi.get(url)
    .then((response) => {
      dispatch({
        type: GET_ALL_PLAN_PACKAGE_SUCCESS,
        payload: response?.data?.response?.data,
      });
    })
    .catch((err) => {
      message.error(err?.response?.data?.error);
      dispatch({ type: GET_ALL_PLAN_PACKAGE_FAIL });
    });
};
