import React from "react";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import ProfileMaintenanceComponent from "./Component/ProfileMaintenance";
import ChangePasswordComponent from "./Component/ChangePassword";
import YouIFullWidthContent from "../../../Components/DivDesign/YouIFullWidthContent";
import { useSelector } from "react-redux";
import { Card, Skeleton} from "antd";

const UpdateProfileMain = () => {
    const { loading } = useSelector(({auth}) => {
        return {
            loading: auth.loading
        };
      });
  return (
    <YouIDivDesign>
      <YouIFullWidthContent title="My Profile">
        <div className="container-fluid" style={{ padding: 0 }}>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-sm-12">
              <Card className="rounded-card" title="Profile Maintenance">
                {loading ? <Skeleton active/> : <ProfileMaintenanceComponent />}
              </Card>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Card className="rounded-card" title="Change Password">
              {loading ? <Skeleton active/> : <ChangePasswordComponent />}
              </Card>
            </div>
          </div>
        </div>
      </YouIFullWidthContent>
    </YouIDivDesign>
  );
};

export default UpdateProfileMain;
