import React, { useEffect, useReducer, useRef } from "react";

import YouIDivDesign from "Components/DivDesign/YouIDivDesign";
import { Breadcrumb, Select, Input, Button, Form } from "antd";
import BreadcrumbComponent from "Components/Breadcrumb/BreadcrumbComponent";
import { Link, useHistory } from "react-router-dom";
import YouIFullWidthContent from "Components/DivDesign/YouIFullWidthContent";
import { AppRoutes } from "../../Constants";
import StoreTable from "./Component/StoreTable";
import TableTab from "Components/TableTab";
import SearchPanel from "Components/SearchPanel";
import { reducerHelper, Transl } from "Helpers";
import { Icon } from "@iconify/react";
import storeIcon from "@iconify/icons-dashicons/store";
import CreateStore from "./Component/CreateStore";
import { useDispatch, useSelector } from "react-redux";
import {
  createStore,
  getStores,
  setSelectedStore,
  updateStore,
} from "../../Actions/index";
import CustomPagination from "../../Components/CustomPagination";
import MockStoreTable from "./Component/MockStoreTable";
import { API_TABLE_ROWS_PER_PAGE } from "../../Constants/AppConfig";
import _ from "lodash";
import moment from "moment";
import CustomRangePicker from "../../Components/Custom/RangePicker";
import { t } from "i18next";
import { Helmet } from "react-helmet";

const ListStore = ({ ...props }) => {
  const searchRef = useRef(null);
  const [filterForm] = Form.useForm();
  const { store } = useSelector(({ store }) => {
    return {
      store,
    };
  });
  const [
    {
      modalVisible,
      createStoreModalVisible,
      pagination,
      modalMode,
      selectedStore,
      defaultActiveKey,
    },
    setState,
  ] = useReducer(reducerHelper, {
    defaultActiveKey: -1,
    modalVisible: false,
    createStoreModalVisible: false,
    pagination: {
      page: 1,
      limit: API_TABLE_ROWS_PER_PAGE,
    },
    filters: {
      status: -1,
    },
    modalMode: "create",
    selectedStore: null,
    optionLists: [
      {
        name: "Store Name",
        value: "store_name",
      },
    ],
  });

  const initialSearchOptions = "store_name";

  const searchOptions = [
    {
      key: t("common.name"),
      value: "store_name",
    },
    {
      key: "Primary Domain",
      value: "domain",
    },
  ];

  const [
    {
      searchValue,
      searchObject,
      searchObjectDisplayText,
      filterObject,
      filterTag,
    },
    setFilter,
  ] = useReducer(reducerHelper, {
    searchValue: "",
    searchObject: initialSearchOptions,
    searchObjectDisplayText: _.first(searchOptions, "value").key ?? "",
    filterTag: [],
    filterObject: [],
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStores(pagination?.page, pagination?.limit, filterObject));
  }, [pagination, filterObject, dispatch]);

  const StoreManagementBreadcrumb = () => {
    return (
      <BreadcrumbComponent>
        <Breadcrumb.Item>
          <Icon icon={storeIcon} />
          &nbsp;&nbsp;
          <Link to={AppRoutes.STORE}>Store Management</Link>
        </Breadcrumb.Item>
      </BreadcrumbComponent>
    );
  };

  const onPaginationChange = (page, pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page,
        limit: pageSize,
      },
    }));
  };

  const onShowSizeChange = (current, size) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page: current,
        limit: size,
      },
    }));
  };

  const tabLists = [
    {
      name: t("common.all"),
      key: -1,
    },
    {
      name: t("common.active"),
      key: 1,
    },
    {
      name: t("common.unpublished"),
      key: 0,
    },
  ];

  const onTabOnChange = (key) => {
    let filterObjectExclStatus = filterObject.filter((v) => v.key !== "status");
    let filterTagExclStatus = filterTag.filter((v) => v.key !== "status");
    let stsValue = t("common.all");
    if (key !== -1) {
      filterObjectExclStatus = [
        ...filterObjectExclStatus,
        { key: "status", value: key },
      ];
      switch (parseInt(key)) {
        case 0:
          stsValue = t("common.unpublished");
          break;
        case 1:
          stsValue = t("common.active");
          break;
        default:
          break;
      }
    }
    filterTagExclStatus = [
      ...filterTagExclStatus,
      { key: "status", value: `${t("common.status")} : ${stsValue}` },
    ];
    setFilter((prev) => ({
      ...prev,
      filterObject: filterObjectExclStatus,
      filterTag: filterTagExclStatus,
    }));
    setState((prev) => ({
      ...prev,
      defaultActiveKey: key,
    }));
  };

  const handleDatePicker = (date, dateString) => {
    if (_.isNull(date)) {
      filterForm.setFieldsValue({
        created_at: "",
      });
      return;
    }
    filterForm.setFieldsValue({
      created_at: [moment(date[0]), moment(date[1])],
    });
  };

  const filterOptions = [
    {
      label: "Created At",
      name: "created_at",
      component: <CustomRangePicker onChange={handleDatePicker} />,
    },
    {
      label: "Updated At",
      name: "updated_at",
      component: <Input />,
    },
    {
      label: "Delete",
      name: "is_deleting",
      component: (
        <Select size={"large"}>
          <Select.Option value="1">Queued</Select.Option>
          <Select.Option value="0">Active</Select.Option>
        </Select>
      ),
    },
  ];

  const showCreateStore = () => {
    setState((prev) => ({
      ...prev,
      createStoreModalVisible: true,
      modalMode: "create",
    }));
  };

  const handleCreateStore = (values) => {
    if (modalMode === "create") {
      setFilter((prev) => ({
        ...prev,
        filterObject: [],
        filterTag: [],
        searchValue: "",
        searchObjectDisplayText: _.first(searchOptions, "value").key ?? "",
        searchObject: initialSearchOptions,
      }));
    }
    dispatch(
      modalMode === "create"
        ? createStore(values, [])
        : updateStore(selectedStore, values, filterObject)
    );
    setState((prev) => ({
      ...prev,
      createStoreModalVisible: !createStoreModalVisible,
    }));
  };

  const editStore = (id) => {
    dispatch(setSelectedStore(id));
    setState((prev) => ({
      ...prev,
      selectedStore: id,
      modalMode: "edit",
      createStoreModalVisible: !createStoreModalVisible,
    }));
  };

  const onSearchChange = (value) => {
    const searchOptDisplayText =
      _.first(searchOptions.filter((v) => v.value === value)).key ?? "";
    setFilter((prev) => ({
      ...prev,
      searchObject: value,
      searchObjectDisplayText: searchOptDisplayText,
    }));
  };

  const handleTagDimiss = (key) => {
    if (key === "status") {
      setState((prev) => ({
        ...prev,
        defaultActiveKey: -1,
      }));
    }
    filterForm.setFieldsValue({ [key]: "" });
    setFilter((prev) => ({
      ...prev,
      filterTag: filterTag.filter((v) => v.key !== key),
      filterObject: filterObject.filter((v) => v.key !== key),
    }));
  };

  const captureSearchFieldValue = () => {
    const searchVal = searchRef?.current?.input?.value;
    const searchOptionsKey = _.map(searchOptions, "value");
    setFilter((prev) => ({
      ...prev,
      searchValue: searchVal,
      filterObject: [
        ...filterObject.filter((v) => !searchOptionsKey.includes(v.key)),
        { key: searchObject, value: searchVal },
      ],
      filterTag: [
        ...filterTag.filter((v) => !searchOptionsKey.includes(v.key)),
        {
          key: searchObject,
          value: `${searchObjectDisplayText} : ${searchVal}`,
        },
      ],
    }));
  };

  const getLabelFromFilterOptions = (name) => {
    return filterOptions.find((v) => v.name === name)?.label;
  };

  const handleFilterModalSubmit = () => {
    setState((prev) => ({ ...prev, modalVisible: false }));
    filterForm.submit();
  };

  const handleFilterSubmit = (values) => {
    const fTag = [];
    const fObject = [];
    let rmvKey = [];
    Object.entries(values)
      .filter(([k, v]) => !_.isUndefined(v))
      .map(([k, v]) => {
        let filterDisplayVal = v;
        let filterVal = v;
        if (k === "created_at") {
          if (_.isEmpty(v)) {
            rmvKey.push(k);
            return null;
          }
          filterDisplayVal = `${v[0].format("YYYY-MM-DD")} to ${v[1].format(
            "YYYY-MM-DD"
          )}`;
          filterVal = `${v[0].format("YYYY-MM-DD")},${v[1].format(
            "YYYY-MM-DD"
          )}`;
        } else if (k === "is_deleting") {
          filterDisplayVal = v === 0 ? "Active" : "Queued";
        }
        fTag.push({
          key: k,
          value: `${getLabelFromFilterOptions(k)} : ${filterDisplayVal}`,
        });
        fObject.push({ key: k, value: filterVal });
        return v;
      });
    const fTagKey = _.map(fTag, "key");
    const fObjectKey = _.map(fObject, "key");
    setFilter((prev) => ({
      ...prev,
      filterObject: [
        ...filterObject.filter((v) => !fObjectKey.includes(v.key)),
        ...fObject.filter((v) => !rmvKey.includes(v.key)),
      ],
      filterTag: [
        ...filterTag.filter((v) => !fTagKey.includes(v.key)),
        ...fTag.filter((v) => !rmvKey.includes(v.key)),
      ],
    }));
  };

  return (
    <>
      <Helmet>
        <title>Store Management</title>
        <meta name="description" content="Store Management" />
      </Helmet>
      <YouIDivDesign>
        <YouIFullWidthContent
          breadcrumbComp={<StoreManagementBreadcrumb />}
          additionalPageTitleContent={
            <Button type="primary" onClick={showCreateStore}>
              {Transl("common.createField", { field: Transl("common.store") })}
            </Button>
          }
          customContentStyle={{ height: "100%" }}
          customBackgroundStyle={{
            height: "100%",
            minHeight: "100%",
          }}
          title="Store Management"
          withFooter
        >
          <SearchPanel
            initialSearchOptions={initialSearchOptions}
            searchOptions={searchOptions}
            searchValue={searchValue}
            searchObject={searchObject}
            searchObjectDisplayText={searchObjectDisplayText}
            filterTag={filterTag}
            filterObject={filterObject}
            onSearchChange={onSearchChange}
            handleTagDimiss={handleTagDimiss}
            captureSearchFieldValue={captureSearchFieldValue}
            ref={searchRef}
            withFilterModal
            filterOptions={filterOptions}
            openFilterModal={() =>
              setState((prev) => ({
                ...prev,
                modalVisible: true,
              }))
            }
            filterModalClose={() =>
              setState((prev) => ({
                ...prev,
                modalVisible: false,
              }))
            }
            filterModalVisible={modalVisible}
            handleFilterModalSubmit={handleFilterModalSubmit}
            handleFilterSubmit={handleFilterSubmit}
            form={filterForm}
            withDropDownSearch
            {...props}
          />
          <div style={{ marginTop: "10px" }} />
          <TableTab
            defaultActiveKey={defaultActiveKey}
            activeKey={String(defaultActiveKey)}
            onChange={onTabOnChange}
            tabLists={tabLists}
          />
          {store?.loading ? (
            <MockStoreTable />
          ) : (
            <>
              <StoreTable
                loading={store?.loading}
                data={store?.data?.data ?? []}
                history={history}
                editStore={editStore}
              />
              <CustomPagination
                text="stores"
                onShowSizeChange={onShowSizeChange}
                onChange={onPaginationChange}
                dataSource={store?.data}
              />
            </>
          )}
          <CreateStore
            createStoreModalVisible={createStoreModalVisible}
            handleStoreModalVisible={() =>
              setState((prev) => ({
                ...prev,
                createStoreModalVisible: !createStoreModalVisible,
                selectedStore: null,
                modalMode: "create",
              }))
            }
            modalMode={modalMode}
            selectedStoreDetail={_.first(
              (store?.data?.data ?? []).filter((v) => v.id === selectedStore)
            )}
            handleCreateStore={handleCreateStore}
          />
        </YouIFullWidthContent>
      </YouIDivDesign>
    </>
  );
};

export default ListStore;
