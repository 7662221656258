import { Collapse, Input, Button, Switch } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  openSingleSelectImageModal,
  updateImageModalData,
} from "../../../Actions/BuilderAction";
import { generateInitialListingBasedOnTemplate } from "../../../Helpers/Helpers";
import "./index.scss";
const { Panel } = Collapse;

const ListingEditor = ({ selectedBlock, setChange, settings, ...props }) => {
  const dispatch = useDispatch();
  const { listingTemplate, data, internalKey } = settings;

  const onChange = (key) => {
    console.log(key);
  };

  const constructDynamicEditorForm = (data, i) => {
    return listingTemplate.map((template) => {
      return (
        <div className="dynamic-listing-editor-container">
          {template.editorType == "text" && (
            <>
              <label>{template.displayTitle}</label>
              <Input
                value={data[template.internalKey] ?? ""}
                onChange={(evt) =>
                  onChangeDataCapture(
                    template.internalKey,
                    i,
                    evt?.target?.value
                  )
                }
              />
            </>
          )}
          {template.editorType == "textarea" && (
            <>
              <label>{template.displayTitle}</label>
              <Input.TextArea
                rows={4}
                value={data[template.internalKey] ?? ""}
                onChange={(evt) =>
                  onChangeDataCapture(
                    template.internalKey,
                    i,
                    evt?.target?.value
                  )
                }
              />
            </>
          )}
          {template.editorType == "switch" && (
            <>
              <label>{template.displayTitle}</label>
              <br />
              <Switch
                value={data[template.internalKey] ?? ""}
                onChange={(value) =>
                  onChangeDataCapture(template.internalKey, i, value)
                }
              />
            </>
          )}
          {template.editorType == "imageModalButton" && (
            <>
              <label>{template.displayTitle}</label>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  dispatch(openSingleSelectImageModal());
                  dispatch(
                    updateImageModalData({
                      page: selectedBlock?.page,
                      blockId: selectedBlock?.blockId,
                      settingMode: "content",
                      internalKey: internalKey,
                      modifyKey: "data",
                      modifyData: settings.data,
                      selectedIndex: i,
                    })
                  );
                }}
              >
                {template.displayTitle}
              </Button>
            </>
          )}
        </div>
      );
    });
  };

  const handleAddMoreListing = () => {
    const templateData = generateInitialListingBasedOnTemplate(listingTemplate);
    setChange(
      selectedBlock?.page,
      selectedBlock?.blockId,
      "content",
      internalKey,
      "data",
      [...data, templateData]
    );
  };

  const onChangeDataCapture = (modifyKey, key, value) => {
    let newData = data.map((v, k) => {
      console.log(v);
      if (k == key) {
        v[modifyKey] = value;
      }
      return v;
    });

    setChange(
      selectedBlock?.page,
      selectedBlock?.blockId,
      "content",
      internalKey,
      "data",
      newData
    );
  };

  const handleDeleteListing = (index) => {
    setChange(
      selectedBlock?.page,
      selectedBlock?.blockId,
      "content",
      internalKey,
      "data",
      data.filter((v, i) => i != index)
    );
  };

  return (
    <>
      <Collapse defaultActiveKey={null} height="100px" onChange={onChange}>
        {data.map((v, i) => {
          return (
            <Panel
              header={`${settings?.displayTitle} ${parseInt(i) + 1}`}
              key={i}
            >
              {constructDynamicEditorForm(v, i)}
              {i > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <Button type="danger" onClick={() => handleDeleteListing(i)}>
                    Delete Record
                  </Button>
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
      <div
        style={{
          marginTop: "5px",
          marginBottom: "5px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
        className="container"
      >
        <div className="row justify-content-center">
          <Button onClick={handleAddMoreListing}>Add more</Button>
        </div>
      </div>
    </>
  );
};

export default ListingEditor;
