import Buildernav from "../Components/CommonNav/BuilderNav";

export const GenerateBuilderNav = ({
    location,
    Routers,
    activeSidebar,
    setActiveSidebar,
}) => {
    //put your route here to prevent builderNav appear
    //add route inside [];
    const excludeRoutes = [];
    return (
        <>
            {!excludeRoutes.includes(location.pathname) && (
                <Buildernav
                    location={location}
                    Routers={Routers}
                    activeSidebar={activeSidebar}
                    setActiveSidebar={setActiveSidebar}
                />
            )}
        </>
    );
};
