import React, { useEffect, useReducer, useRef } from "react";
import { Breadcrumb, Button, Form, Select, Modal } from "antd";
import YouIDivDesign from "../../../Components/DivDesign/YouIDivDesign";
import YouIFullWidthContent from "../../../Components/DivDesign/YouIFullWidthContent";
import BreadcrumbComponent from "../../../Components/Breadcrumb/BreadcrumbComponent";
import { AppRoutes } from "../../../Constants";
import { Link, useHistory } from "react-router-dom";
import { Icon } from "@iconify/react";
import appsIcon from "@iconify/icons-charm/apps";
import storeIcon from "@iconify/icons-dashicons/store";
import PageGridListing from "./Pages/PageGridListing";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllPages, setSelectedStore } from "../../../Actions";
import windowIcon from "@iconify/icons-bi/window";
import { reducerHelper } from "../../../Helpers";
import CreatePageModal from "./Component/CreatePageModal";
import { API_TABLE_ROWS_PER_PAGE } from "../../../Constants/AppConfig";
import CustomPagination from "../../../Components/CustomPagination";
import SearchPanel from "../../../Components/SearchPanel";
import { t } from "i18next";
import { PageMode } from "./Constant/PageMode";
import dotGrid from "@iconify/icons-akar-icons/dot-grid";
import listIcon from "@iconify/icons-bi/list";
import PageListing from "./Pages/PageListing";
import {
  deleteWebstorePage,
  updatePageStatus,
} from "../../../Actions/WebstorePageAction";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import TableTab from "../../../Components/TableTab/index";
import ListingLayout from "../../../Components/Layout/ListingLayout";

const PageManagement = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    {
      createPageModalVisible,
      pagination,
      modalMode,
      selectedPageId,
      displayListingMode,
      defaultActiveKey,
    },
    setState,
  ] = useReducer(reducerHelper, {
    createPageModalVisible: false,
    pagination: {
      page: 1,
      limit: API_TABLE_ROWS_PER_PAGE,
    },
    modalMode: "create",
    selectedPageId: 0,
    displayListingMode: "list",
    defaultActiveKey: -1,
  });

  const initialSearchOptions = "page_name";

  const searchOptions = [];

  const defaultSearchLabel = <h4>{t("common.pageName")}</h4>;

  const [
    { searchValue, searchObject, filterObject, filterTag, modalVisible },
    setFilter,
  ] = useReducer(reducerHelper, {
    searchValue: "",
    searchObject: initialSearchOptions,
    filterTag: [],
    filterObject: [],
    modalVisible: false,
  });

  const { selectedStore, webstorePage } = useSelector(
    ({ webstorePage, coreApp }) => {
      return {
        webstorePage,
        selectedStore: coreApp.selectedStore,
      };
    }
  );

  useEffect(() => {
    dispatch(setSelectedStore(props?.match?.params?.storeId));
  }, [props?.match?.params?.storeId]);

  useEffect(() => {
    if (!_.isUndefined(selectedStore?.id)) {
      dispatch(getAllPages(pagination.page, pagination.limit, filterObject));
    }
  }, [selectedStore, pagination, searchValue, filterObject]);

  const StorePageManagementBreadcrumb = () => {
    return (
      <BreadcrumbComponent>
        <Breadcrumb.Item>
          <Icon icon={storeIcon} />
          &nbsp;&nbsp;
          <Link to={AppRoutes.STORE}> Store</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Icon icon={appsIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(AppRoutes.STOREDETAIL, ":storeId", selectedStore?.id)}
          >
            {" "}
            {selectedStore?.store_name} Apps
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Icon icon={windowIcon} />
          &nbsp;&nbsp;
          <Link
            to={_.replace(AppRoutes.STOREPAGES, ":storeId", selectedStore?.id)}
          >
            Page Management
          </Link>
        </Breadcrumb.Item>
      </BreadcrumbComponent>
    );
  };

  const showCreatePage = (action) => {
    setState((prev) => ({
      ...prev,
      createPageModalVisible: true,
      modalMode: action,
    }));
  };

  const createPageModalSubmit = () => {};

  const handleCreatePageModalCancel = () => {
    setState((prev) => ({
      ...prev,
      createPageModalVisible: false,
    }));
  };

  const handleCreatePageFinish = (values) => {};

  const onPaginationChange = (page, pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page,
        limit: pageSize,
      },
    }));
  };

  const onShowSizeChange = (current, size) => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page: current,
        limit: size,
      },
    }));
  };

  const handleTagDimiss = (key) => {
    setFilter((prev) => ({
      ...prev,
      searchValue: key === "page_name" ? null : prev.searchValue,
      filterTag: filterTag.filter((v) => v.key !== key),
      filterObject: filterObject.filter((v) => v.key !== key),
    }));
  };

  const captureSearchFieldValue = () => {
    const searchVal = searchRef?.current?.input?.value;
    setFilter((prev) => ({
      ...prev,
      searchValue: searchVal,
      filterObject: [
        ...filterObject.filter((v) => v.key !== initialSearchOptions),
        { key: initialSearchOptions, value: searchVal },
      ],
      filterTag: [
        ...filterTag.filter((v) => v.key !== initialSearchOptions),
        {
          key: initialSearchOptions,
          value: `${t("common.pageName")} : ${searchVal}`,
        },
      ],
    }));
  };

  const searchRef = useRef();

  const filterOptions = [
    {
      label: "Status",
      name: "status",
      component: (
        <Select size={"large"}>
          <Select.Option value="1">Active</Select.Option>
          <Select.Option value="0">Inactive</Select.Option>
        </Select>
      ),
    }
  ];

  const [filterForm] = Form.useForm();

  const handleFilterModalSubmit = () => {
    setFilter((prev) => ({ ...prev, modalVisible: false }));
    filterForm.submit();
  };

  const getLabelFromFilterOptions = (name) => {
    return filterOptions.find((v) => v.name === name)?.label;
  };

  const handleFilterSubmit = (values) => {
    const fTag = [];
    const fObject = [];
    let rmvKey = [];
    Object.entries(values)
      .filter(([k, v]) => !_.isUndefined(v))
      .map(([k, v]) => {
        let filterDisplayVal = v;
        let filterVal = v;
        if (k === "status") {
          filterDisplayVal = parseInt(v) === 1 ? "Active" : "Inactive";
        }
        fTag.push({
          key: k,
          value: `${getLabelFromFilterOptions(k)} : ${filterDisplayVal}`,
        });
        fObject.push({ key: k, value: filterVal });
        return v;
      });
    const fTagKey = _.map(fTag, "key");
    const fObjectKey = _.map(fObject, "key");
    setFilter((prev) => ({
      ...prev,
      filterObject: [
        ...filterObject.filter((v) => !fObjectKey.includes(v.key)),
        ...fObject.filter((v) => !rmvKey.includes(v.key)),
      ],
      filterTag: [
        ...filterTag.filter((v) => !fTagKey.includes(v.key)),
        ...fTag.filter((v) => !rmvKey.includes(v.key)),
      ],
    }));
  };

  const setSelectedPageId = (pageId) => {
    setState((prev) => ({
      ...prev,
      selectedPageId: pageId,
    }));
  };

  const handleDisplayPageListing = (displayMode) => {
    setState((prev) => ({
      ...prev,
      displayListingMode: displayMode,
    }));
  };

  const changePageStatus = (id) => {
    dispatch(updatePageStatus(id));
  };

  const onDelete = (id, storeId) => {
    Modal.confirm({
      title: "Page Deletion",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to perform delete?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => deletePage(id),
    });
  };

  const deletePage = (id) => {
    dispatch(deleteWebstorePage(id));
  };

  const editPage = (id, pageMode, storeId) => {
    let pg = AppRoutes.STOREPAGEEDITOR;
    pg = _.replace(pg, ":storeId", storeId);
    pg = _.replace(pg, ":pageId", id);
    if (pageMode == PageMode.Editor) {
      history.push(pg);
    } else if (pageMode == PageMode.RedirectUrl) {
      setSelectedPageId(id);
      showCreatePage("edit");
    }
  };

  const tabLists = [
    {
      name: t("common.all"),
      key: -1,
    },
    {
      name: "Builder",
      key: "builder",
    },
    {
      name: "Redirect URL",
      key: "Redirect URL",
    },
    {
      name:"Editor",
      key: "Editor",
    },
  ];

  const onTabOnChange = (key) => {
    let filterObjectExclStatus = filterObject.filter((v) => v.key !== "page_mode");
    let filterTagExclStatus = filterTag.filter((v) => v.key !== "page_mode");
    let stsValue = t("common.all");
    if (key != -1) {
      filterObjectExclStatus = [
        ...filterObjectExclStatus,
        { key: "page_mode", value: key },
      ];
      stsValue = key;
    }
    filterTagExclStatus = [
      ...filterTagExclStatus,
      { key: "page_mode", value: `Page Mode : ${stsValue}` },
    ];
    setFilter((prev) => ({
      ...prev,
      filterObject: filterObjectExclStatus,
      filterTag: filterTagExclStatus,
    }));
    setState((prev) => ({
      ...prev,
      defaultActiveKey: key,
    }));
  };

  return (
    <>
      <YouIDivDesign>
        <YouIFullWidthContent
          withFooter
          breadcrumbComp={<StorePageManagementBreadcrumb />}
          additionalPageTitleContent={
            <Button type="primary" onClick={() => showCreatePage("create")}>
              Create Page
            </Button>
          }
          customContentStyle={{ height: "100%" }}
          customBackgroundStyle={{ height: "100%", minHeight: "100%" }}
          title="Page Management"
          withTitle
        >
          <SearchPanel
            initialSearchOptions={initialSearchOptions}
            searchOptions={searchOptions}
            searchValue={searchValue}
            searchObject={searchObject}
            filterTag={filterTag}
            filterObject={filterObject}
            handleTagDimiss={handleTagDimiss}
            captureSearchFieldValue={captureSearchFieldValue}
            ref={searchRef}
            filterOptions={filterOptions}
            defaultSearchLabel={defaultSearchLabel}
            withFilterModal
            openFilterModal={() =>
              setFilter((prev) => ({
                ...prev,
                modalVisible: true,
              }))
            }
            filterModalClose={() =>
              setFilter((prev) => ({
                ...prev,
                modalVisible: false,
              }))
            }
            filterModalVisible={modalVisible}
            form={filterForm}
            handleFilterModalSubmit={handleFilterModalSubmit}
            handleFilterSubmit={handleFilterSubmit}
            rightComponent={
              <div className="page-management-listing-view-controls">
                <Button onClick={() => handleDisplayPageListing("list")}>
                  <Icon icon={listIcon} />
                </Button>
                <Button onClick={() => handleDisplayPageListing("grid")}>
                  <Icon icon={dotGrid} />
                </Button>
              </div>
            }
            {...props}
          />
          <ListingLayout>
          <TableTab
            style={{marginTop:"10px"}}
            defaultActiveKey={defaultActiveKey}
            activeKey={String(defaultActiveKey)}
            onChange={onTabOnChange}
            tabLists={tabLists}
          />
          {displayListingMode == "grid" ? (
            <PageGridListing
              loading={webstorePage?.loading}
              data={webstorePage?.data?.data ?? []}
              changePageStatus={changePageStatus}
              deletePage={deletePage}
              editPage={editPage}
              onDelete={onDelete}
              {...props}
            />
          ) : (
            <PageListing
              loading={webstorePage?.loading}
              data={webstorePage?.data?.data ?? []}
              changePageStatus={changePageStatus}
              deletePage={deletePage}
              editPage={editPage}
              onDelete={onDelete}
              {...props}
            />
          )}
          </ListingLayout>

          <CreatePageModal
            modalMode={modalMode}
            createPageModalVisible={createPageModalVisible}
            modalSubmit={createPageModalSubmit}
            handleCreatePageModalCancel={handleCreatePageModalCancel}
            handleCreatePageFinish={handleCreatePageFinish}
            storeId={selectedStore?.id}
            pageId={selectedPageId}
          />
          <CustomPagination
            text={t("common.pages")}
            onShowSizeChange={onShowSizeChange}
            onChange={onPaginationChange}
            dataSource={webstorePage?.data}
          />
        </YouIFullWidthContent>
      </YouIDivDesign>
    </>
  );
};

export default PageManagement;
