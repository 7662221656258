import {
  CLOSE_IMAGE_MODAL,
  INSERT_IMAGE_MODAL_DATA,
  OPEN_ICON_BOX,
  OPEN_MULTIPLE_SELECT_IMAGE_MODAL,
  OPEN_SINGLE_SELECT_IMAGE_MODAL,
} from "../Constants/ActionType";

const BuilderInitState = {
  imageManager: {
    modalOpen: false,
    multipleSelect: false,
    page: null,
    blockId: null,
    settingMode: null,
    internalKey: null,
    modifyKey: null,
    modifyData: null,
    selectedIndex: null,
  },
  iconManager: {
    iconBoxOpen: false,
  },
};

export const BuilderStateReducer = (state = BuilderInitState, action) => {
  switch (action.type) {
    case OPEN_SINGLE_SELECT_IMAGE_MODAL: {
      return {
        ...state,
        imageManager: {
          ...state.imageManager,
          modalOpen: true,
          multipleSelect: false,
        },
      };
    }

    case OPEN_MULTIPLE_SELECT_IMAGE_MODAL: {
      return {
        ...state,
        imageManager: {
          ...state.imageManager,
          modalOpen: true,
          multipleSelect: true,
        },
      };
    }

    case INSERT_IMAGE_MODAL_DATA: {
      return {
        ...state,
        imageManager: {
          ...state.imageManager,
          page: action.page,
          blockId: action.blockId,
          settingMode: action.settingMode,
          internalKey: action.internalKey,
          modifyKey: action.modifyKey,
          modifyData: action.modifyData,
          selectedIndex: action.selectedIndex,
        },
      };
    }

    case CLOSE_IMAGE_MODAL: {
      return {
        ...state,
        imageManager: {
          modalOpen: false,
          multipleSelect: false,
          page: null,
          blockId: null,
          settingMode: null,
          internalKey: null,
          modifyKey: null,
          modifyData: null,
          selectedIndex: null,
        },
      };
    }
    case OPEN_ICON_BOX: {
      return {
        ...state,
        iconManager: {
          ...state.iconManager,
          iconBoxOpen: true,
        },
      };
    }
    default:
      return state;
  }
};
