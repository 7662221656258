import { message } from "antd";
import { AppRoutes } from "../Constants";
import { SELECT_SELECTED_STORE, SET_SELECTED_STORE } from "../Constants/ActionType";
import BuilderApi from "../Inceptors/BuilderApi";

export const selectSelectedStore = (obj) => (dispatch) => {
    dispatch({type:SELECT_SELECTED_STORE,payload:obj});
}

export const setSelectedStore = (storeId) => (dispatch) => {
    dispatch({type:SET_SELECTED_STORE,payload:parseInt(storeId)});
}

export const fetchSelectedStoreDetail = (id,history) => (dispatch) => {
    let url = `/api/v1/store/${id}`;
    window.sessionStorage.setItem("store_id",id);
    BuilderApi.get(url,{
        headers:{
            "Store-ID": id
        }
    }).then((response) => {
        dispatch(selectSelectedStore(response?.data?.response?.data));
    }).catch((err) => {
        window.location = window.location.origin + AppRoutes.STORE;
        message.error('Store Not Found!');
    });
}