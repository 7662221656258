import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Input } from "antd";
import { PasswordFormComponent } from "./PasswordForm";
import { Icon } from "@iconify/react";
import { Transl } from "../../Helpers";

export const CreateAccountFormComponent = () => {
    return (
        <>
            <Row>
                <Col lg={3}>
                    <h3>
                        {Transl("email")}&nbsp;
                        <Icon icon="bi:asterisk" className="required" />
                    </h3>
                </Col>
                <Col lg={9}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: Transl("field.required", {
                                    field: Transl("email"),
                                }),
                            },
                            {
                                type: "email",
                                message: Transl("valid_email_alert"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={Transl("field.placeholder.enter", {
                                field: Transl("new.email"),
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <PasswordFormComponent />
        </>
    );
};
