import axios from "axios";
import _ from "lodash";
import { AppRoutes, LOCAL_LANGUAGE } from "./../Constants";

const BuilderApi = axios.create({
  //baseURL: process.env.REACT_APP_BUILDERAPI_URL,
  baseURL: process.env.REACT_APP_AUTHAPI_URL,
  timeout: 30000,
  headers: { "X-Builderx-Language": LOCAL_LANGUAGE },
});

BuilderApi.defaults.headers.post["Content-Type"] = "application/json";
BuilderApi.defaults.headers.post.Accept = "application/json";
BuilderApi.interceptors.request.use(
  (config) => {
    const storeId = window.sessionStorage.getItem("store_id");
    if (!_.isEmpty(storeId)) {
      config.headers["Store-ID"] = storeId;
    }
    const userToken = localStorage.getItem("user");
    if (!_.isEmpty(userToken)) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
BuilderApi.interceptors.response.use(
  (response) => {
    //  const url = response.request.responseURL.split('?')[0].replace(API_URL, '');

    //   if (currentExecutingRequests[url]) {
    //     // here you clean the request
    //     delete currentExecutingRequests[url];
    //   }

    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }

    //   if (currentExecutingRequests[error.config.url.split('?')[0]]) {
    //     // here you clean the request
    //     delete currentExecutingRequests[error.config.url.split('?')[0]];
    //   }

    if (error.response) {
      if (error.response.data) {
        // NotificationManager.error(error.response.data.message);
      }
    }
    if (error?.response?.status === 401) {
      window.sessionStorage.removeItem("store_id");
    }
    if (
      error?.response?.status === 401 &&
      ![AppRoutes.LOGIN, AppRoutes.REGISTER].includes(window.location.pathname)
    ) {
      window.location.assign(window.location.origin + AppRoutes.LOGIN);
    }

    return Promise.reject(error);
  }
);

export default BuilderApi;
