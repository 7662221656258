import {
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_FAILED,
  GET_SELECTED_STORE,
  GET_SELECTED_STORE_SUCCESS,
  GET_SELECTED_STORE_FAILED,
} from "../Constants/ActionType";

const INIT_STATE = {
  loading: false,
  data: {},
  selectedStore: {},
};

export const StoreReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORE:
      return { ...state, loading: true };
    case GET_STORE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_STORE_FAILED:
      return { ...state, loading: false, data: {} };
    case GET_SELECTED_STORE:
      return { ...state, loading: false, selectedStore: {} };
    case GET_SELECTED_STORE_SUCCESS:
      return { ...state, loading: true, selectedStore: action.payload };
    case GET_SELECTED_STORE_FAILED:
      return { ...state, loading: true, selectedStore: {} };
    default:
      return { ...state };
  }
};
