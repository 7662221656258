import React, { useEffect } from "react";
import { Button, Select, Input, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import desktopComputer from "@iconify/icons-emojione-monotone/desktop-computer";
import tabletIcon from "@iconify/icons-ci/tablet";
import iphoneIcon from "@iconify/icons-raphael/iphone";
import linkChain from "@iconify/icons-akar-icons/link-chain";
import plusOutlined from "@iconify/icons-ant-design/plus-outlined";
import editIcon from "@iconify/icons-akar-icons/edit";
import "./css/Header.scss";
import { Transl } from "../../../Helpers";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStore } from "../../../Actions";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../Constants/Routes";
const { Search } = Input;

const BuilderHeader = ({
  storeId,
  state,
  setState,
  toggleComponents,
  addBlockLayout,
  handleIframePreview,
  handleSaveGlobal,
  loading,
  notifyToStoreFrontHandler = null,
  iframeLoading,
  toggleNavbar,
  showModal,
  openBlockManager,
  handleChangeRoutePage,
  handleCreatePageModal,
  selectedPage,
  storefrontPreviewLoaded,
  handleOpenAdvancedPageManagementModal,
  pages,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedStore } = useSelector(({ coreApp }) => {
    return {
      selectedStore: coreApp.selectedStore,
    };
  });

  useEffect(() => {
    dispatch(setSelectedStore(props?.match?.params?.storeId));
  }, []);

  const handleChangeRoute = (val) => {
    if (val == "addNewPage") {
      handleCreatePageModal();
    } else {
      handleChangeRoutePage(val);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col align-self-start">
                <div className="col d-flex flex-nowrap">
                  <div className="d-flex builder-header-left-nav">
                    <Button onClick={() => history.push(_.replace(AppRoutes.STOREDETAIL,":storeId",storeId))} type="light">
                      {" "}
                      Back {selectedStore?.store_name} Store
                    </Button>
                    <span style={{ marginRight: "5px", marginLeft: "5px" }}>
                      |
                    </span>
                    <span>{Transl("common.pages")}:</span>
                  </div>
                 
                  <div className="input-group">
                    <Select
                      defaultValue={_.first(pages).value ?? null}
                      value={selectedPage}
                      dropdownMatchSelectWidth={false}
                      // style={{ width: "100%" }}
                      // showSearch
                      // filterOption={(input, option) => option.toLowerCase().includes(input.toLowerCase())}
                      onChange={handleChangeRoute}
                      //loading={iframeLoading}
                    >
                      {_.orderBy(pages ?? [],'isModal').map(({ title, value }) => {
                        return (
                          <Select.Option value={value}>
                            {value == "addNewPage"
                              ? title
                              : `Name: ${title} | Path: ${value}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              {/* <div className="col align-self-center">
                <div
                  className="col-sm-12 align-self-center"
                  id="builder-site-full-path-container"
                >
                  <Input
                    className="site-full-path-input"
                    prefix={
                      <span style={{ color: "black" }}>
                        <Icon icon={linkChain} /> {Transl("common.website")}
                      </span>
                    }
                    disabled={true}
                    defaultValue="https://conn3ct.me/patrick/tan/ok"
                    suffix={
                      <Button style={{ height: "inherit" }} type="light">
                        <Icon icon={editIcon} />
                      </Button>
                    }
                  />
                </div>
              </div> */}
              <div className="col align-self-end">
                <div className="d-flex justify-content-end">
                  <div className="row">
                  <div className="col">
                      <Button
                        className="builder-add-block"
                        loading={!storefrontPreviewLoaded}
                        disabled={!storefrontPreviewLoaded}
                        onClick={() => handleOpenAdvancedPageManagementModal()}
                      >
                        Advanced Page Management
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        onClick={openBlockManager}
                        className="builder-add-block"
                        loading={!storefrontPreviewLoaded}
                        disabled={!storefrontPreviewLoaded}
                      >
                        <Icon icon={plusOutlined} />
                        {Transl("builder.addBlock")}
                      </Button>
                    </div>
                    <div className="col align-self-center">
                      <div className="row justify-content-center">
                        <div
                          className="btn-group float-right builder-view-control-group"
                          role="group"
                          aria-label="Basic example"
                          style={{ justifyContent: "center" }}
                        >
                          <Tooltip title="Desktop view">
                            <span
                              onClick={() => handleIframePreview("desktop")}
                              className="view-controls"
                            >
                              <Icon icon={desktopComputer} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Tablet view">
                            <span
                              onClick={() => handleIframePreview("tablet")}
                              className="view-controls"
                            >
                              <Icon icon={tabletIcon} />
                            </span>
                          </Tooltip>
                          <Tooltip title="Mobile view">
                            <span
                              onClick={() => handleIframePreview("mobile")}
                              className="view-controls"
                            >
                              <Icon icon={iphoneIcon} />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <Button
                        onClick={handleSaveGlobal}
                        loading={!storefrontPreviewLoaded}
                        disabled={!storefrontPreviewLoaded}
                        type="danger"
                      >
                        {Transl("common.saveChanges")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuilderHeader;
