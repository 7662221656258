import { Table, Col, Button, Skeleton, Switch } from "antd";
import React from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import linkChain from "@iconify/icons-akar-icons/link-chain";
import editIcon from "@iconify/icons-bxs/edit";
import trashIcon from "@iconify/icons-bi/trash";
import "./PageListing.scss";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../Constants/Routes";
import _ from "lodash";

const PageListing = ({
  loading,
  data,
  changePageStatus,
  deletePage,
  onDelete,
  editPage,
}) => {
  const history = useHistory();

  const { selectedStore } = useSelector(({ coreApp }) => {
    return {
      selectedStore: coreApp.selectedStore,
    };
  });
  const columns = [
    {
      title: "Page Name",
      dataIndex: "page_name",
      key: "page_name",
    },
    {
      title: "Route Path",
      dataIndex: "page_route",
      key: "page_route",
      render: (text) => (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://${selectedStore.primary_domain.domain}/${text}`}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Page Mode",
      dataIndex: "page_mode",
      key: "page_mode",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Switch
          onChange={() => changePageStatus(record.id)}
          defaultChecked={record.status}
        />
      ),
    },
    {
      title: "Created Date",
      dataIndex: "updated_at",
      key: "address",
      render: (_, record) => moment(record.updated_at).format("DD MMM YYYY"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (__, dat) => {
        const { id, store_id, page_mode,page_uuid } = dat;
        return (
          <div className="page-listing-table-action">
            {page_mode === "Builder" ? (
              <Button onClick={() => history.push(_.replace(AppRoutes.STOREBUILDER,':storeId',store_id),{pageId:page_uuid})}>
                <Icon icon={editIcon} /> {t("common.builder")}
              </Button>
            ) : (
              <Button onClick={() => editPage(id, page_mode, store_id)}>
                <Icon icon={linkChain} />{t("common.edit")}
              </Button>
            )}
            <Button onClick={() => onDelete(id, store_id)}>
              <Icon icon={trashIcon} /> {t("common.delete")}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-responsive">
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};
export default PageListing;
