import React, { useState } from "react";
import YouIDivDesign from "../../Components/DivDesign/YouIDivDesign";

import { Breadcrumb } from "antd";
import BreadcrumbComponent from "../../Components/Breadcrumb/BreadcrumbComponent";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../Constants";

import ImageManagerComponent from "../../Components/ImageManager/ImageManagerComponent";
import YouIFullWidthContent from "../../Components/DivDesign/YouIFullWidthContent";
import { Transl } from "../../Helpers";

const ImageManager = () => {
    const [isModal] = useState(false);

    const ImageManagerBreadcrumb = () => {
        return (
            <BreadcrumbComponent>
                <Breadcrumb.Item>Resources</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={AppRoutes.IMAGEMANAGER}>
                        {Transl("image.manager")}
                    </Link>
                </Breadcrumb.Item>
            </BreadcrumbComponent>
        );
    };

    return (
        <>
            <YouIDivDesign>
                <YouIFullWidthContent
                    breadcrumbComp={<ImageManagerBreadcrumb />}
                    withTitle
                    title={Transl("image.manager")}
                >
                    <ImageManagerComponent isModal={isModal} />
                </YouIFullWidthContent>
            </YouIDivDesign>
        </>
    );
};

export default ImageManager;
