import React from "react";
import { Modal } from "antd";
import { Transl } from "../../Helpers/LanguageHelper";

const DeleteDataModal = ({ modalShow, handleDelete, handleClose }) => {
  return (
    <Modal
      title={Transl("delete")}
      open={modalShow.modalOpen}
      onOk={() => handleDelete(modalShow.data)}
      okText={Transl("delete")}
      onCancel={handleClose}
      cancelText={Transl("cancel")}
    >
      {Transl("common.deleteWarningMessage")}
    </Modal>
  );
};

export default DeleteDataModal;
