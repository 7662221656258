const CountryinitState = {
    countries: [],
    loading: false,
    error: null,
};

export const CountryReducer = (state = CountryinitState, action) => {
    switch (action.type) {
        case "FETCH_COUNTRY_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
                countries: [],
            };

        case "FETCH_COUNTRY_SUCCESS":
            return {
                ...state,
                loading: false,
                countries: action.payload,
            };

        case "FETCH_COUNTRY_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
                countries: [],
            };

        default:
            return state;
    }
};

const StateinitState = {
    loading: false,
    error: null,
};

export const StateReducer = (state = StateinitState, action) => {
    switch (action.type) {
        case "FETCH_STATE_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };

        case "FETCH_STATE_SUCCESS":
            return {
                ...state,
                loading: false,
                stateLocation: action.payload,
            };

        case "FETCH_STATE_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
