import { NotificationManager } from "react-notifications";
import { message} from "antd";
import {
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_FAILED,
  GET_SELECTED_STORE,
  GET_SELECTED_STORE_SUCCESS,
  GET_SELECTED_STORE_FAILED,
} from "../Constants/ActionType";
import { API_TABLE_ROWS_PER_PAGE } from "../Constants/AppConfig";
import BuilderApi from "../Inceptors/BuilderApi";
import { AppRoutes } from "../Constants";


export const getStores = (page,limit,filter) => (dispatch) => {
    dispatch({type:GET_STORE});
    let url = `/api/v1/store?page=${page}&limit=${limit}`;
    filter.map((v) => {
        if (v.key === "status" && parseInt(v.value) === -1){
            return null;
        }
        url += `&filter[${v.key}]=${v.value}`;
        return url;
    });
    BuilderApi.get(url).then((response) => {
        dispatch({type:GET_STORE_SUCCESS,payload:response?.data?.response?.data});
    }).catch((err) => {
        dispatch({type:GET_STORE_FAILED});
    });
}

export const createStore = (values) => (dispatch) => {
    let url = `/api/v1/store`;
    BuilderApi.post(url,values).then((response) => {
        message.success('Store created successfully!');
        dispatch(getStores(1,API_TABLE_ROWS_PER_PAGE,[]));
    }).catch((err) => {
        NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
}

export const getStoreDetail = (id,history) => (dispatch) => {
    dispatch({type:GET_SELECTED_STORE});
    let url = `/api/v1/store/${id}`;
    BuilderApi.get(url).then((response) => {
        dispatch({type:GET_SELECTED_STORE_SUCCESS,payload:response?.data?.response?.data});
    }).catch((err) => {
        history.push(AppRoutes.STORE);
        message.error('Store Not Found!');
        dispatch({type:GET_SELECTED_STORE_FAILED});
    });
}

export const updateStore = (id,values,filters) => (dispatch) => {
    let url = `/api/v1/store/${id}`;
    BuilderApi.put(url,values).then((response) => {
        message.success('Store updated successfully!');
        dispatch(getStores(1,API_TABLE_ROWS_PER_PAGE,filters));
    }).catch((err) => {
        NotificationManager.error(err?.response?.data?.error ?? err?.message);
    });
}