import {
  SELECT_SELECTED_STORE,
  SET_SELECTED_STORE,
} from "../Constants/ActionType";

const INIT_STATE = {
  selectedStoreId: null,
  selectedStore: {},
};

export const CoreAppReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECT_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.payload,
      };

    case SET_SELECTED_STORE:
      return {
        ...state,
        selectedStoreId: action.payload,
      };

    default:
      return state;
  }
};
