import { message } from "antd";
import {
  FETCH_IMAGE_FAILURE,
  FETCH_IMAGE_REQUEST,
  FETCH_IMAGE_SUCCESS,
} from "../Constants/ActionType";
import { Transl } from "../Helpers/LanguageHelper";
import BuilderApi from "../Inceptors/BuilderApi";

export const requestImage = () => {
  return {
    type: FETCH_IMAGE_REQUEST,
  };
};

export const getImage = () => (dispatch) => {
  BuilderApi.get("api/v1/store/image-management/upload/image")
    .then((response) => {
      dispatch({
        type: FETCH_IMAGE_SUCCESS,
        payload: response.data?.response?.data ?? [],
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_IMAGE_FAILURE,
        error: "Fail to fetch image",
      });
    });
};

export const uploadSingleImage = (values) => (dispatch) => {
  BuilderApi.post(
    "api/v1/store/image-management/upload/image",
    values.image
  ).then(() => {
    dispatch(getImage());
    message.success(
      Transl("common.successful", { field: Transl("imageManagement.upload") })
    );
  });
};

export const deleteImage = (values) => (dispatch) => {
  BuilderApi.put("api/v1/store/image-management/upload/file/delete", {
    filename: values.filename,
  }).then(() => {
    message.success(
      Transl("common.successful", { field: Transl("common.delete") })
    );
    dispatch(getImage());
  });
};
