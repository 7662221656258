import { MenuOutlined } from "@ant-design/icons";
import { Table, InputNumber } from "antd";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import "./SortTableColumn.scss";

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: "grab",
      color: "#999",
    }}
  />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const SortTableColumn = ({
  gridSortData,
  notifyToStoreFrontHandler,
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);

  const handleOnChangeGrid = (blockRecord, val) => {
    notifyToStoreFrontHandler({
      action: "updateSelectedBlockGrid",
      blockData: { ...blockRecord, width: val },
      page: gridSortData.page,
    });
    setDataSource(gridSortData.blocks.map((v) => {
        if (v.blockId == blockRecord.blockId){
          v.width = val;
        }
        return v;
      }));
  };

  const handleSwapBlockAndNotifyStoreFront = (blockData) => {
    notifyToStoreFrontHandler({
      action: "swapBlockByRawBlockComponent",
      blockData,
      sectionId: _.first(blockData).sectionId,
      page: gridSortData.page,
    });
  };

  const columns = [
    {
      title: null,
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Block Type",
      dataIndex: "blockType",
      className: "drag-visible",
    },
    {
      title: "Block Id",
      dataIndex: "blockId",
      className: "drag-visible",
    },
    {
      title: `Width (Total Grid:${_.sum(_.map(dataSource,'width') ?? 0)}/12)`,
      dataIndex: "width",
      render: (val, data) => {
        return (
          <InputNumber
            onChange={(val) => handleOnChangeGrid(data, val)}
            defaultValue={val}
            max={12}
            min={1}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setDataSource(
      gridSortData.blocks.reduce((carry, v, i) => {
        return [
          ...carry,
          {
            ...v,
            index: i,
            key: i,
          },
        ];
      }, [])
    );
  }, [gridSortData?.blocks]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      handleSwapBlockAndNotifyStoreFront(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default SortTableColumn;
