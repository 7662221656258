import React, { useEffect, useReducer } from "react";
import { Table, Form, Button, Input } from "antd";
import { reducerHelper } from "../../../../Helpers/Helpers";
import CustomModal from "../../../../Components/Custom/Modal/index";
import _ from "lodash";

const AdvancedPageManagement = ({
  advPgMgtModalVisible,
  handleAdvPgMgtOk,
  handleAdvPgMgtCancel,
  storeFrontAllThemeSettings,
  notifyToStoreFrontHandler,
  handleCreatePageModal,
}) => {
  const [form] = Form.useForm();

  const [{ selectedPage, pageDetailModalVisible }, setState] = useReducer(
    reducerHelper,
    {
      pageDetailModalVisible: false,
      selectedPage: {},
    }
  );

  const closePageDetailModal = () => {
    form.resetFields();
    setState((prev) => ({
      ...prev,
      selectedPage: {},
      pageDetailModalVisible: false,
    }));
  };

  const openPageDetailModal = (pageId) => {
    const selectedPageThemeSetting = storeFrontAllThemeSettings.find(
      (v) => v.pageId == pageId
    );
    setState((prev) => ({
      ...prev,
      selectedPage: selectedPageThemeSetting,
      pageDetailModalVisible: true,
    }));
    form.setFieldsValue(selectedPageThemeSetting);
  };

  const onChange = () => {};

  const handleSubmitPageEditForm = () => {
    form.submit();
  };

  const handlePageEditFormFinish = (values) => {
    const updatedPageSetting = { ...selectedPage, ...values };
    notifyToStoreFrontHandler({
      action: "updateSelectedPageSetting",
      pageId: selectedPage.pageId,
      pageSetting: updatedPageSetting,
    });
    closePageDetailModal();
  };

  const handlePageEditFormFinishFailed = (err) => {
    console.error(err);
  };

  const handleDeleteWebstorePage = (pageId) => {
    notifyToStoreFrontHandler({
        action: "deleteSelectedPage",
        pageId
    });
  }

  const columns = [
    {
      title: "Page Id",
      dataIndex: "pageId",
      width: "200px",
    },
    {
      title: "Page Name",
      dataIndex: "page",
    },
    {
      title: "Page Route",
      dataIndex: "pagePath",
    },
    {
      title: "Action",
      render: (text, record, index) => {
        return (
          <React.Fragment>
            <Button
              onClick={() => openPageDetailModal(record.pageId)}
              type="info"
            >
              Edit
            </Button>{" "}
            <Button onClick={() => handleDeleteWebstorePage(record?.pageId)} type="danger">
              Remove
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <CustomModal
        title="Advanced Pagement Management"
        width={1080}
        centered
        open={advPgMgtModalVisible}
        onOk={handleAdvPgMgtOk}
        onCancel={handleAdvPgMgtCancel}
        footer={null}
        bodyStyle={{ padding: "5px 24px" }}
      >
        <div className="row mb-2 justify-content-end">
          <div className="d-flex justify-content-end">
            <Button type="primary" onClick={handleCreatePageModal}>
              Create New Page
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Table
              columns={columns}
              dataSource={
                _.isEmpty(storeFrontAllThemeSettings)
                  ? []
                  : storeFrontAllThemeSettings
              }
              onChange={onChange}
              pagination={{ defaultPageSize: 5}}
            />
          </div>
        </div>
      </CustomModal>
      <CustomModal
        onOk={handleSubmitPageEditForm}
        centered
        onCancel={closePageDetailModal}
        title="Builder Edit Page"
        open={pageDetailModalVisible}
      >
        <div className="container" style={{ marginBottom: "15px" }}>
          <div className="row justify-content-center">
            <div className="col text-center">
              <label style={{ fontSize: "16px" }}>
                Page Id: {selectedPage?.pageId}
              </label>
            </div>
          </div>
        </div>
        <Form
          name="basic"
          onFinish={handlePageEditFormFinish}
          onFinishFailed={handlePageEditFormFinishFailed}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          form={form}
        >
          <Form.Item hidden={true} name="pageId"></Form.Item>
          <Form.Item name="page" label="Page Name">
            <Input />
          </Form.Item>
          <Form.Item name="pagePath" label="Page Route">
            <Input />
          </Form.Item>
        </Form>
      </CustomModal>
    </React.Fragment>
  );
};

export default AdvancedPageManagement;
