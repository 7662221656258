const MessageReturninitState = {
    message: null,
    category: null,
    status: null,
};

export const MessageReturnReducer = (
    state = MessageReturninitState,
    action
) => {
    switch (action.type) {
        case "RETURN_MESSAGE":
            return {
                message: action.message,
                category: action.category,
                status: action.status,
            };

        case "CLEAR_MESSAGE":
            return {
                message: null,
                category: null,
                status: null,
            };

        default:
            return state;
    }
};
